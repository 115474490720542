import React,{useEffect,useState,useRef} from 'react';
import "./DumbbellLandingPageCreate.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import picIcon from "../../assets/dumbbell_image/pic-icon.png";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import { v4 as uuidv4 } from 'uuid';
import {message, Spin} from "antd";
import {
    editLandApi,
    getLandApi,
    getMyPageApi, getTrainerProfileApi,
    trainerLandUploadApi,
    trainerThumbUploadApi
} from "../../Services/Services";
import {Setup} from "../../Services/Setup";
import defaultUser from "../../assets/images/default_user.png";
import {numberWithCommas} from "../../Services/utils";
import ReactPlayer from "react-player";

function DumbbellLandingPageCreate(){

    const [video,set_video] = useState("");
    const [image,set_image] = useState("");
    const [full_name,set_full_name] = useState("");
    const [descriptionInput,set_descriptionInput] = useState("");
    const [descriptions,set_descriptions] = useState([]);

    const [instagram,set_instagram] = useState("");
    const [whatsApp,set_whatsApp] = useState("");
    const [telegram,set_telegram] = useState("");
    const [website,set_website] = useState("");
    const [email,set_email] = useState("");
    const [phone,set_phone] = useState("");


    const [plans,set_plans] = useState([]);
    const [currentPlanTitle,set_currentPlanTitle] = useState("");
    const [currentPlanCost,set_currentPlanCost] = useState("");
    const [currentPlanDiscount,set_currentPlanDiscount] = useState("");
    const [currentPlanDeliveryTime,set_currentPlanDeliveryTime] = useState("");
    const [currentPlanDuration,set_currentPlanDuration] = useState(30);
    const [currentPlanDescriptionInput,set_currentPlanDescriptionInput] = useState("");
    const [currentPlanDescriptions,set_currentPlanDescriptions] = useState([]);

    useEffect(()=>{
        document.body.style.backgroundColor = "#ffffff";
    },[])

    const [my,set_my] = useState(null)
    const getUser = async ()=>{
        // let user = localStorage.getItem('user')
        try{
            const res = await getTrainerProfileApi()
            localStorage.setItem('user',JSON.stringify(res.data.data))
            set_my(res.data.data)
        }catch (e) {
            console.log(e)
        }
    }


    useEffect(()=>{
       getLand()
        getUser()
    },[])



    const [page,set_page] = useState(null)
    const getLand =async ()=>{
        try{
            const resp = await getMyPageApi()
            set_page(resp.data.data)
            // if(resp.data.data.data.video.length>0)
            // set_imagePrev(Setup.filePoint+resp.data.data.data.video)
            if(resp.data.data.data)
                fillPage(resp.data.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const fillPage = (data)=>{
        set_image(data.image)
        set_video(data.video)
        set_full_name(data.full_name)
        set_descriptions(data.descriptions)
        set_instagram(data.instagram)
        set_whatsApp(data.whatsApp)
        set_telegram(data.telegram)
        set_website(data.website)
        set_email(data.email)
        set_phone(data.phone)
        set_plans(data.plans)

    }

    const handleDeleteDescription = (d)=>{
        set_descriptions(descriptions.filter(f=>f.id!==d.id))
    }

    const handleAddDescription = ()=>{
        if(descriptionInput.length<1)
            return

        set_descriptions([...descriptions,{id:uuidv4(),text:descriptionInput}])
        set_descriptionInput("")
    }

    const handleAddPlanDescription = ()=>{
        if(currentPlanDescriptionInput.length<1)
            return
        set_currentPlanDescriptions([...currentPlanDescriptions,{id:uuidv4(),text:currentPlanDescriptionInput}])
        set_currentPlanDescriptionInput("")
    }

    const handleDeletePlanDescription =  (d)=>{
        set_currentPlanDescriptions(currentPlanDescriptions.filter(f=>f.id!==d.id))
    }

    const resetPlanForm = ()=>{
        set_currentPlanTitle("")
        set_currentPlanCost("")
        set_currentPlanDeliveryTime("")
        set_currentPlanDuration("")
        set_currentPlanDescriptions([])
    }

    const addPlan = ()=>{




        //validate
        if(currentPlanTitle.length<2){
            return message.error({
                content: "لطفا عنوان برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(currentPlanCost.length<1){
            return message.error({
                content: "لطفا هزینه برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(currentPlanDuration.length<1){
            return message.error({
                content: "لطفا طول دوره برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(currentPlanDeliveryTime.length<1){
            return message.error({
                content: "لطفا حداکثر زمان تحویل برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }

        // let amount = total.replace(",","")

        // currentPlanCost

        console.log(currentPlanCost,currentPlanDuration,my.subscribe_cost)

        let cost_num= currentPlanCost.replaceAll(",", "");
        let discount_num= currentPlanDiscount.replaceAll(",", "");

        let payable = parseInt(cost_num)-parseInt(discount_num)


        let fee= my.subscribe_cost*(currentPlanDuration/30)

        if(fee>payable){
            return message.error({
                content: `حداقل مبلغ باید ${numberWithCommas(fee)} تومان باشد `,
                className: 'toast-class'
            });
        }


        let  obj =  {
            id:uuidv4(),
            title:currentPlanTitle,
            cost:cost_num,
            amount:parseInt(cost_num),
            discount:parseInt(discount_num),
            payable_amount:payable,
            delivery_time:currentPlanDeliveryTime,
            duration:currentPlanDuration,
            descriptions:currentPlanDescriptions
        }

        resetPlanForm()
        set_plans([...plans,obj])
    }

    const handleDeletePlan = (p)=>{
        set_plans(plans.filter(f=>f.id!==p.id))
    }

    const save = async ()=>{
        const obj =  {
            video:"",
            image:"",
            full_name : full_name,
            descriptions:descriptions,
            instagram:instagram,
            whatsApp:whatsApp,
            telegram:telegram,
            website:website,
            email:email,
            phone:phone,
            plans:plans
        }

        console.log(page)
        try{
            const res = await editLandApi({data:obj},page._id)
        }catch (e) {
            console.log(e)
        }
        console.log(obj)
    }



    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);


    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])


    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
            set_imageFile(e.target.files[0])
        }
    }
    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const uploadThumb = async ()=>{
        set_spin(true)
        if(imageFile){
            try{
                await trainerLandUploadApi(imageFile);
                getLand()
                set_spin(false)
                set_imagePrev(null)
            }catch(e){
                set_spin(false)
                console.log(e)
            }
        }

    }

    const handleDeliverChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_currentPlanDeliveryTime(value);
    };
    const handlePriceChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_currentPlanCost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };
    const handleDiscountChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_currentPlanDiscount(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };


    const calcTotal = ()=>{
        console.log("cost",currentPlanCost,typeof currentPlanCost)
        console.log("discount",currentPlanDiscount,typeof currentPlanDiscount)
        let c = currentPlanCost;
        let d = currentPlanDiscount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }

    return(
        <div className="dumbbell-land-create-container">
            <DumbbellHeader backColor={"#8A86FE"} user={my}/>

            <div className="dumbbell-land-create-btns">

                <div className="dumbbell-land-create-btn save" onClick={e=>{
                    save()
                }}>
                    <span>ذخیره</span>
                </div>

                <div className="dumbbell-land-create-btn link" onClick={e=>{
                    window.open(page.page_link, '_blank');
                }}>
                    <span>مشاهده صفحه</span>
                </div>

            </div>


            {/*<img onClick={handleClickItems} className="land-pic" src={imagePrev} alt=""/>*/}
            <Spin spinning={spin}>

                {imagePrev?
                    <ReactPlayer width={"100%"}  url={imagePrev} controls />
                    :null
                }

                {imagePrev?<div className="land-upload-btn">
                <span  onClick={e=>{
                    uploadThumb()
                }}>آپلود ویدیو</span>
                </div>:null}

                    {video && !imagePrev?
                        <div className="land-pic">
                            <ReactPlayer width={"100%"}   url={Setup.filePoint+video+"?id="+uuidv4()} controls />

                            <div className="land-change-btn">
                                <span  onClick={handleClickItems}>تغییر ویدیو</span>
                            </div>

                        </div>
                        :null
                    }

                {!imagePrev && !video?
                    <div className="dumbbell-land-create-image-upload" onClick={handleClickItems}>
                        <img src={picIcon} alt=""/>
                        <span>انتخاب ویدیو</span>
                    </div>:null
                }

                <div className="dumbbell-land-create-image-message">
                    <span>حجم تصویر  ارسالی نباید بیشتر از 1 مگا بایت باشد</span>
                </div>
            </Spin>


            <div className="dumbbell-land-create-divider">
                <div className="dumbbell-land-create-divider-line"></div>
            </div>




            <div className="dumbbell-land-create-info-sec">

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={full_name}
                        onChange={e=>set_full_name(e.target.value)}
                        type="text" name="" id="" placeholder="نام"/>
                </div>

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={instagram}
                        onChange={e=>set_instagram(e.target.value)}
                        type="text" name="" id="" placeholder="لینک اینستاگرام"/>
                </div>

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={telegram}
                        onChange={e=>set_telegram(e.target.value)}
                        type="text" name="" id="" placeholder="لینک تلگرام"/>
                </div>

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={whatsApp}
                        onChange={e=>set_whatsApp(e.target.value)}
                        type="text" name="" id="" placeholder="لینک واتس آپ"/>
                </div>

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={website}
                        onChange={e=>set_website(e.target.value)}
                        type="text" name="" id="" placeholder="لینک وب سایت"/>
                </div>

                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={email}
                        onChange={e=>set_email(e.target.value)}
                        type="text" name="" id="" placeholder="آدرس ایمیل"/>
                </div>
                <div className="dumbbell-land-create-info-add-input">
                    <input
                        value={phone}
                        onChange={e=>set_phone(e.target.value)}
                        type="text" name="" id="" placeholder="تلفن تماس"/>
                </div>


                <div className="dumbbell-land-create-info-add-input">
                    <img src={plusBlueIcon} alt="" onClick={e=>{
                        handleAddDescription()
                    }}/>
                    <input
                        value={descriptionInput}
                        onChange={e=>set_descriptionInput(e.target.value)}
                        type="text" name="" id="" placeholder="توضیحات"/>
                </div>

                <div className="dumbbell-land-create-info-add-list">
                    {descriptions.map(d=>{
                        return(
                            <div className="dumbbell-land-create-info-add-item">
                                <span className="item-text">{d.text}</span>
                                <img src={trashWhiteIcon} className="item-bullet" onClick={e=>{
                                    handleDeleteDescription(d)
                                }}/>
                            </div>
                        )
                    })}

                </div>

            </div>




            <div className="dumbbell-land-create-divider">
                <div className="dumbbell-land-create-divider-line"></div>
            </div>


            <div className="dumbbell-land-create-plan-sec">

                <div className="dumbbell-land-create-plan-card">

                    <div className="dumbbell-land-create-plan-card-header">
                        <div className="dumbbell-land-create-plan-card-header-item">
                            <input
                                value={currentPlanTitle}
                                onChange={e=>set_currentPlanTitle(e.target.value)}
                                type="text" name="" id="" placeholder="عنوان برنامه"/>
                        </div>
                    </div>

                    <div className="dumbbell-land-create-plan-card-main">

                        <div className="dumbbell-land-create-plan-card-deliver-time">
                            <input
                                value={currentPlanCost}
                                onChange={e=>handlePriceChange(e)}
                                type="text" name="" id="" placeholder="هزینه برنامه (تومان)"/>
                        </div>

                        <div className="dumbbell-land-create-plan-card-deliver-time">
                            <input
                                value={currentPlanDiscount}
                                onChange={e=>handleDiscountChange(e)}
                                type="text" name="" id="" placeholder="تخفیف برنامه (تومان)"/>
                        </div>

                        <div className="dumbbell-land-create-plan-card-deliver-time">
                            <select
                                placeholder="طول دوره (روز)"
                                value={currentPlanDuration}
                                onChange={e=>set_currentPlanDuration(e.target.value)}
                                className="s-land-input" name="" id="">
                                <option value={30}>1 ماهه</option>
                                <option value={60}>2 ماهه</option>
                                <option value={90}>3 ماهه</option>
                                <option value={120}>4 ماهه</option>
                                <option value={150}>5 ماهه</option>
                                <option value={180}>6 ماهه</option>
                                <option value={210}>7 ماهه</option>
                                <option value={240}>8 ماهه</option>
                                <option value={270}>9 ماهه</option>
                                <option value={300}>10 ماهه</option>
                                <option value={330}>11 ماهه</option>
                                <option value={365}>1 ساله</option>
                                <option value={730}>2 ساله</option>
                                <option value={1460}>3 ساله</option>
                            </select>
                        </div>

                        <div className="dumbbell-land-create-plan-card-deliver-time">
                            <input
                                value={currentPlanDeliveryTime}
                                onChange={e=>handleDeliverChange(e)}
                                type="text" name="" id="" placeholder="زمان تحویل (روز)"/>
                        </div>

                        <div className="dumbbell-land-create-plan-card-desc-input">
                            <img src={plusBlueIcon} alt="" onClick={e=>{
                                handleAddPlanDescription()
                            }}/>
                            <input
                                value={currentPlanDescriptionInput}
                                onChange={e=>set_currentPlanDescriptionInput(e.target.value)}
                                type="text" name="" id="" placeholder="توضیحات"/>
                        </div>

                        <div className="total-section">
                            <span className="total-section-label">{`مبلغ قابل پرداخت`}</span>
                            <span className="total-section-price">{calcTotal()} تومان </span>
                        </div>

                        <div className="dumbbell-land-create-plan-card-desc-list">

                            {currentPlanDescriptions.map(c=>{
                                return(
                                    <div className="dumbbell-land-create-plan-card-desc-item">
                                        <span className="desc-text">{c.text}</span>
                                        <img src={trashWhiteIcon} className="desc-bullet" onClick={e=>{
                                            handleDeletePlanDescription(c)
                                        }}/>
                                    </div>
                                )
                            })}

                        </div>

                    </div>

                    <div className="dumbbell-land-create-plan-card-plus-badg" onClick={e=>{
                        addPlan()
                    }}>
                        <img src={plusBlueIcon} alt=""/>
                    </div>

                </div>

            </div>


            <div className="plans">
                {plans.map(p=>{
                    return(
                        <div className="dumbbell-land-create-plan-sec">

                            <div className="dumbbell-land-create-plan-card">

                                <div className="dumbbell-land-create-plan-card-header-view">
                                    <div className="dumbbell-land-create-plan-card-header-item">
                                        <img
                                            onClick={e=>{
                                                handleDeletePlan(p)
                                            }}
                                            className="trash-class" src={trashWhiteIcon} alt=""/>
                                    </div>
                                    <div className="dumbbell-land-create-plan-card-header-item">
                                        <span className="plan-title">{p.title}</span>
                                    </div>
                                </div>

                                <div className="dumbbell-land-create-plan-card-main">

                                    <div className="dumbbell-land-create-plan-card-deliver-time-view">
                                        <span>{p.cost} تومان </span>
                                        <span>هزینه برنامه(تومان)</span>
                                    </div>

                                    <div className="dumbbell-land-create-plan-card-deliver-time-view">
                                        <span>{p.duration} روز </span>
                                        <span>طول مدت برنامه(روز)</span>
                                    </div>

                                    <div className="dumbbell-land-create-plan-card-deliver-time-view">
                                        <span>{p.delivery_time} روز </span>
                                        <span>حداکثر زمان تحویل برنامه(روز)</span>
                                    </div>

                                    <div className="dumbbell-land-create-plan-card-desc-input-view">
                                        <span>توضیحات</span>
                                    </div>

                                    <div className="dumbbell-land-create-plan-card-desc-list">

                                        {p.descriptions.map(c=>{
                                            return(
                                                <div className="dumbbell-land-create-plan-card-desc-item">
                                                    <span className="desc-text">{c.text}</span>
                                                    <img className="desc-bullet"/>
                                                </div>
                                            )
                                        })}

                                    </div>

                                </div>

                                {/*<div className="dumbbell-land-create-plan-card-plus-badg" onClick={e=>{*/}
                                {/*    addPlan()*/}
                                {/*}}>*/}
                                {/*    <img src={plusBlueIcon} alt=""/>*/}
                                {/*</div>*/}

                            </div>

                        </div>
                    )
                })}
            </div>

            <input type="file" ref={fileRef} name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>

        </div>
    )
}

export default DumbbellLandingPageCreate;
