import React, {useEffect,useState} from "react";
import {Drawer, notification,Spin} from "antd";
import {WarningOutlined,CheckSquareOutlined} from '@ant-design/icons';
import "./ErrorDrawer.scss";

const  ErrorDrawer = ({error})=> {

    const [visible, setVisible] = useState(false);

    useEffect(()=>{
        if(error?.message.length>0){
            setVisible(true)
            setTimeout(()=>{
                setVisible(false)
            },3000)
        }
    },[error])


    const onClose = () => {
        setVisible(false);
    };

    return(
        <div className="Error-comp">
            <Drawer height={66} mask={false}  closable={false}  placement="top" onClose={onClose} visible={visible}>
                {error?.status==="success"?
                    <div className="success-draw">
                        <CheckSquareOutlined style={{
                            color:"#6d72ff",
                            fontSize:25
                        }} />
                        <span className="error-text">{error?.message}</span>
                    </div>
                    :null}

                {error?.status==="error"?
                    <div className="error-draw">
                        <WarningOutlined style={{
                            color:"#faba59",
                            fontSize:25
                        }} />
                        <span className="error-text">{error?.message}</span>
                    </div>
                    :null}

                {error?.status==="info"?
                    <div className="error-sec">
                        <WarningOutlined style={{
                            color:"orange",
                            fontSize:25
                        }} />
                        <span className="error-text">{error?.message}</span>
                    </div>
                    :null}

            </Drawer>

        </div>
    )
}
export {ErrorDrawer}