import React, {Fragment, useEffect, useState} from 'react';
import "./DumbbellCallbackDebt.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {
    loginApi,
    verifyDebtApi,
    verifyInvoiceApi,
    viewInvoiceApi,
    viewInvoiceAuthorityApi
} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory,useLocation} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";

function DumbbellCallbackDebt(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("Authority");
    const status=new URLSearchParams(search).get("Status");

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    const [spin,set_spin] = useState(true)
    const [mode,set_mode] = useState("default") //default - invoice - failed - success
    const [data,set_data] = useState({})

    useEffect(()=>{
        console.log(authority)
        console.log(status)
        getInvoice()
    },[])


    const getInvoice = async ()=>{
        try{
            if(status==="OK" && authority.length>5){
                verifyProcess()
            }else {
                set_spin(false)
                set_mode("failed")
            }
        }catch (e) {
            console.log(e)
        }
    }


    const verifyProcess = async ()=>{
        try{
            await verifyDebtApi(authority)
            set_mode("success")
            set_spin(false)
        }catch (e) {
            set_spin(false)
            set_mode("failed")
        }
    }

    return(
        <div className="dumbbell-login-container">

            <Spin spinning={spin}>

                <div className="dumbbell-login-default">

                    <div className="dumbbell-invoice-page callback-page">

                        <div className="dumbbell-invoice-page-title">
                            <span>{mode==="success"?"پرداخت شد":""}</span>
                            <span>{mode==="failed"?"پرداخت ناموفق":""}</span>
                        </div>


                        {mode==="success"?
                            <div className="dumbbell-callback-message-success">
                                <span className="dumbbell-callback-message-title">پرداخت شما با موفقیت انجام شد</span>
                                <span className="dumbbell-callback-message-title">و بدهی شما تسویه گردید</span>
                            </div>:null

                        }


                        {mode==="failed"?<div className="dumbbell-callback-message-failed">
                            <span className="dumbbell-callback-message-title">پرداخت ناموفق</span>
                        </div>:null}

                    </div>

                </div>


            </Spin>




        </div>
    )
}

export default DumbbellCallbackDebt;
