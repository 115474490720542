import React,{useEffect} from 'react';
import "./DumbbellTrainerProfile.js.scss";

function DumbbellTrainerProfile(){

    return(
        <div>
            <h1>DumbbellTrainerProfile.js ...</h1>
        </div>
    )
}

export default DumbbellTrainerProfile;
