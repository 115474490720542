import React, {useState} from 'react';
import "./DumbbellLandingPageAddBookingDrawer.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {message, Spin} from "antd";
import {numberWithCommas, validation} from "../../Services/utils";
import {addTraineeApi, payLandBooingApi, payLandPlanApi} from "../../Services/Services";
import moment from "moment-jalaali";

function DumbbellLandingPageAddBookingDrawer({trainerId,turn,handleDoneAddTrainee}){


    let [first_name,set_first_name] = useState("")
    let [last_name,set_last_name] = useState("")
    let [mobile,set_mobile] = useState("")
    let [email,set_email] = useState("")

    let [blood,set_blood] = useState("")
    let [gender,set_gender] = useState("")
    let [age,set_age] = useState("")
    let [weight,set_weight] = useState("")

    let [sport_background,set_sport_background] = useState("")
    let [disease_background,set_disease_background] = useState("")
    let [supplement_use_background,set_supplement_use_background] = useState("")
    let [drug_use_background,set_drug_use_background] = useState("")


    const payPlan = async ()=>{

        if(first_name.length<2){
            return message.error({
                content: "لطفا نام را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(last_name.length<2){
            return message.error({
                content: "لطفا نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }


        let obj ={
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            turn:turn,
            trainerId:trainerId
        }

        try{
            let response = await payLandBooingApi(obj)
            window.open(response.data.data.url, '_blank');
            handleDoneAddTrainee()
        }catch (e) {
            console.log(e)
            return message.error({
                content: "مشکلی بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    return(
        <div className="dumbbell-add-trainee-container">

            <DumbbellDrawerHeader title={"ایجاد پرونده ورزشی"}/>

            <div className="dumbbell-add-trainee-form">

                <div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={first_name}
                        onChange={e=>set_first_name(e.target.value)}
                        type="text" name="" id="" placeholder="نام"/>
                </div>

                <div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={last_name}
                        onChange={e=>set_last_name(e.target.value)}
                        type="text" name="" id="" placeholder="نام خانوادگی"/>

                </div>

                <div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={mobile}
                        onChange={e=>set_mobile(e.target.value)}
                        type="text" name="" id="" placeholder="شماره موبایل"/>
                </div>



                {/*<div className="dumbbell-add-trainee-form-item land-input">*/}
                {/*    <input*/}
                {/*        value={email}*/}
                {/*        onChange={e=>set_email(e.target.value)}*/}
                {/*        type="text" name="" id="" placeholder="ایمیل"/>*/}
                {/*</div>*/}



                {/*<div className="dumbbell-add-trainee-form-submit" onClick={e=>{*/}
                {/*    addTrainee()*/}
                {/*}}>*/}
                {/*    <span>ایجاد پرونده ورزشی و پرداخت</span>*/}
                {/*</div>*/}

            </div>



            <div className="dumbbell-invoice-page-form">

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{moment(turn.date,"YYYY-MM-DD").format('jYYYY/jMM/jDD')}</span>
                    <span className="dumbbell-invoice-page-form-label">تاریخ رزرو</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{turn?.startAt}</span>
                    <span className="dumbbell-invoice-page-form-label">ساعت شروع</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{turn?.endAt}</span>
                    <span className="dumbbell-invoice-page-form-label">ساعت پایان</span>
                </div>


                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(turn?.cost)}  تومان</span>
                    <span className="dumbbell-invoice-page-form-label">مبلغ سرویس</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(turn?.pre_cost)}  تومان</span>
                    <span className="dumbbell-invoice-page-form-label">هزینه رزرو سرویس</span>
                </div>

                {/*<div className="dumbbell-invoice-page-form-row">*/}
                {/*    <span className="dumbbell-invoice-page-form-value">{}  تومان</span>*/}
                {/*    <span className="dumbbell-invoice-page-form-label">تخفیف برنامه </span>*/}
                {/*</div>*/}

            </div>

            <div className="dumbbell-invoice-page-payable">
                <span className="dumbbell-invoice-page-payable-value">{numberWithCommas(turn?.pre_cost)}  تومان</span>
                <span className="dumbbell-invoice-page-payable-label">مبلغ قابل پرداخت</span>
            </div>

            <Spin spinning={false}>
                <div className="dumbbell-login-btn" onClick={e=>{
                    payPlan()
                }}>
                    <span>پرداخت</span>
                </div>
            </Spin>

        </div>
    )
}

export default DumbbellLandingPageAddBookingDrawer;
