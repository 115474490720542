import axios from 'axios';
import {Setup} from './Setup';
import {useHistory} from "react-router";

const API =  axios.create({
    baseURL: Setup.endpoint
});

const UNAUTHORIZED = 401

API.interceptors.response.use(
    response=>response,
    error => {
        console.log("error.response",error.response)
        const {status,data} = error.response;
        if(status === UNAUTHORIZED){
            if(data.zone==="trainee"){
                // localStorage.clear();
                // window.location.href= "/login-trainee"
            }
            if(data.zone==="trainer"){
                // localStorage.clear();
                // window.location.href= "/login-trainer"
            }

        }
        return Promise.reject(error)
    }
);

const API_PAYMENT =  axios.create({
    baseURL: Setup.payment_endpoint+"/api/"+Setup.version
});


export {API,API_PAYMENT};

