import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellPlanCreate.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import sampleFace from "../../assets/images/default_user.png";
import capsuleIcon from "../../assets/dumbbell_image/capsule-icon.png";
import dumbbellIcon from "../../assets/dumbbell_image/dumbbell-icon.png";
import foodIcon from "../../assets/dumbbell_image/food-icon.png";
import micIcon from "../../assets/dumbbell_image/mic-icon.png";
import addPlayIcon from "../../assets/dumbbell_image/V2/add_play_icon.svg";
import weekOnIcon from "../../assets/dumbbell_image/V2/week_on_icon.svg";
import clockIcon from "../../assets/dumbbell_image/V2/clock_icon.svg";
import printIcon from "../../assets/dumbbell_image/V2/print_icon.svg";
import libraryIcon from "../../assets/dumbbell_image/V2/library_icon.svg";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import addCalendarIcon from "../../assets/dumbbell_image/V2/add_calendar_icon.svg";
import addWeekOnIcon from "../../assets/dumbbell_image/V2/add_week_on_icon.svg";
import addWeekIcon from "../../assets/dumbbell_image/V2/add_week_icon.svg";
import addCompIcon from "../../assets/dumbbell_image/V2/add_comp_icon.svg";
import videoWhiteIcon from "../../assets/dumbbell_image/V2/video_white_icon.svg";

import addTrashIcon from "../../assets/dumbbell_image/V2/add_trash_icon.svg";
import addMergeIcon from "../../assets/dumbbell_image/V2/add_merge_icon.svg";
import addMergeOnIcon from "../../assets/dumbbell_image/V2/add_merge_on_icon.svg";
import addSuperIcon from "../../assets/dumbbell_image/V2/add_super_icon.svg";
import addSuperOnIcon from "../../assets/dumbbell_image/V2/add_super_on_icon.svg";



import editIcon from "../../assets/dumbbell_image/edit-icon.png";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import { v4 as uuidv4 } from 'uuid';
import {Setup} from "../../Services/Setup";
import { CloseCircleOutlined,CompressOutlined,CodeSandboxOutlined ,UserOutlined,CloudUploadOutlined,SkypeOutlined,CloseOutlined,LinkOutlined} from '@ant-design/icons';
import {Drawer, message, Popconfirm, Spin, Switch} from "antd";
import moment from "moment-jalaali";
import {useParams} from "react-router";
import ReactPlayer from 'react-player'
import {
    changePlanModeApi,
    deleteLibItem_api, deleteVideo_api,
    getLib_api, getMyProfileApi, getTraineeApi,
    mediaChallengeDeleteApi,
    mediaDeleteApi,
    mediaRedDeleteApi,
    planOneApi, saveAndSendPlanApi,
    savePlanApi, searchVideo_api, videoUploadApi
} from "../../Services/Services";
import {checkPlanEndDate, convertMonthIntNumberToName} from "../../Services/utils";
import DumbbellTrainerMenu from "../DumbbellTrainerMenu/DumbbellTrainerMenu";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import {useFocus} from "../../Hook/useFocus";
import messageIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import DumbbellRequestPlan from "../DumbbellRequestPlan/DumbbellRequestPlan";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg";
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg";
import DumbbellVideoList from "../DumbbellVideoList/DumbbellVideoList";
let globalFilter = "all"
let deleteFlag = false

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


function transformToWeek (times){
    let customWeek = null
    let complete = false
    times.forEach((t,index)=>{
        if(!complete && t.weekDay==="شنبه"){
            customWeek = times.slice(index,index+7)
            complete =true
        }
    })
    return customWeek
}

function calcTimes (times,planMode){
    if(planMode==="days")
        return times

    if(planMode==="week"){
        console.log("week : ",times)
        return transformToWeek(times)
    }

}

const params = {
    slidesPerView: 'auto',
    spaceBetween: 5,
    // rebuildOnUpdate : true,
    rtl:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}

function SuperHeaderCard({part,breakSuperSet,changeSuperItems,supers}){

    const [superItems,set_superItems] = useState(null)

    //super rest
    const [visibleRestDrawer,set_visibleRestDrawer] = useState(false)
    const onRestDrawerClose = ()=>{
        set_visibleRestDrawer(false)
    }
    const showRestDrawer = ()=>{
        set_visibleRestDrawer(true)
    }


    const handleSelectItems  =(type,val)=>{
        changeSuperItems(part,type,val)
        onRepeatDrawerClose()
        onRestDrawerClose()
    }

    //super repeat
    const [visibleRepeatDrawer,set_visibleRepeatDrawer] = useState(false)
    const onRepeatDrawerClose = ()=>{
        set_visibleRepeatDrawer(false)
    }
    const showRepeatDrawer = ()=>{
        set_visibleRepeatDrawer(true)
    }

    useEffect(()=>{
        let superIt = null
        supers.forEach(s=>{
            s.others.forEach(o=>{
                if(o.id===part.id){
                    superIt = s
                }
            })
        })

        if(superIt){
            console.log("Super : ",superIt)
            set_superItems(superIt)
        }



    },[])

    return(
        <>
            <div className="plan-card-v2-super">
                <div className="plan-exercise-card-main">
                    <div className="plan-exercise-card-super-header">
                        <div className="plan-exercise-card-super-actions">
                            <div className="plan-exercise-card-super-actions-item clickable" onClick={e=>{
                                breakSuperSet(part)
                            }}>
                                <CompressOutlined/>
                            </div>

                        </div>
                        {superItems?.items?
                            <div className="plan-exercise-card-main-body-child">
                                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{
                                    showRepeatDrawer(part)
                                }}>
                                    <span className="plan-exercise-card-main-body-child-item-val">{superItems?.items[1].value}</span>
                                    <span className="plan-exercise-card-main-body-child-item-label">تکرار</span>
                                </div>
                                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{
                                    showRestDrawer(part)
                                }}>
                                    <span className="plan-exercise-card-main-body-child-item-val">{superItems?.items[3].value}</span>
                                    <span className="plan-exercise-card-main-body-child-item-label">استراحت(ثانیه)</span>
                                </div>
                            </div>:null
                        }

                        <div className="plan-exercise-card-main-header-super-name" >
                            <span>سوپر ست</span>
                        </div>

                    </div>

                </div>

                <div className={"plan-card-number-super"}>
                    <span className="super-bullet"></span>
                    <div className={"plan-card-number-line-super"}></div>
                </div>

            </div>

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onRestDrawerClose} visible={visibleRestDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span> استراحت بین سوپر</span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",5)}>
                            <span>5 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",10)}>
                            <span>10 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",15)}>
                            <span>15 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",20)}>
                            <span>20 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",25)}>
                            <span>25 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",30)}>
                            <span>30 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",35)}>
                            <span>35 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",40)}>
                            <span>40 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",45)}>
                            <span>45 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",50)}>
                            <span>50 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",55)}>
                            <span>55 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",60)}>
                            <span>1 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",120)}>
                            <span>2 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",180)}>
                            <span>3 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",240)}>
                            <span>4 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",300)}>
                            <span>5 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",360)}>
                            <span>6 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",420)}>
                            <span>7 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",480)}>
                            <span>8 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",540)}>
                            <span>9 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",600)}>
                            <span>10 دقیقه</span>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onRepeatDrawerClose} visible={visibleRepeatDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span>تعداد تکرار سوپر</span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",1)}>
                            <span>1 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",2)}>
                            <span>2 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",3)}>
                            <span>3 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",4)}>
                            <span>4 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",5)}>
                            <span>5 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",6)}>
                            <span>6 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",7)}>
                            <span>7 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",8)}>
                            <span>8 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",9)}>
                            <span>9 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",10)}>
                            <span>10 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",11)}>
                            <span>11 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",12)}>
                            <span>12 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",13)}>
                            <span>13 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",14)}>
                            <span>14 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",15)}>
                            <span>15 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",16)}>
                            <span>16 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",17)}>
                            <span>17 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",18)}>
                            <span>18 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",19)}>
                            <span>19 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>20 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>30 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>40 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>50 تکرار</span>
                        </div>

                    </div>
                </div>
            </Drawer>
        </>

    )
}


function SuppPartCard({plan,
                          supp,
                          alternatives,
                          set_supp,
                          breakFromLink,
                          setAlterToRoot,
                          supers,
                          set_plan,
                          set_links,
                          handleSelectPartV2,
                          selectPartv2,
                          currentSection,currentTimes,part,
                          handleDeletePartItems,
                          showHourDrawer,
                          setInputFocus,
                          set_selectedPart,
                          selectedPart,handleClickDeletePart}){


    const addToMeals = (p)=>{
        let find =  supp.find(l=>l.id===p.id)
        if(find)
            set_supp(supp.filter(l=>l.id!==p.id))
        else
            set_supp([...supp,p])
    }

    const [isSuper,set_isSuper] = useState(false)
    const [isAlter,set_isAlter] = useState(false)


    const existInMeals = (p)=>{
        return supp.find(l=>l.id===p.id)
    }

    const [visible,set_visible] = useState(false)
    const [myAlters,set_myAlters] = useState([])


    const checkIsFirstSuper  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        supers.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isSuper:isAlter,isFirstSuper:pIsFirst};
    }


    const checkIsFirstAlter  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        alternatives.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isAlter:isAlter,isFirstAlter:pIsFirst};
    }



    useEffect(()=>{
        console.log("yyyy 8889")
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })

        console.log("hasAlter : ",hasAlter)
        console.log("isAlterRoot : ",isAlterRoot)

        if(hasAlter){
            set_isAlter(true)
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_isAlter(false)
            set_visible(true)
        }

    },[alternatives,plan])






    //sort Drawer
    const [sortDrawer,set_sortDrawer] = useState(false)
    const [sortList,set_sortList] = useState([])
    const showSortDrawer = ()=>{
        let sList = []
        let c = {...plan}
        // eslint-disable-next-line no-unused-expressions
        c[currentSection]?.times[currentTimes]?.parts.forEach(p=>{
            //check if alter -> first of alter
            let  {isAlter,isFirstAlter} = checkIsFirstAlter(p)
            let  {isSuper,isFirstSuper} = checkIsFirstSuper(p)

            if(!isAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }

            if(isFirstSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }
            if(isFirstAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }
        })
        console.log("DDDDDD : ",isSuper ,isAlter,c[currentSection]?.times[currentTimes]?.parts)
        if(!isSuper && !isAlter){
            set_sortList(sList)
            set_sortDrawer(true)
        }

    }
    const closeSortDrawer = ()=>{
        set_sortDrawer(false)
    }

    const changeAlterAndSupersOrder = (r,index)=>{
        supers.forEach(s=>{
            if(s.root.id===r.id){
                s.root.order = index+1
            }
            s.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })

        alternatives.forEach(a=>{
            if(a.root.id===r.id){
                a.root.order = index+1
            }
            a.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })
    }

    const clickOnResortItem =(destination)=>{
        console.log(part.order)
        console.log(destination.order)
        let clone = {...plan}
        let re = clone[currentSection].times[currentTimes].parts
        // let supers = plan[currentSection].times[currentTimes]['supers']
        // let alternatives = plan[currentSection].times[currentTimes]['alternatives']
        let newParts =[]
        re.forEach((r,index)=>{
            console.log("part.order : ",part.order,r.order,destination.order)
            if(r.id!==part.id && r.order<destination.order){
                console.log(r.text,index,"....down")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order===destination.order){
                console.log(r.text,index," in")
                newParts.push({...part,order:index+1})
                changeAlterAndSupersOrder(part,index)
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order>destination.order && r.order<part.order ){
                console.log(r.text,index,"....up")
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index+1)
                return
            }
            if(r.id!==part.id && r.order>part.order){
                console.log(r.text,index,"....up2")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }

        })

        let final_part =[]
        newParts.forEach((f,index)=>{
            final_part.push({...f,order:index+1})
            changeAlterAndSupersOrder(f,index)
        })

        console.log("final_part  meal: ",final_part)
        console.log("alternatives : ",alternatives)

        clone[currentSection].times[currentTimes].parts = final_part


        set_plan({})
        set_plan(clone)
        set_links([])

    }



    return(
        <Fragment>
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF",borderRadius:"0 !important"}} placement={"left"}  width={150} closable={false}  onClose={closeSortDrawer} visible={sortDrawer}>
                <div className="sort-drawer-body">
                    <div className="sort-drawer-title">
                        <span>قرار گرفتن قبل از</span>
                    </div>

                    {sortList.map(m=>{
                        return(
                            <div className="sort-drawer-item clickable" onClick={e=>clickOnResortItem(m)}>
                                <span>{m.order} شماره </span>
                            </div>
                        )
                    })}


                </div>
            </Drawer>
            {visible?

               <>
               <div className="plan-card-v2">

                            <div className="plan-exercise-card-main-header-alternatives">
                       {myAlters.map((a,index)=>{
                           return(
                               <div onClick={e=>{
                                   setAlterToRoot(part,a)
                               }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                   <span>{a.order}</span>
                               </div>
                           )
                       })}
                   </div>


                   <div className="queue-sec">
                           <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={"queue-number"} onClick={e=>{
                               handleSelectPartV2(part)
                               setInputFocus()
                               set_selectedPart(part.id)
                               showSortDrawer(part)
                           }}
                           >
                           <span>{part.order}</span>
                       </div>
                   </div>

                   <div className="tools-list">
                       {myAlters.length<1?
                           <div className="tools-item clickable" onClick={e=>{
                                handleClickDeletePart(part)
                               console.log("dddd")
                           }}>
                               <img src={addTrashIcon} alt=""/>
                           </div>
                           :
                           null}


                       {myAlters.length<1?
                           <div className="tools-item clickable" onClick={e=>{
                           addToMeals(part)
                       }}>
                               {existInMeals(part)?
                                   <img src={addMergeOnIcon} style={{height:25}} alt=""/>:
                                   <img src={addMergeIcon} style={{height:25}} alt=""/>
                               }
                       </div>:null}


                       {myAlters.length>1?
                           <div className="tools-item clickable" onClick={e=>{
                               breakFromLink(part)
                           }}>
                               <img src={addCompIcon} style={{height:15}} alt=""/>
                           </div>:null}

                   </div>


                   <div
                       onClick={e=>{
                           console.log(part.id)
                           setInputFocus()
                           set_selectedPart(part.id)
                           handleSelectPartV2(part)
                           showHourDrawer(part)
                       }}
                       className={selectPartv2===part.id?"card-main-header-v2":"card-main-header-v2"}>


                       <div className="plan-exercise-card-main-header-name" >
                           <span style={{margin:"0 4px"}}>{part.text}</span>
                           <img src={clockIcon} alt=""/>
                       </div>
                   </div>


                   <div className="plan-exercise-card-main-body">
                       <div className="plan-exercise-card-main-body-childes">
                           {plan[currentSection]?.times[currentTimes]?.parts.find(p=>p.id===part.id).items.map(i=>{
                                   return(
                                       <div className="plan-exercise-card-main-body-child">
                                           <span>{i.text}</span>
                                           <img onClick={e=>{
                                               handleDeletePartItems(part,i)
                                           }} src={trashWhiteIcon} alt=""/>
                                       </div>
                                   )
                               }

                           )}
                       </div>
                   </div>


               </div>







                   {/*<div className="plan-card-diet">*/}
                   {/*    <div className="plan-exercise-card-main">*/}


                   {/*        <div className="plan-exercise-card-main-header-alternatives">*/}
                   {/*            {myAlters.map((a,index)=>{*/}
                   {/*                return(*/}
                   {/*                    <div onClick={e=>{*/}
                   {/*                        setAlterToRoot(part,a)*/}
                   {/*                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>*/}
                   {/*                        <span>{index+1}</span>*/}
                   {/*                    </div>*/}
                   {/*                )*/}
                   {/*            })}*/}
                   {/*        </div>*/}

                   {/*        <div*/}
                   {/*            onClick={e=>{*/}
                   {/*                console.log(part.id)*/}
                   {/*                setInputFocus()*/}
                   {/*                set_selectedPart(part.id)*/}
                   {/*            }}*/}
                   {/*            className={selectedPart===part.id?"plan-exercise-card-main-header active-part":"plan-exercise-card-main-header"}>*/}

                   {/*            <div className="plan-exercise-card-main-header-actions">*/}

                   {/*                <div className="plan-exercise-card-main-header-delete" onClick={e=>{*/}
                   {/*                    handleClickDeletePart(part)*/}
                   {/*                }}>*/}
                   {/*                    <img src={trashIcon} alt=""/>*/}
                   {/*                </div>*/}

                   {/*                {myAlters.length<1?<div className={existInMeals(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                   {/*                    addToMeals(part)*/}
                   {/*                }}>*/}
                   {/*                    <LinkOutlined />*/}
                   {/*                </div>:null}*/}


                   {/*                { myAlters.length>1?<div onClick={e=>{*/}
                   {/*                    breakFromLink(part)*/}
                   {/*                }}>*/}
                   {/*                    <CompressOutlined />*/}
                   {/*                </div>:null}*/}

                   {/*            </div>*/}



                   {/*            <div className="plan-exercise-card-main-header-name" onClick={e=>{*/}
                   {/*                showHourDrawer(part)*/}
                   {/*            }}>*/}
                   {/*                <span>{part.text}</span>*/}
                   {/*            </div>*/}
                   {/*        </div>*/}

                   {/*        <div className="plan-exercise-card-main-body">*/}
                   {/*            <div className="plan-exercise-card-main-body-childes">*/}
                   {/*                {plan[currentSection]?.times[currentTimes]?.parts[part.id-1]?.items.map(i=>{*/}
                   {/*                        return(*/}
                   {/*                            <div className="plan-exercise-card-main-body-child">*/}
                   {/*                                <span>{i.text}</span>*/}
                   {/*                                <img onClick={e=>{*/}
                   {/*                                    handleDeletePartItems(part,i)*/}
                   {/*                                }} src={trashWhiteIcon} alt=""/>*/}
                   {/*                            </div>*/}
                   {/*                        )*/}
                   {/*                    }*/}

                   {/*                )}*/}
                   {/*            </div>*/}
                   {/*        </div>*/}

                   {/*    </div>*/}

                   {/*    <div className="plan-card-number">*/}
                   {/*        <span>{part.order}</span>*/}

                   {/*    </div>*/}

                   {/*</div>*/}
               </>

                :null}
        </Fragment>
    )
}



function MealPartCard({plan,
                          meals,
                          alternatives,
                          set_meals,
                          set_plan,
                          set_links,
                          supers,
                          handleSelectPartV2,
                          selectPartv2,
                          breakFromLink,
                          setAlterToRoot,
                          currentSection,currentTimes,part,handleDeletePartItems,showHourDrawer,setInputFocus,set_selectedPart,selectedPart,handleClickDeletePart}){


    const addToMeals = (p)=>{
        let find =  meals.find(l=>l.id===p.id)
        if(find)
            set_meals(meals.filter(l=>l.id!==p.id))
        else
            set_meals([...meals,p])
    }

    const [isSuper,set_isSuper] = useState(false)
    const [isAlter,set_isAlter] = useState(false)

    const existInMeals = (p)=>{
        return meals.find(l=>l.id===p.id)
    }

    const [visible,set_visible] = useState(false)
    const [myAlters,set_myAlters] = useState([])


    const checkIsFirstSuper  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        supers.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isSuper:isAlter,isFirstSuper:pIsFirst};
    }


    const checkIsFirstAlter  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        alternatives.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isAlter:isAlter,isFirstAlter:pIsFirst};
    }


    useEffect(()=>{
        console.log("yyyy 8889")
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })

        console.log("hasAlter : ",hasAlter)
        console.log("isAlterRoot : ",isAlterRoot)

        if(hasAlter){
            set_isAlter(true)
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_isAlter(false)
            set_visible(true)
        }

    },[alternatives,plan])


    //sort Drawer
    const [sortDrawer,set_sortDrawer] = useState(false)
    const [sortList,set_sortList] = useState([])
    const showSortDrawer = ()=>{
        let sList = []
        let c = {...plan}
        // eslint-disable-next-line no-unused-expressions
        c[currentSection]?.times[currentTimes]?.parts.forEach(p=>{
            //check if alter -> first of alter
            let  {isAlter,isFirstAlter} = checkIsFirstAlter(p)
            let  {isSuper,isFirstSuper} = checkIsFirstSuper(p)

            if(!isAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }

            if(isFirstSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }
            if(isFirstAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }
        })
        if(!isSuper && !isAlter){
            set_sortList(sList)
            set_sortDrawer(true)
        }

    }
    const closeSortDrawer = ()=>{
        set_sortDrawer(false)
    }

    const changeAlterAndSupersOrder = (r,index)=>{
        supers.forEach(s=>{
            if(s.root.id===r.id){
                s.root.order = index+1
            }
            s.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })

        alternatives.forEach(a=>{
            if(a.root.id===r.id){
                a.root.order = index+1
            }
            a.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })
    }

    const clickOnResortItem =(destination)=>{
        console.log(part.order)
        console.log(destination.order)
        let clone = {...plan}
        let re = clone[currentSection].times[currentTimes].parts
        // let supers = plan[currentSection].times[currentTimes]['supers']
        // let alternatives = plan[currentSection].times[currentTimes]['alternatives']
        let newParts =[]
        re.forEach((r,index)=>{
            console.log("part.order : ",part.order,r.order,destination.order)
            if(r.id!==part.id && r.order<destination.order){
                console.log(r.text,index,"....down")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order===destination.order){
                console.log(r.text,index," in")
                newParts.push({...part,order:index+1})
                changeAlterAndSupersOrder(part,index)
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order>destination.order && r.order<part.order ){
                console.log(r.text,index,"....up")
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index+1)
                return
            }
            if(r.id!==part.id && r.order>part.order){
                console.log(r.text,index,"....up2")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }

        })

        let final_part =[]
        newParts.forEach((f,index)=>{
            final_part.push({...f,order:index+1})
            changeAlterAndSupersOrder(f,index)
        })

        console.log("final_part  meal: ",final_part)
        console.log("alternatives : ",alternatives)

        clone[currentSection].times[currentTimes].parts = final_part


        set_plan({})
        set_plan(clone)
        set_links([])

    }

    return(
        <Fragment>
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF",borderRadius:"0 !important"}} placement={"left"}  width={150} closable={false}  onClose={closeSortDrawer} visible={sortDrawer}>
                <div className="sort-drawer-body">
                    <div className="sort-drawer-title">
                        <span>قرار گرفتن قبل از</span>
                    </div>

                    {sortList.map(m=>{
                        return(
                            <div className="sort-drawer-item clickable" onClick={e=>clickOnResortItem(m)}>
                                <span>{m.order} شماره </span>
                            </div>
                        )
                    })}


                </div>
            </Drawer>
            {visible?
                <>

                    <div className="plan-card-v2">

                        <div className="plan-exercise-card-main-header-alternatives">
                            {myAlters.map((a,index)=>{
                                return(
                                    <div onClick={e=>{
                                        setAlterToRoot(part,a)
                                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                        <span>{a.order}</span>
                                    </div>
                                )
                            })}
                        </div>


                        <div className="queue-sec">
                                <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={"queue-number"} onClick={e=>{
                                    handleSelectPartV2(part)
                                    setInputFocus()
                                    set_selectedPart(part.id)
                                    showSortDrawer(part)
                                }}
                                >
                                <span>{part.order}</span>
                            </div>
                        </div>

                        <div className="tools-list">
                            {myAlters.length<1?
                                <div className="tools-item clickable" onClick={e=>{
                                    handleClickDeletePart(part)
                                    console.log("dddd")
                                }}>
                                    <img src={addTrashIcon} alt=""/>
                                </div>
                                :
                                null}


                            {myAlters.length<1?
                                <div className="tools-item clickable" onClick={e=>{
                                    addToMeals(part)
                                }}>
                                    {existInMeals(part)?
                                        <img src={addMergeOnIcon} style={{height:25}} alt=""/>:
                                        <img src={addMergeIcon} style={{height:25}} alt=""/>
                                    }
                                </div>:null}


                            {myAlters.length>1?
                                <div className="tools-item clickable" onClick={e=>{
                                    breakFromLink(part)
                                }}>
                                    <img src={addCompIcon} style={{height:15}} alt=""/>
                                </div>:null}

                        </div>


                        <div
                            onClick={e=>{
                                console.log(part.id)
                                setInputFocus()
                                set_selectedPart(part.id)
                                handleSelectPartV2(part)
                                showHourDrawer(part)
                            }}
                            className={selectPartv2===part.id?"card-main-header-v2":"card-main-header-v2"}>


                            <div className="plan-exercise-card-main-header-name" >
                                <span>{part.text}</span>
                            </div>
                        </div>


                        <div className="plan-exercise-card-main-body">
                            <div className="plan-exercise-card-main-body-childes">
                                {plan[currentSection]?.times[currentTimes]?.parts.find(p=>p.id===part.id).items.map(i=>{
                                        return(
                                            <div className="plan-exercise-card-main-body-child">
                                                <span>{i.text}</span>
                                                <img onClick={e=>{
                                                    handleDeletePartItems(part,i)
                                                }} src={trashWhiteIcon} alt=""/>
                                            </div>
                                        )
                                    }

                                )}
                            </div>
                        </div>


                    </div>

                    {/*<div className="plan-card-diet">*/}
                    {/*    <div className="plan-exercise-card-main">*/}
                    {/*        <div className="plan-exercise-card-main-header-alternatives">*/}
                    {/*            {myAlters.map((a,index)=>{*/}
                    {/*                return(*/}
                    {/*                    <div onClick={e=>{*/}
                    {/*                        setAlterToRoot(part,a)*/}
                    {/*                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>*/}
                    {/*                        <span>{index+1}</span>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            })}*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            onClick={e=>{*/}
                    {/*                console.log(part.id)*/}
                    {/*                setInputFocus()*/}
                    {/*                set_selectedPart(part.id)*/}
                    {/*            }}*/}
                    {/*            className={selectedPart===part.id?"plan-exercise-card-main-header active-part":"plan-exercise-card-main-header"}>*/}

                    {/*            <div className="plan-exercise-card-main-header-actions">*/}

                    {/*                <div className="plan-exercise-card-main-header-delete" onClick={e=>{*/}
                    {/*                    handleClickDeletePart(part)*/}
                    {/*                }}>*/}
                    {/*                    <img src={trashIcon} alt=""/>*/}
                    {/*                </div>*/}

                    {/*                {myAlters.length<1?<div className={existInMeals(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                    {/*                    addToMeals(part)*/}
                    {/*                }}>*/}
                    {/*                    <LinkOutlined />*/}
                    {/*                </div>:null}*/}


                    {/*                { myAlters.length>1?<div onClick={e=>{*/}
                    {/*                    breakFromLink(part)*/}
                    {/*                }}>*/}
                    {/*                    <CompressOutlined />*/}
                    {/*                </div>:null}*/}

                    {/*            </div>*/}



                    {/*            <div className="plan-exercise-card-main-header-name" onClick={e=>{*/}
                    {/*                showHourDrawer(part)*/}
                    {/*            }}>*/}
                    {/*                <span>{part.text}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="plan-exercise-card-main-body">*/}
                    {/*            <div className="plan-exercise-card-main-body-childes">*/}
                    {/*                {plan[currentSection]?.times[currentTimes]?.parts[part.id]?.items.map(i=>{*/}
                    {/*                        return(*/}
                    {/*                            <div className="plan-exercise-card-main-body-child">*/}
                    {/*                                <span>{i.text}</span>*/}
                    {/*                                <img onClick={e=>{*/}
                    {/*                                    handleDeletePartItems(part,i)*/}
                    {/*                                }} src={trashWhiteIcon} alt=""/>*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    }*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*    <div className="plan-card-number">*/}
                    {/*        <span>{part.order}</span>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                </>
                :null}
        </Fragment>
    )
}

function ExPartCard({part,
                    handleClickDeletePart,
                        showSetDrawer,
                        showRepeatDrawer,
                        showRestDrawer,
                        handleSelectPartV2,
                        selectPartv2,
                        addVideo,
                        removeVideoFromPart,
                        plan,
                        set_plan,
                        sus,
                        set_sus,
                        links,
                        alternatives,
                        supers,
                        set_links,
                        currentTimes,
                        currentSection,
                        setAlterToRoot,
                        breakFromLink,
                        i,
                        uploadVideo
                    }){



    const [thumb,set_thumb] = useState(part.thumb)
    const [video,set_video] = useState(Setup.filePoint+part.video)
    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);

    const [uploadMode,set_uploadMode] = useState("default") // default , video ,upload , pending

    const addToLink = (p)=>{

        let last = links[links.length-1]
        if(last){
            if(p.order-last.order>1)
                return console.log("order error")

            if(p.order<last.order)
                return console.log("order error")

        }

        let find =  links.find(l=>l.id===p.id)
        if(find)
            set_links(links.filter(l=>l.id!==p.id))
            else
        set_links([...links,p])
    }

    const addToSus = (p,alters=[])=>{
        if(alters.length<1){
            let last = sus[sus.length-1]
            if(last){
                if(p.order-last.order>1)
                    return console.log("order error")

                if(p.order<last.order)
                    return console.log("order error")

            }

            let find =  sus.find(l=>l.id===p.id)
            if(find){
                set_sus(sus.filter(l=>l.order<p.order))
            }
            else
                set_sus([...sus,p])
        }else {
            //check order

            let last = sus[sus.length-1]
            if(last){
                if(alters[0].order-last.order>1)
                    return console.log("order error")

                if(alters[0].order<last.order)
                    return console.log("order error")

            }

            let find =  sus.find(l=>l.id===alters[0].id)
            if(find){
                set_sus(sus.filter(l=>l.order<alters[0].order))
            }else
            set_sus([...sus,...alters])
        }

    }


    const existInLinks = (p)=>{
        return links.find(l=>l.id===p.id)
    }
    const existInSus = (p)=>{
        return sus.find(l=>l.id===p.id)
    }

    const [myAlters,set_myAlters] = useState([])

    const [visible,set_visible] = useState(false)
    const [isSuper,set_isSuper] = useState(false)
    const [isAlter,set_isAlter] = useState(false)
    const [isSuperRoot,set_isSuperRoot] = useState(false)


    const isSuperCalc = (p)=>{
        let isSu = false
        supers.forEach(a=>{
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isSu =true
                }
            })
        })

        return isSu
    }


    useEffect(()=>{
        console.log("suuuuuu 777777")
        let isSu = false
        supers.forEach(a=>{
            a.others.forEach(o=>{
                if(part.id===o.id){
                    isSu =true
                }
            })
        })

        if(isSu)
            set_isSuper(true)
        else
            set_isSuper(false)

    },[supers,plan])


    useEffect(()=>{
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })

        if(hasAlter){
            set_isAlter(true)
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_isAlter(false)
            set_visible(true)
        }

    },[alternatives,plan])


    useEffect(()=>{
       if(part?.video?.length > 0){
           set_uploadMode("video")
       }else{
           set_uploadMode("choose")
       }
    },[])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
             set_uploadMode("upload")
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickChoose = (num)=>{
        fileRef.current.click();
    }

    const playVideo = ()=>{
        console.log(Setup.filePoint+part?.video?.video)
        set_video(Setup.filePoint+part?.video?.video)
        showModal()
    }

    const upload = async (title)=>{
        set_uploadMode("pending")
        if(imageFile){
            try{
                let response = await videoUploadApi(imageFile,title,"");
                set_imagePrev(null)
                set_thumb(Setup.filePoint+response.data.data.video)
                set_video(Setup.filePoint+response.data.data.video)
                addVideo(response.data.data.video)
                set_uploadMode("video")
            }catch(e){
                console.log(e)
            }
        }

    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (url) => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const MyRef = useRef(null);

    const handleClickOnVideo = ()=>{
        showVideoDrawer()
    }

    const [visibleVideoDrawer,set_visibleVideoDrawer] = useState(false)
    const onVideoDrawerClose = ()=>{
        set_visibleVideoDrawer(false)
    }
    const showVideoDrawer = ()=>{
        set_visibleVideoDrawer(true)
    }

    const handleSelectVideo =(v)=>{
        console.log(v)
        addVideo(part,v)
        onVideoDrawerClose()
    }


    const confirm = async(p) => {
        removeVideoFromPart(p)
    };
    const cancel = (e) => {
        console.log(e);
    };

    const checkIsFirstAlter  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        alternatives.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isAlter:isAlter,isFirstAlter:pIsFirst};
    }

    const checkIsFirstSuper  =(p)=>{
        let pIsFirst = false
        let isAlter = false
        supers.forEach(a=>{
            let first = null;
            let firstOrder = 100000
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isAlter =true
                }
                if(o.order<firstOrder){
                    firstOrder = o.order
                    first = o
                }
            })
            if(first.id===p.id){
                pIsFirst = true
            }
        })

        return {isSuper:isAlter,isFirstSuper:pIsFirst};
    }


    //sort Drawer
    const [sortDrawer,set_sortDrawer] = useState(false)
    const [sortList,set_sortList] = useState([])
    const showSortDrawer = ()=>{
        let sList = []
        let c = {...plan}
        // eslint-disable-next-line no-unused-expressions
        c[currentSection]?.times[currentTimes]?.parts.forEach(p=>{
            //check if alter -> first of alter
            let  {isAlter,isFirstAlter} = checkIsFirstAlter(p)
            let  {isSuper,isFirstSuper} = checkIsFirstSuper(p)

            if(!isAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }

            if(isFirstSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }
            if(isFirstAlter && !isSuper && p.id!==part.id && p.order-1!==part.order){
                sList.push({order:p.order})
            }

        })
        if(!isSuper && !isAlter){
            set_sortList(sList)
            set_sortDrawer(true)
        }

    }
    const closeSortDrawer = ()=>{
        set_sortDrawer(false)
    }

    const changeAlterAndSupersOrder = (r,index)=>{
        supers.forEach(s=>{
            if(s.root.id===r.id){
                s.root.order = index+1
            }
            s.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })

        alternatives.forEach(a=>{
            if(a.root.id===r.id){
                a.root.order = index+1
            }
            a.others.forEach(o=>{
                if(o.id===r.id){
                    o.order=index+1
                }
            })
        })
    }

    const clickOnResortItem =(destination)=>{
        console.log(part.order)
        console.log(destination.order)
        let clone = {...plan}
        let re = clone[currentSection].times[currentTimes].parts
        let supers = plan[currentSection].times[currentTimes]['supers']
        let alternatives = plan[currentSection].times[currentTimes]['alternatives']
        let newParts =[]
        re.forEach((r,index)=>{
            console.log("part.order : ",part.order,r.order,destination.order)
            if(r.id!==part.id && r.order<destination.order){
                console.log(r.text,index,"....down")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order===destination.order){
                console.log(r.text,index," in")
                newParts.push({...part,order:index+1})
                changeAlterAndSupersOrder(part,index)
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index)
                return
            }
            if(r.id!==part.id && r.order>destination.order && r.order<part.order ){
                console.log(r.text,index,"....up")
                newParts.push({...r,order:index+2})
                changeAlterAndSupersOrder(r,index+1)
                return
            }
            if(r.id!==part.id && r.order>part.order){
                console.log(r.text,index,"....up2")
                newParts.push({...r,order:index+1})
                changeAlterAndSupersOrder(r,index)
                return
            }
        })

        let final_part =[]
        newParts.forEach((f,index)=>{
            final_part.push({...f,order:index+1})
            changeAlterAndSupersOrder(f,index)
        })

        clone[currentSection].times[currentTimes].parts = final_part


        set_plan({})
        set_plan(clone)
        //
        // let find =  links.find(l=>l.id===p.id)
        // if(find)
         set_links([])

    }

    return(
        <Fragment>
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"right"}  width={300} closable={false}  onClose={onVideoDrawerClose} visible={visibleVideoDrawer}>
                <DumbbellVideoList header={false} select={true} handleSelectItem={handleSelectVideo}/>
            </Drawer>
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF",borderRadius:"0 !important"}} placement={"left"}  width={150} closable={false}  onClose={closeSortDrawer} visible={sortDrawer}>
                <div className="sort-drawer-body">
                    <div className="sort-drawer-title">
                        <span>قرار گرفتن قبل از</span>
                    </div>

                    {sortList.map(m=>{
                        return(
                            <div className="sort-drawer-item clickable" onClick={e=>clickOnResortItem(m)}>
                                <span>{m.order} شماره </span>
                            </div>
                        )
                    })}


                </div>
            </Drawer>
            {visible?
                <>

                    <div className="plan-card-v2">

                        <div className="plan-exercise-card-main-header-alternatives">
                            {myAlters.map((a,index)=>{
                                return(
                                    <div onClick={e=>{
                                        setAlterToRoot(part,a)
                                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                        <span>{a.order}</span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={"queue-sec"}>
                            <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={isSuper?"queue-number-super":"queue-number"} onClick={e=>{
                                handleSelectPartV2(part)
                                showSortDrawer(part)
                            }}
                            >
                                <span>{part.order}</span>
                                {plan[currentSection]?.times[currentTimes]?.parts[i+1]
                                    ?
                                    <div className={isSuper && isSuperCalc(plan[currentSection]?.times[currentTimes]?.parts[i+1])?"plan-card-number-line-super":"plan-card-number-line"}></div>
                                    :null}
                            </div>
                        </div>

                        <div className="tools-list">
                            {!isSuper && myAlters.length<1?
                                <div className="tools-item clickable" onClick={e=>{
                                    handleClickDeletePart(part)
                                }}>
                                    <img src={addTrashIcon} alt=""/>
                                </div>
                                :
                                null}

                            {!isSuper && myAlters.length<1?
                            <div className="tools-item clickable" onClick={e=>{
                                addToLink(part)
                            }}>
                                {existInLinks(part)?
                                    <img src={addMergeOnIcon} style={{height:25}} alt=""/>:
                                    <img src={addMergeIcon} style={{height:25}} alt=""/>
                                }
                            </div>:null}



                            {!isSuper?
                            <div className="tools-item clickable" onClick={e=>{
                                addToSus(part,myAlters)
                            }}>
                                {existInSus(part)?
                                    <img src={addSuperOnIcon} alt=""/>:
                                    <img src={addSuperIcon} alt=""/>
                                }

                            </div>:null}

                            {!isSuper && myAlters.length>1?
                                <div className="tools-item clickable" onClick={e=>{
                                    breakFromLink(part)
                                }}>
                                    <img src={addCompIcon} style={{height:15}} alt=""/>
                                </div>:null}

                        </div>

                        <div className="plan-card-v2-body">
                            <div className="plan-card-v2-body-info">
                                <h6>{part.text}</h6>
                                {/*<textarea placeholder="توضیحات">*/}

                                {/*</textarea>*/}
                                {part?.video?.fa_names?.length>0?
                                    <div className="selected-video-label">
                                        <Popconfirm
                                            title="ویدیو از این حرکت حذف بشه ؟"
                                            onConfirm={e=>confirm(part)}
                                            onCancel={cancel}
                                            okText="بله"
                                            cancelText="خیر"
                                        >
                                        <div className="selected-video-label-remove clickable">
                                            <img src={trashWhiteIcon} alt=""/>
                                        </div>
                                        </Popconfirm>
                                        <div className="selected-video-label-text clickable" onClick={playVideo}>
                                            <span>{part.video.fa_names[0]}</span>
                                        </div>
                                    </div>

                                    :
                                    <span>هنوز ویدیویی انتخاب نشده</span>
                                }

                            </div>
                            <div className="plan-card-v2-body-video clickable" onClick={handleClickOnVideo}>
                                <div className="plan-card-v2-body-video-card">
                                    <img src={addPlayIcon} alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className="plan-card-v2-footer">
                            <Swiper ref={MyRef}  {...params}>
                                <div  className="plan-card-v2-footer-item" onClick={e=>{

                                }}>
                                    <span>10</span>
                                    <h6>وزن</h6>
                                </div>
                                <div  className="plan-card-v2-footer-item" onClick={e=>{
                                    showRestDrawer(part)
                                }}>
                                    <span>{part.items[3].value}</span>
                                    <h6>استراحت</h6>
                                </div>
                                <div  className="plan-card-v2-footer-item" onClick={e=>{
                                    showSetDrawer(part)
                                }}>
                                    <span>{part.items[2].value}</span>
                                    <h6>تعداد</h6>
                                </div>
                                <div  className="plan-card-v2-footer-item" >
                                    <span>10</span>
                                    <h6>مدت</h6>
                                </div>
                                <div  className="plan-card-v2-footer-item" onClick={e=>{
                                    showRepeatDrawer(part)
                                }}>
                                    <span>{part.items[1].value}</span>
                                    <h6>تکرار</h6>
                                </div>
                            </Swiper>
                        </div>

                    </div>
                        <Drawer    bodyStyle={{padding:0,margin:0}}  placement="top" height={"auto"}  closable={false}  onClose={handleCancel} visible={isModalOpen}>
                            {isModalOpen?
                                <ReactPlayer playing={isModalOpen} width={"100%"} url={video} controls />:
                                null
                            }
                        </Drawer>

                    {/*<div className="plan-card">*/}
                    {/*    <Drawer    bodyStyle={{padding:0,margin:0}}  placement="top" height={"auto"}  closable={false}  onClose={handleCancel} visible={isModalOpen}>*/}
                    {/*        {isModalOpen?*/}
                    {/*            <ReactPlayer playing={isModalOpen} width={"100%"} url={video} controls />:*/}
                    {/*            null*/}
                    {/*        }*/}

                    {/*    </Drawer>*/}
                    {/*    <input type="file"  accept="video/mp4,video/x-m4v,video/*" ref={fileRef} name="" style={{visibility: "hidden",position: "fixed"}} onChange={handleImageChange} id=""/>*/}
                    {/*    <div className="plan-exercise-card-main">*/}
                    {/*        <div className="plan-exercise-card-main-header-alternatives">*/}
                    {/*            {myAlters.map((a,index)=>{*/}
                    {/*                return(*/}
                    {/*                    <div onClick={e=>{*/}
                    {/*                        setAlterToRoot(part,a)*/}
                    {/*                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>*/}
                    {/*                        <span>{index+1}</span>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            })}*/}
                    {/*        </div>*/}
                    {/*        <div className="plan-exercise-card-main-header">*/}
                    {/*            <div className="plan-exercise-card-main-header-actions">*/}

                    {/*                {!isSuper && myAlters.length<1?*/}
                    {/*                    <div className="plan-exercise-card-main-header-delete" onClick={e=>{*/}
                    {/*                        handleClickDeletePart(part)*/}
                    {/*                    }}>*/}
                    {/*                        <img src={trashIcon} alt=""/>*/}
                    {/*                    </div>:*/}
                    {/*                    null}*/}

                    {/*                {!isSuper && myAlters.length<1?*/}
                    {/*                    <div className={existInLinks(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                    {/*                        addToLink(part)*/}
                    {/*                    }}>*/}
                    {/*                        <LinkOutlined />*/}
                    {/*                    </div>:null*/}
                    {/*                }*/}


                    {/*                {!isSuper && myAlters.length>1?*/}
                    {/*                    <div onClick={e=>{*/}
                    {/*                        breakFromLink(part)*/}
                    {/*                    }}>*/}
                    {/*                        <CompressOutlined />*/}
                    {/*                    </div>:null*/}
                    {/*                }*/}

                    {/*                {!isSuper?<div className={existInSus(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                    {/*                    addToSus(part,myAlters)*/}
                    {/*                }}>*/}
                    {/*                    <SkypeOutlined />*/}
                    {/*                </div>:null}*/}


                    {/*            </div>*/}

                    {/*            <div className="plan-exercise-card-main-header-name" >*/}
                    {/*                <span>{part.text}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="plan-exercise-card-main-body">*/}
                    {/*            <div className="plan-exercise-card-main-body-child">*/}
                    {/*                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{*/}
                    {/*                    showSetDrawer(part)*/}
                    {/*                }}>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-val">{part.items[2].value}</span>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-label">ست</span>*/}
                    {/*                </div>*/}
                    {/*                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{*/}
                    {/*                    showRepeatDrawer(part)*/}
                    {/*                }}>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-val">{part.items[1].value}</span>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-label">تکرار</span>*/}
                    {/*                </div>*/}
                    {/*                /!*<div className="plan-exercise-card-main-body-child-item">*!/*/}
                    {/*                /!*    <span className="plan-exercise-card-main-body-child-item-val">0</span>*!/*/}
                    {/*                /!*    <span className="plan-exercise-card-main-body-child-item-label">مدت</span>*!/*/}
                    {/*                /!*</div>*!/*/}
                    {/*                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{*/}
                    {/*                    showRestDrawer(part)*/}
                    {/*                }}>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-val">{part.items[3].value}</span>*/}
                    {/*                    <span className="plan-exercise-card-main-body-child-item-label">استراحت(ثانیه)</span>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            {uploadMode==="video"?<div onClick={e=>{*/}
                    {/*                playVideo()*/}
                    {/*            }} className="plan-exercise-card-main-body-video"*/}
                    {/*                                       style={{backgroundImage: `url(${thumb})`}}*/}
                    {/*            >*/}
                    {/*                <img src={playIcon} alt=""/>*/}
                    {/*            </div>:null}*/}

                    {/*            {uploadMode==="choose"?<div onClick={handleClickChoose} className="plan-exercise-card-main-body-video-choose">*/}
                    {/*                <span>انتخاب ویدیو</span>*/}
                    {/*            </div>:null}*/}

                    {/*            {uploadMode==="pending"?<div className="pending-upload">*/}
                    {/*                <Spin spinning={true}></Spin>*/}
                    {/*            </div>:null}*/}

                    {/*            {uploadMode==="upload"?<div className="plan-exercise-card-main-body-video-upload">*/}
                    {/*                <div className="plan-exercise-card-main-body-video-upload-do" onClick={e=>{*/}
                    {/*                    upload(part.text)*/}
                    {/*                }}>*/}
                    {/*                    <CloudUploadOutlined />*/}
                    {/*                </div>*/}
                    {/*                <div className="plan-exercise-card-main-body-video-upload-cancel" onClick={e=>{*/}
                    {/*                    set_imagePrev(null)*/}
                    {/*                    set_imageFile(null)*/}
                    {/*                    set_uploadMode("choose")*/}
                    {/*                }}>*/}
                    {/*                    <CloseOutlined />*/}
                    {/*                </div>*/}
                    {/*            </div>:null}*/}


                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*    <div className={isSuper?"plan-card-number-super":"plan-card-number"}>*/}
                    {/*        <span>{part.order}</span>*/}
                    {/*        {plan[currentSection]?.times[currentTimes]?.parts[i+1]?<div className={isSuper?"plan-card-number-line-super":"plan-card-number-line"}></div>:null}*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                </>
                :null
            }
        </Fragment>
    )
}

function existInSelectedCopyTo(list,item){
    return list.find(l=>l.index===item.index)
}


const DeletableCardComp = ({children,item,deleteMedia})=>{

    const [deleteStatus,set_deleteStatus] = useState(false)
    const errorDispatch = useDispatchError()

    const onLongPress = () => {
        set_deleteStatus(true)
        console.log("ssssssssssssssssss")
    };
    const onClick = () => {
        console.log("cccccccc")
    }

    const defaultOptions = {
        shouldPreventDefault: false,
        delay: 1000,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return(
        <div className="deletable-comp" {...longPressEvent}>
            {children}
        </div>
    )
}


function DumbbellPlanCreate(){

    const MyRef = useRef(null);
    const {planId,traineeId} = useParams()
    // const [message, setMessage] = useState('')
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        browserSupportsContinuousListening
    } = useSpeechRecognition();


    useEffect(()=>{
        set_searchWord(transcript)
        set_mealInput(transcript)
    },[transcript])







    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_visibleChatTraineeDrawer(false);
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };

    const breakSuperSet = (p)=>{
        let clone = {...plan}
        let supers = clone[currentSection].times[currentTimes]['supers']
        let cop = [...supers]
        cop = cop.filter(c=>c.root.id!==p.id)
        clone[currentSection].times[currentTimes]['supers'] = cop
        set_plan({})
        set_plan(clone)
        set_links([])
    }

    const changeSuperItems = (part,type,val)=>{
        console.log("ssssss",type,val)
        console.log(currentPart)
        console.log(currentItems)
        let clone = {...plan}
        let supers = clone[currentSection].times[currentTimes].supers;
        supers.forEach(s=>{
            s.others.forEach(o=>{
                if(o.id===part.id){
                        s.items.forEach(i=>{
                            if(i.label===type)
                                i.value=val
                        })
                }
            })
        })
        set_plan(clone)
    }

    const checkSuperRoot = (part,supers)=>{
        let isSuperRoot = false
        supers.forEach(a=>{
            if(part.id===a.root.id){
                isSuperRoot= true
            }
        })

        return isSuperRoot
    }

    const [startAt,set_startAt] = useState("")
    const [basePlan,set_basePlan] = useState({})
    const [plan,set_plan] = useState(
        {
            supplement:{
                type:"week", // week,day
                times:[]
            },
            diet:{
                type:"week", // week,day
                times:[]
            },
            exercise:{
                type:"week", // week,day
                times:[]
            }
        }
    )

    useEffect(()=>{
        // setTemplate("exercise");
        // generateTimes(new Date())
        getMyProfile()
        getLib()
        getPlan()
    },[])


    const [planMode,set_planMode] = useState("")

    const handleChangePlanMode = async (m)=>{
        set_planMode(m)
        try{
            const resp = await changePlanModeApi(basePlan._id,m)
        }catch (e) {
            console.log(e)
        }
    }

    const [unread,set_unread] = useState(0)
    const [trainee,set_trainee] = useState(null)
    const getTrainee = async (p)=>{
        try{
            const res = await getTraineeApi(p.trainee_id)
            set_trainee(res.data.data.trainee)
            set_unread(res.data.data.unread)
        }catch (e) {
            console.log(e)
        }
    }

    const [firstInit,set_firstInit] = useState(false)
    const [plan_ground,set_plan_ground] = useState(true)
    useEffect(()=>{
         console.log("aaaaaaaaaaaaa :",plan[currentSection])
        // if(deleteFlag){
        //     set_plan_ground(false)
        //     setTimeout(()=>{
        //         set_plan_ground(true)
        //     },0)
        //     deleteFlag =false
        // }
        //
        if(!firstInit && plan[currentSection].times.length>0){
            console.log("First Init ",plan[currentSection])
            handleClickOnTimes(plan[currentSection].times[0])
            set_firstInit(true)
        }
    },[plan])

    const [planIsEnd,set_planIsEnd] = useState(false);

    const getPlan = async ()=>{
        try{
            console.log("Params : -------- ",planId,traineeId);
            const response = await planOneApi(traineeId,planId)
            set_planIsEnd(checkPlanEndDate(response.data.data))
            getTrainee(response.data.data)
            let p = response.data.data;
            let diet = p.data.diet.times
            let exercise = p.data.exercise.times
            let supplement = p.data.supplement.times
            set_basePlan(p)
            set_planMode(p.mode)
            set_plan({
                supplement:{
                    times:supplement.length<1?generateTimes(p):supplement
                },
                diet:{
                    times:diet.length<1?generateTimes(p):diet
                },
                exercise:{
                    times:exercise.length<1?generateTimes(p):exercise
                }
            })



            if(p.startAt){
                set_startLabel(p.startAt)
            }else{
                initStartDate()
            }
        }catch(e){
            console.log(e)
        }
    }

    const generateTimes = (p)=>{
        console.log("Generating exercise")
        let start = new Date()
        let results =[]
        for (let i = 0; i < p.duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,alternatives:[],supers:[],parts:[]})
        }

        return results

    }

    //re generate start Date
    const[reStartDay,set_reStartDay] = useState('0')
    const[reStartMonth,set_reStartMonth] = useState('0')
    const[reStartYear,set_reStartYear] = useState('0')

    const initStartDate =()=>{
        let d = moment(new Date()).format('jDD')
        let m = moment(new Date()).format('jMM')
        let y = moment(new Date()).format('jYYYY')
        console.log(d,m,y)
        set_reStartDay(d)
        set_reStartMonth(m)
        set_reStartYear(y)
        set_startLabel(`${y}/${m}/${d}`)
    }


    const reTimes = (start,duration)=>{
        let results =[]
        for (let i = 0; i < duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }
        return results
    }


    const [startLabel,set_startLabel] = useState("")
    const reGenerateTimes = ()=>{
        let duration = basePlan.duration
        let jFormat = reStartYear+"/"+reStartMonth+"/"+reStartDay
        let start = moment(jFormat, 'jYYYY/jM/jD').toDate()



        let copy = {...plan}
        copy['supplement'].times = reTimes(start,duration)
        copy['diet'].times = reTimes(start,duration)
        copy['exercise'].times = reTimes(start,duration)
        set_plan(copy)
        set_startLabel(jFormat)
        onStartDrawerClose()
    }

    const [currentSection,set_currentSection]= useState("exercise") //supplement, diet ,exercise
    const [currentTimes,set_currentTimes]= useState("")
    const [selectTimes,set_selectTimes]= useState({})

    const setWeekTemplate = (section)=>{
        let obj = [
            {index:0,label:"شنبه", parts:[]},
            {index:1,label:"یکشنبه", parts:[]},
            {index:2,label:"دوشنبه", parts:[
                    {
                        video:"",
                        text:"",
                        order:1,
                        items:[
                            {type:"exercise",label:"duration",value:5},
                            {type:"exercise",label:"repeat",value:10},
                            {type:"exercise",label:"set",value:4},
                            {type:"exercise",label:"rest",value:60},
                        ]
                    },
                    {
                        video:"",
                        text:"",
                        order:2,
                        items:[
                            {type:"exercise",label:"duration",value:5},
                            {type:"exercise",label:"repeat",value:10},
                            {type:"exercise",label:"set",value:4},
                            {type:"exercise",label:"rest",value:60},
                        ]
                    }
                ]},
            {index:3,label:"سشنبه", parts:[]},
            {index:4,label:"چهارشنبه", parts:[]},
            {index:5,label:"پنجشنبه", parts:[
            {
                video:"",
                text:"",
                order:1,
                items:[
                    {type:"exercise",label:"duration",value:5},
                    {type:"exercise",label:"repeat",value:10},
                    {type:"exercise",label:"set",value:4},
                    {type:"exercise",label:"rest",value:60},
                ]
            }
        ]},
            {index:6,label:"جمعه", parts:[]},
        ]
        let copyOfPlan = {...plan}
        copyOfPlan[section] = {type:"week",times:obj}
        set_plan(copyOfPlan);
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }

    const [swiperReset,set_swiperReset] = useState(true)
    const setDayTemplate = (section,startTime,endTime)=>{
        let obj = [
            {index:0,label:"1 دی", parts:[]},
            {index:1,day:"1",weekDay:"شنبه", parts:[]},
            {index:2,day:"2",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"3",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"4",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]
        let copyOfPlan = {...plan}
        copyOfPlan[section] = {type:"week",times:obj}
        set_plan(copyOfPlan)
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }

    const convertDayOfWeek = (num)=>{
        console.log("num",num)
        switch(num){
            case 1:
                return "دوشنبه"
                break;
            case 2:
                return "سشنبه"
                break;
            case 3:
                return "چهارشنبه"
                break;
            case 4:
                return "پنجشنبه"
                break;
            case 5:
                return "جمعه"
                break;
            case 6:
                return "شنبه"
                break;
            case 0:
                return "یکشنبه"
                break;


        }
    }

    // const generateTimes = (start)=>{
    //     let results = [
    //         {day:"1",weekday:"شنبه"},
    //         {day:"2",weekday:"شنبه"}
    //     ]
    //     let startDate = start;
    //     let date = new Date()
    //     let end = date.addDays(58)
    //     let gg = moment(end).format('jYYYY/jMM/jDD')
    //     console.log("END : ",convertDayOfWeek(moment(end).day()))
    //     console.log("END : ",gg)
    // }
    const setTemplate = (section,startTime,endTime)=>{
        let objExer = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let objSupp = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let objDiet = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let copyOfPlan = {...plan}
        copyOfPlan["exercise"] = {type:"week",times:objExer}
        copyOfPlan["diet"] = {type:"week",times:objDiet}
        copyOfPlan["supplement"] = {type:"week",times:objSupp}
        set_plan(copyOfPlan)
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }


    const handleChangeSectionType = (e)=>{
        if(e.target.value==="week")
            setWeekTemplate(currentSection)
        if(e.target.value==="day")
            setDayTemplate(currentSection)


    }

    const handleClickOnTimes =(t)=>{
        if(currentTimes===t.index)
            showOptionDrawer(t)
        set_currentTimes(t.index)
        set_selectTimes(t)



    }

    const [listBottom,set_listBottom] = useState(-322)
    const [searchFilter,set_searchFilter] = useState("all") // all , me,public

    const [searchSpin,set_searchSpin] = useState(false)
    const [searchResults,set_searchResults] = useState([])
    const [searchWord,set_searchWord] = useState([])

    const handleClickOnFilter = (type)=>{
        globalFilter = type
        set_searchFilter(type)
        handleSearch(searchWord)
    }

    const [mealInput,set_mealInput] = useState("")

    const handleClickOnItem = (item)=>{
        console.log(item)
        console.log(plan)
        console.log(currentTimes)
        set_listBottom(-322);

        let copyOfPlan = {...plan}
        let parts = copyOfPlan[currentSection].times[currentTimes].parts
        let obj = {
            video:item.video,
            thumb:item.image,
            id:uuidv4(),
            text:item.fa_names[0],
            order:parts.length+1,
            items:[
                {type:"exercise",label:"duration",value:5},
                {type:"exercise",label:"repeat",value:10},
                {type:"exercise",label:"set",value:4},
                {type:"exercise",label:"rest",value:60},
            ]
        }
        copyOfPlan[currentSection] = copyOfPlan[currentSection].times[currentTimes].parts = [...parts,obj]
        set_searchWord("")

        // console.log(copyOfPlan)
         // set_plan(copyOfPlan)
    }
    const [searchRef, setSearchFocus] = useFocus()

    const handleAddNewExercise = ()=>{
        set_listBottom(-322);
        let copyOfPlan = {...plan}
        let parts = copyOfPlan[currentSection].times[currentTimes].parts
        let obj = {
            video:"",
            thumb:"",
            id:uuidv4(),
            text:searchWord,
            order:parts.length+1,
            items:[
                {type:"exercise",label:"duration",value:5},
                {type:"exercise",label:"repeat",value:10},
                {type:"exercise",label:"set",value:4},
                {type:"exercise",label:"rest",value:60},
            ]
        }
        copyOfPlan[currentSection] = copyOfPlan[currentSection].times[currentTimes].parts = [...parts,obj]
        set_searchWord("")

        setTimeout(s=>{
            let e = document.getElementById("mobile-sec")
            e.scrollTo({ top:  e.scrollHeight, behavior: 'smooth' })
        },100)


    }

    const handleSearch = async (text)=>{
        // if(currentTimes.length<1)
        //     return

         set_searchWord(text)
        // set_listBottom(178);
        // set_searchSpin(true)
        // try{
        //     let resp = await searchVideo_api(text,globalFilter)
        //     set_searchResults(resp.data.data);
        //     set_searchSpin(false)
        // }catch (e) {
        //     console.log(e)
        // }

    }


    const handleDeletePartItems = (part,items)=>{
        let clone = {...plan}
        clone[currentSection].times[currentTimes].parts.find(p=>p.id===part.id).items = clone[currentSection].times[currentTimes].parts.find(p=>p.id===part.id).items.filter(i=>i.id!==items.id)
        set_plan(clone)
    }

    const uploadVideo =()=>{

    }


    const suppAlternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = supp[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:supp}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_supp([])
    }


    const mealAlternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = meals[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:meals}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_meals([])
    }

    const alternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = links[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:links}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_links([])
    }

    const superSet = ()=>{
        console.log(plan)
        console.log(sus)
        let items = [
        {type: 'exercise', label: 'duration', value: 5},
        {type: 'exercise', label: 'repeat', value: 4},
        {type: 'exercise', label: 'set', value: 1},
        {type: 'exercise', label: 'rest', value: 15}
        ]
        let clone = {...plan}
        let firstLink = sus[0]
        let supers = clone[currentSection].times[currentTimes]['supers']
        let obj = {root:firstLink,others:sus,items:items}
        supers = [...supers,obj]
        clone[currentSection].times[currentTimes]['supers'] = supers
        set_plan({})
        set_plan(clone)
        set_sus([])

    }


    //sup
    const [selectSupPartv2,set_selectSupPartv2] = useState(-1)
    const [selectPartType,set_selectPartType] = useState(null)
    const handleSelectSupPartV2 = (part)=>{
        console.log("sup part : ",part)
        set_selectPartType("sup")
        set_selectSupPartv2(part)
    }

    //meal
    const [selectMealPartv2,set_selectMealPartv2] = useState(-1)
    const handleSelectMealPartV2 = (part)=>{
        console.log("meal part : ",part)
        set_selectPartType("meal")
        set_selectMealPartv2(part)
    }

    //ex
    const [selectPartv2,set_selectPartv2] = useState(-1)
    const handleSelectPartV2 = (part)=>{
        console.log("part : ",part)
        set_selectPartType("ex")
        set_selectPartv2(part)
    }

    const breakFromLink = (p)=>{
        let clone = {...plan}
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let cop = [...alters]
        let breakAll =false
        // cop.forEach(a=>{
        //     if(a.root.id===p.id){
        //         if(a.others.length>2){
        //             let oth = a.others.filter(o=>o.id!==p.id)
        //             a.root = oth[0]
        //             a.others = oth
        //         }
        //         if(a.others.length===2){
        //             breakAll =true
        //         }
        //     }
        // })
        //
        // if(breakAll)
        cop = cop.filter(c=>c.root.id!==p.id)

        clone[currentSection].times[currentTimes]['alternatives'] = cop
        set_plan({})
        set_plan(clone)
        // set_links([])
    }

    const reorder = (p)=>{
        let times = p[currentSection].times[currentTimes]
        let result = []
        let count = 0
        times.parts.forEach(part=>{
            count = count+1
            part.order = count
            times.alternatives.forEach(a=>{
                if(a.root.id===part.id){
                    a.others.forEach(o=>{
                        o.order = count
                    })
                }
            })

        })

        console.log("plan : ",p)
    }

    const setAlterToRoot = (oldRoot,newAlterRoot)=>{
        let clone = {...plan}
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let cop = [...alters]
        cop.forEach(a=>{
            if(a.root.id===oldRoot.id){
                a.root = newAlterRoot
            }
            a.others.forEach(o=>{
                if(o.id===oldRoot.id){
                    o = oldRoot
                }
            })
        })
        clone[currentSection].times[currentTimes]['alternatives'] = cop
        set_plan({})
        //reorder

        set_plan(clone)
        // set_links([])
    }

    const [meals,set_meals] = useState([])
    const [supp,set_supp] = useState([])

    const [links,set_links] = useState([])
    const [sus,set_sus] = useState([])
    useEffect(()=>{
        console.log("links : ",links)
    },[links])
    const handleClickDeletePart = (p)=>{
        let clone = {...plan}
        let re = clone[currentSection].times[currentTimes].parts.filter(i=>i.id!==p.id)
        let supers = plan[currentSection].times[currentTimes]['supers']
        let alternatives = plan[currentSection].times[currentTimes]['alternatives']
        let newParts =[]
        re.forEach((r,index)=>{
            supers.forEach(s=>{
                if(s.root.id===r.id){
                    s.root.order = index+1
                }
                s.others.forEach(o=>{
                    if(o.id===r.id){
                        o.order=index+1
                    }
                })
            })

            alternatives.forEach(a=>{
                if(a.root.id===r.id){
                    a.root.order = index+1
                }
                a.others.forEach(o=>{
                    if(o.id===r.id){
                        o.order=index+1
                    }
                })
            })

            newParts.push({...r,order:index+1})
        })
        clone[currentSection].times[currentTimes].parts = newParts

        set_plan_ground(false)

      set_plan({})
      set_plan(clone)
      set_plan_ground(true)
        //
        let find =  links.find(l=>l.id===p.id)
        if(find)
            set_links(links.filter(l=>l.id!==p.id))


    }

    //start drawer
    const [visibleStartDrawer,set_visibleStartDrawer] = useState(false)
    const onStartDrawerClose = ()=>{
        set_visibleStartDrawer(false)
    }
    const showStartDrawer = (p)=>{
        if(basePlan.deliveredAt && basePlan.deliveredAt.length>0){
            return message.warn({
                content: "بعد از تحویل قادر به تغییر شروع دوره نمیباشید",
                className: 'toast-class'
            });
        }else {
            set_visibleStartDrawer(true)
        }

    }


    const handleChangeWeekMode = ()=>{
        handleChangePlanMode(planMode==="days"?"week":"days")
    }

    //rest drawer
    const [visibleRestDrawer,set_visibleRestDrawer] = useState(false)
    const onRestDrawerClose = ()=>{
        set_visibleRestDrawer(false)
    }
    const showRestDrawer = (p)=>{
        set_visibleRestDrawer(true)
        set_currentPart(p)
        set_currentItems("rest")
    }

    //save drawer
    const [visibleSaveDrawer,set_visibleSaveDrawer] = useState(false)
    const onSaveDrawerClose = ()=>{
        set_visibleSaveDrawer(false)
    }
    const showSaveDrawer = (p)=>{
        console.log("plan : ",plan)
        set_visibleSaveDrawer(true)
    }

    //save
    const [saveToLibrary,set_saveToLibrary] = useState(false)
    const [libraryNameInput,set_libraryNameInput] = useState("")

    const saveAndSend = async ()=>{
        if(saveToLibrary && libraryNameInput.length<1){
            return message.error({
                content: "لطفا نام برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }
        set_spinSave(true)

        let  obj ={
            data:plan,
            library:saveToLibrary,
            libraryName:libraryNameInput,
            traineeId:traineeId,
            send:true
        }

        try{
            await saveAndSendPlanApi(obj,basePlan._id)
            set_saveToLibrary(false)
            onSaveDrawerClose()
            set_spinSave(false)
            getPlan()
            return message.success({
                content: "برنامه برای شاگرد ارسال شد",
                className: 'toast-class'
            });
        }catch (e) {
            set_spinSave(false)
            console.log(e)
        }


    }

    const [spinSave,set_spinSave] = useState(false)
    const save = async ()=>{

        if(saveToLibrary && libraryNameInput.length<1){
            return message.error({
                content: "لطفا نام برنامه را وارد نمایید",
                className: 'toast-class'
            });
        }

        set_spinSave(true)

        let  obj ={
            startAt:startLabel,
            data:plan,
            library:saveToLibrary,
            libraryName:libraryNameInput,
            traineeId:traineeId,
            send:false
        }

        try{
            await savePlanApi(obj,basePlan._id)
            set_saveToLibrary(false)
            onSaveDrawerClose()
            set_spinSave(false)
            return message.success({
                content: "برنامه با موفقیت ذخیره شد",
                className: 'toast-class'
            });
        }catch (e) {
            set_spinSave(false)
            return message.success({
                content: "مشکل در ذخیره برنامه",
                className: 'toast-class'
            });
            console.log(e)
        }

    }

    //repeat drawer
    const [visibleRepeatDrawer,set_visibleRepeatDrawer] = useState(false)
    const onRepeatDrawerClose = ()=>{
        set_visibleRepeatDrawer(false)
    }
    const showRepeatDrawer = (p)=>{
        set_visibleRepeatDrawer(true)
        set_currentPart(p)
        set_currentItems("repeat")
    }

    //hour drawer
    const [visibleHourDrawer,set_visibleHourDrawer] = useState(false)
    const onHourDrawerClose = ()=>{
        set_visibleHourDrawer(false)
    }
    const showHourDrawer = (p)=>{
        set_visibleHourDrawer(true)
        set_currentPart(p)
        set_currentItems("hour")
    }


    //set drawer
    const [visibleSetDrawer,set_visibleSetDrawer] = useState(false)
    const onSetDrawerClose = ()=>{
        set_visibleSetDrawer(false)
    }
    const showSetDrawer = (p)=>{
        set_visibleSetDrawer(true)
        set_currentPart(p)
        set_currentItems("set")
    }

    const addVideo = (part,video)=>{
        let clone = {...plan}
        let parts = clone[currentSection].times[currentTimes].parts;
        parts.forEach(p=>{
            if(p.id===part.id){
                p.video = video
            }
        })
        set_plan(clone)
    }
    const removeVideoFromPart = (part)=>{
        let clone = {...plan}
        let parts = clone[currentSection].times[currentTimes].parts;
        parts.forEach(p=>{
            if(p.id===part.id){
                p.video = null
            }
        })
        set_plan(clone)
    }

    //weight drawer
    const [visibleWeightDrawer,set_visibleWeightDrawer] = useState(false)
    const onWeightDrawerClose = ()=>{
        set_visibleWeightDrawer(false)
    }
    const showWeightDrawer = (p)=>{
        set_visibleWeightDrawer(true)
        set_currentPart(p)
        set_currentItems("weight")
    }

    //duration drawer
    const [visibleDurationDrawer,set_visibleDurationDrawer] = useState(false)
    const onDurationDrawerClose = ()=>{
        set_visibleDurationDrawer(false)
    }
    const showDurationDrawer = (p)=>{
        set_visibleDurationDrawer(true)
        set_currentPart(p)
        set_currentItems("duration")
    }


    //option drawer
    const [visibleOptionDrawer,set_visibleOptionDrawer] = useState(false)
    const onOptionDrawerClose = ()=>{
        set_visibleOptionDrawer(false)
    }
    const showOptionDrawer = (p)=>{
        set_visibleOptionDrawer(true)
        set_currentPart(p)
        set_currentItems("set")
    }

    //lib drawer
    //copy to drawer
    const [libDrawer,set_libDrawer] = useState(false)
    const onLibDrawerClose = ()=>{
        set_libDrawer(false)
    }
    const showLibDrawer = (p)=>{
        set_libDrawer(true)
    }

    //copy from drawer
    const [SelectedCopyFromDrawer,set_SelectedCopyFromDrawer] = useState({})
    const [visibleCopyFromDrawer,set_visibleCopyFromDrawer] = useState(false)
    const onCopyFromDrawerClose = ()=>{
        set_visibleCopyFromDrawer(false)
    }
    const showCopyFromDrawer = (p)=>{
        set_visibleCopyFromDrawer(true)
    }
    const handleSelectCopyFrom = (t)=>{
        let find = SelectedCopyFromDrawer.index===t.index
        if(find)
            set_SelectedCopyFromDrawer({})
        else
            set_SelectedCopyFromDrawer(t)
    }


    const handleCopyFrom = ()=>{
        let copy = {...plan}
        copy[currentSection].times.forEach(t=>{
            if(SelectedCopyFromDrawer.index===t.index){
                copy[currentSection].times[currentTimes].parts = t.parts
                copy[currentSection].times[currentTimes].alternatives = t.alternatives
                copy[currentSection].times[currentTimes].supers = t.supers
            }
        })
        set_plan(copy)
        set_SelectedCopyFromDrawer({})
        onCopyFromDrawerClose()
        onOptionDrawerClose()
    }

    //copy to drawer
    const [SelectedCopyToDrawer,set_SelectedCopyToDrawer] = useState([])
    const [visibleCopyToDrawer,set_visibleCopyToDrawer] = useState(false)
    const onCopyToDrawerClose = ()=>{
        set_visibleCopyToDrawer(false)
    }
    const showCopyToDrawer = (p)=>{
        set_visibleCopyToDrawer(true)
    }

    const handleCopyTo = ()=>{
        let copy = {...plan}
        copy[currentSection].times.forEach(t=>{
            SelectedCopyToDrawer.forEach(s=>{
                if(t.index===s.index){
                    t.parts = plan[currentSection].times[currentTimes].parts
                    t.alternatives = plan[currentSection].times[currentTimes].alternatives
                    t.supers = plan[currentSection].times[currentTimes].supers
                }
            })
        })
        set_plan(copy)
        set_SelectedCopyToDrawer([])
        onCopyToDrawerClose()
        onOptionDrawerClose()
    }



    const handleSelectCopyTo = (t)=>{
        let find = SelectedCopyToDrawer.find(f=>f.index===t.index)
        if(find)
            set_SelectedCopyToDrawer(SelectedCopyToDrawer.filter(f=>f.index!==t.index))
        else
            set_SelectedCopyToDrawer([...SelectedCopyToDrawer,t])
    }

    const [currentItems,set_currentItems] = useState(null)
    const [currentPart,set_currentPart] = useState(null)

    const  handleChangeHour  = (val)=>{
        let clone = {...plan}
        let parts = clone[currentSection].times[currentTimes].parts;
        console.log("handleChangeHour : ",parts)
        parts.forEach(p=>{
            if(selectPartType==="ex")
            if(p.id===selectPartv2.id){p.text = val}

            if(selectPartType==="sup")
                if(p.id===selectSupPartv2.id){p.text = val}

            if(selectPartType==="meal")
                if(p.id===selectMealPartv2.id){p.text = val}
        })

        set_plan(clone)
        onHourDrawerClose()
    }

    const  handleSelectItems = (type,num)=>{
        console.log(currentPart)
        console.log(currentItems)
        let clone = {...plan}
        let parts = clone[currentSection].times[currentTimes].parts;
        parts.forEach(p=>{
            if(p.id===currentPart.id){
                p.items.forEach(i=>{
                    if(i.label===type)
                        i.value=num
                })
            }
        })
        set_plan(clone)


        if(type==="weight")
            onWeightDrawerClose()

        if(type==="rest")
            onRestDrawerClose()

        if(type==="duration")
            onRestDrawerClose()

        if(type==="repeat")
            onRepeatDrawerClose()

        if(type==="set")
            onSetDrawerClose()
    }

    const [selectedPart,set_selectedPart] = useState("")

    //diet

    const [inputRef, setInputFocus] = useFocus()
    const handleAddMealChild = ()=>{
        let copy = {...plan}
        if(copy[currentSection].times[currentTimes].parts.length>0){
            if(selectedPart) {
                let items = copy[currentSection].times[currentTimes].parts.find(p=>p.id===selectedPart).items
                copy[currentSection].times[currentTimes].parts.find(p=>p.id===selectedPart).items = [...items, {
                    id: items.length,
                    type: currentSection,
                    label: "",
                    value: "",
                    text: mealInput
                }]
                set_plan(copy)
                set_mealInput("")
            }else {
                return message.error({
                    content: "ابتدا روی وعده مورد نظر کلیک نمایید",
                    className: 'toast-class'
                });
            }
        }else {
            return message.error({
                content: "ابتدا روی وعده جدید کلیک نمایید",
                className: 'toast-class'
            });
        }

    }

    const handleNewMeal = ()=>{

        // let id= uuidv4();
        let copyOfPlan = {...plan}
        let parts = copyOfPlan[currentSection].times[currentTimes].parts
        let id = uuidv4();
        let obj = {
            video:"",
            thumb:"",
            id:id,
            text:"8:00",
            order:parts.length+1,
            items:[]
        }
        copyOfPlan[currentSection].times[currentTimes].parts = [...parts,obj]
        console.log(copyOfPlan)
        set_plan(copyOfPlan)
        setTimeout(s=>{
            set_selectedPart(id)
            handleSelectMealPartV2(obj)
            setInputFocus()
            let e = document.getElementById("mobile-sec")
            e.scrollTo({ top:  e.scrollHeight, behavior: 'smooth' })
        },100)

    }

    const [lib,set_lib] = useState([])
    const [libSpin,set_libSpin] = useState(false)



    const handleDeleteLibItem = async (l)=>{
        set_libSpin(true)
        try{
            await deleteLibItem_api(l._id)
            set_libSpin(false)
            getLib()
        }catch (e) {
            set_libSpin(false)
            console.log(e)
        }
    }


    const getLib = async ()=>{
        try{
            const res = await getLib_api()
            set_lib(res.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const handleSelectLib = async (l)=>{
        console.log(l)
        set_plan(l.data)
        onLibDrawerClose()
    }

    const [my, set_my] = useState({})


    const getMyProfile = async ()=>{
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }


    const onChange = (checked) => {
        set_saveToLibrary(checked)
    };

    return(
        <div>
            <div className="fix-content">


                <DumbbellHeader title={"تنظیم برنامه"} back={true}/>

                {/*<div className="speech-sec">*/}
                {/*    <h1>تبدیل صدا به متن</h1>*/}
                {/*    <p>Microphone: {listening ? 'on' : 'off'}</p>*/}
                {/*    <button onClick={e=>{*/}
                {/*        SpeechRecognition.startListening({ language: 'fa-IR',matchInterim:true,isFuzzyMatch:true })*/}
                {/*    }}>Start</button>*/}
                {/*    <button onClick={SpeechRecognition.stopListening}>Stop</button>*/}
                {/*    <button onClick={resetTranscript}>Reset</button>*/}
                {/*    /!*<h1>{message}</h1>*!/*/}
                {/*    <p>{transcript}</p>*/}
                {/*</div>*/}

                <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"top"}  height={120} closable={false}  onClose={onStartDrawerClose} visible={visibleStartDrawer}>
                    <div className="start-drawer">
                        <div className="start-drawer-form">
                            <div className="start-drawer-btn" onClick={e=>{
                                reGenerateTimes()
                            }}>
                                <span>اعمال</span>
                            </div>
                            <div className="start-drawer-items">
                                <div className="start-drawer-item">

                                    <select
                                        value={reStartYear}
                                        onChange={e=>set_reStartYear(e.target.value)}
                                        name="" id="">
                                        <option value="1402">1402</option>
                                        <option value="1403">1403</option>
                                        <option value="1404">1404</option>
                                        <option value="1405">1405</option>
                                        <option value="1406">1406</option>
                                        <option value="1407">1407</option>
                                    </select>
                                </div>
                                <div className="start-drawer-item">

                                    <select
                                        value={reStartMonth}
                                        onChange={e=>set_reStartMonth(e.target.value)}
                                        name="" id="">
                                        <option value="1">فرودین</option>
                                        <option value="2">اردیبهشت</option>
                                        <option value="3">خرداد</option>
                                        <option value="4">تیر</option>
                                        <option value="5">مرداد</option>
                                        <option value="6">شهریور</option>
                                        <option value="7">مهر</option>
                                        <option value="8">آبان</option>
                                        <option value="9">آذر</option>
                                        <option value="10">دی</option>
                                        <option value="11">بهمن</option>
                                        <option value="12">اسفند</option>
                                    </select>
                                </div>
                                <div className="start-drawer-item">

                                    <select
                                        value={reStartDay}
                                        onChange={e=>set_reStartDay(e.target.value)}
                                        name="" id="">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>



                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="start-drawer-warn">
                            <p>توجه داشته باشید بعد از اعمال تاریخ جدید تمام حرکتهای انتخابی در تمام روزهای جاری پاک میشوند</p>
                        </div>
                    </div>
                </Drawer>
                <div className="plan-add-header-v2">
                    {/*<div className="dumbbell-header-plan-card-actions">*/}

                    {/*    <div className="dumbbell-trainee-card-message" >*/}
                    {/*        <div className="plan-duration">*/}
                    {/*            <span>  {basePlan.duration}  </span>*/}
                    {/*            <span> روزه  </span>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*    <div className="dumbbell-trainee-card-message" onClick={e=>{*/}
                    {/*        showChatDrawer()*/}
                    {/*    }}>*/}
                    {/*        <img src={messageIcon} alt=""/>*/}
                    {/*        {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}*/}
                    {/*    </div>*/}

                    {/*    <div className="dumbbell-trainee-card-box" onClick={e=>{*/}
                    {/*        showBoxDrawer()*/}
                    {/*    }}>*/}
                    {/*        <CodeSandboxOutlined style={{*/}
                    {/*            color: "#cdc4c4",*/}
                    {/*            fontSize: 19,*/}
                    {/*            marginTop: 6,*/}
                    {/*        }} />*/}
                    {/*    </div>*/}

                    {/*    <div className="dumbbell-trainee-card-box" onClick={e=>{*/}
                    {/*        showProfileDrawer()*/}
                    {/*    }}>*/}
                    {/*        <UserOutlined  style={{*/}
                    {/*            color: "#cdc4c4",*/}
                    {/*            fontSize: 19,*/}
                    {/*            marginTop: 6,*/}
                    {/*        }} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {trainee?
                        <div className="plan-add-header-info">
                            <span className="name">{trainee?.first_name+" "+trainee?.last_name}</span>
                            <span className="mobile">{trainee?.mobile}</span>
                        </div>:null
                    }




                    <div className="plan-add-header-image">
                        {trainee?.image?<img src={Setup.filePoint+trainee.image} alt=""/>:<img src={sampleFace} alt=""/>}
                    </div>
                </div>
                {/*<div className="plan-add-header-type">*/}
                {/*    <div className="week-mode" >*/}
                {/*        <select*/}
                {/*            value={planMode}*/}
                {/*            onChange={e=>{*/}
                {/*                handleChangePlanMode(e.target.value)*/}
                {/*            }}*/}
                {/*            name="" id="">*/}
                {/*            <option value="week">هفتگی</option>*/}
                {/*            <option value="days">روزانه</option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*    <div className="lib-sec" onClick={e=>{*/}
                {/*        showLibDrawer()*/}
                {/*    }}>*/}
                {/*        <span className="clickable" >آماده ها</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={e=>{*/}
                {/*        showStartDrawer()*/}
                {/*    }}>{startLabel} تاریخ شروع </span>*/}
                {/*    /!*<select*!/*/}
                {/*    /!*    onChange={handleChangeSectionType}*!/*/}
                {/*    /!*    name="" id="">*!/*/}
                {/*    /!*    <option value="week">هفتگی</option>*!/*/}
                {/*    /!*    <option value="day">روزانه</option>*!/*/}
                {/*    /!*</select>*!/*/}
                {/*</div>*/}

            </div>

            <div className="actions-v2">
                <div className="dumbbell-trainee-card-message" onClick={e=>{
                    if(currentSection==="exercise")
                    window.open(`/printable/${basePlan._id}`, '_blank');

                    if(currentSection==="diet")
                        window.open(`/printable-diet/${basePlan._id}`, '_blank');

                    if(currentSection==="supplement")
                        window.open(`/printable-supplement/${basePlan._id}`, '_blank');

                }}>
                    <img src={printIcon} alt=""/>
                </div>

                <div className="dumbbell-header-plan-card-actions actions-margin">

                    <div className="dumbbell-trainee-card-message" onClick={e=>{
                        showLibDrawer()
                    }}>
                        <img src={libraryIcon} alt=""/>
                    </div>
                    <div className="dumbbell-trainee-card-message" onClick={e=>{
                        showChatDrawer()
                    }}>
                        <img src={messageIcon} alt=""/>
                        {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}
                    </div>

                    <div className="dumbbell-trainee-card-box" onClick={e=>{
                        showBoxDrawer()
                    }}>
                        <img src={picIcon} alt=""/>
                    </div>

                    <div className="dumbbell-trainee-card-box" onClick={e=>{
                        showProfileDrawer()
                    }}>
                        <img src={profileIcon} alt=""/>
                    </div>
                </div>
            </div>
            <div className="plan-add-tabs">

                <div className="plan-add-tabs-item-v2"
                     onClick={e=>{
                         set_searchWord("")
                         set_mealInput("")
                         set_currentSection("diet")
                     }}
                     style={currentSection==="diet"?{backgroundColor:"#8D89FD"}:{}}>
                    {/*<img src={foodIcon} alt=""/>*/}
                    <span style={currentSection==="diet"?{color:"#ffffff"}:{}}>تغذیه</span>
                </div>

                <div className="plan-add-tabs-item-v2 active"
                     onClick={e=>{
                         set_searchWord("")
                         set_mealInput("")
                         set_currentSection("exercise")
                     }}
                     style={currentSection==="exercise"?{backgroundColor:"#8D89FD",color:"white !important"}:{}}>
                    {/*<img src={dumbbellIcon} alt=""/>*/}
                    <span style={currentSection==="exercise"?{color:"#ffffff"}:{}}>تمرین</span>
                </div>

                <div className="plan-add-tabs-item-v2"
                     onClick={e=>{
                         console.log(plan)
                         set_searchWord("")
                         set_mealInput("")
                         set_currentSection("supplement")
                     }}
                     style={currentSection==="supplement"?{backgroundColor:"#8D89FD",color:"white"}:{}}>
                    {/*<img src={capsuleIcon} alt=""/>*/}
                    <span style={currentSection==="supplement"?{color:"#ffffff"}:{}}>مکمل</span>
                </div>
            </div>




            <div className="plan-sec">

                {plan[currentSection]?.times.length>0?<div className="plan-bar">
                    <div className="tools-list">
                        <div className="tools-item clickable" onClick={e=>{
                            showStartDrawer()
                        }}>
                            <img src={addCalendarIcon} alt=""/>
                        </div>

                        <div className="tools-item clickable" onClick={e=>{
                            handleChangeWeekMode()
                        }}>
                            {planMode==="week"?
                                <img src={weekOnIcon} alt=""/>:
                                <img src={addWeekIcon} alt=""/>
                            }

                        </div>
                    </div>
                    {swiperReset?<div className="plan-bar-list">
                        <Swiper ref={MyRef}  {...params}>
                            {calcTimes(plan[currentSection].times,planMode).map(time=>{
                                return(
                                    <div  className={'plan-bar-item'}
                                          style={currentTimes===time.index?{color:"#8D89FD"}:{}}
                                          onClick={e=>{
                                        handleClickOnTimes(time)
                                    }}>

                                            {planMode==="days"?<span style={{fontFamily:"YekanRegular"}}>{time.jDay}</span>:null}
                                            <span style={{fontFamily:"YekanBold"}}>{time.weekDay}</span>
                                    </div>
                                )
                            })}

                        </Swiper>
                    </div>:null}


                    <div className="plan-month">
                        {selectTimes.jMonth?<span>{selectTimes.jYear} {convertMonthIntNumberToName(selectTimes.jMonth)}</span>:null}
                    </div>
                </div>:null}

                {currentSection==="exercise" && sus.length>1?
                <div className="links-menu">
                    <div className="links-menu-item clickable" onClick={e=>{
                        superSet()
                    }}>
                        <span>سوپر</span>
                        <img src={addSuperIcon} alt=""/>
                    </div>
                </div>:null}

                {currentSection==="diet" && meals.length>1?
                    <div className="links-menu">
                        <div className="links-menu-item clickable" onClick={e=>{
                            mealAlternativeSet()
                        }}>
                            <span>وعده جایگزین</span>
                        </div>
                    </div>:null
                }

                {currentSection==="supplement" && supp.length>1?
                    <div className="links-menu">
                        <div className="links-menu-item clickable" onClick={e=>{
                            suppAlternativeSet()
                        }}>
                            <span>مکمل جایگزین</span>
                            <img src={addMergeIcon} alt=""/>
                        </div>
                    </div>:null
                }


                {currentSection==="exercise" && links.length>1?
                    <div className="links-menu">
                        <div className="links-menu-item clickable" onClick={e=>{
                            alternativeSet()
                        }}>
                            <span>جایگزین</span>
                            <img src={addMergeIcon} alt=""/>
                        </div>
                    </div>:null
                }

                {plan_ground?<div className="plan-create-list">

                    {plan[currentSection]?.times[currentTimes]?.parts.map((part,i)=>{
                        return(
                            <Fragment>
                                {currentSection==="exercise"?
                                    <>
                                        {checkSuperRoot(part,plan[currentSection]?.times[currentTimes].supers)?
                                        <SuperHeaderCard changeSuperItems={changeSuperItems} breakSuperSet={breakSuperSet} part={part} supers={plan[currentSection]?.times[currentTimes].supers}/>
                                            :null}
                                        <ExPartCard
                                            uploadVideo={uploadVideo}
                                            setAlterToRoot={setAlterToRoot}
                                            breakFromLink={breakFromLink}
                                            alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                            supers={plan[currentSection]?.times[currentTimes].supers}
                                            part={part}
                                            selectPartv2 ={selectPartv2}
                                            handleSelectPartV2={handleSelectPartV2}
                                            links={links}
                                            set_links={set_links}
                                            sus={sus}
                                            set_sus={set_sus}
                                            handleClickDeletePart={handleClickDeletePart}
                                            showSetDrawer={showSetDrawer}
                                            showRepeatDrawer={showRepeatDrawer}
                                            showRestDrawer={showRestDrawer}
                                            plan={plan}
                                            set_plan={set_plan}
                                            addVideo={addVideo}
                                            removeVideoFromPart={removeVideoFromPart}
                                            currentSection={currentSection}
                                            currentTimes={currentTimes}
                                            i={i}
                                        />
                                    </>
                                    :null}
                                {currentSection==="diet"?<MealPartCard
                                        set_links={set_links}
                                        currentSection={currentSection}
                                        setAlterToRoot={setAlterToRoot}
                                        alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                        supers={plan[currentSection]?.times[currentTimes].supers}
                                        currentTimes={currentTimes}
                                        handleClickDeletePart={handleClickDeletePart}
                                        handleDeletePartItems={handleDeletePartItems}
                                        meals={meals}
                                        selectPartv2 ={selectMealPartv2}
                                        handleSelectPartV2={handleSelectMealPartV2}
                                        breakFromLink={breakFromLink}
                                        set_meals={set_meals}
                                        part={part}
                                        plan={plan}
                                        set_plan={set_plan}
                                        selectedPart={selectedPart}
                                        set_selectedPart={set_selectedPart}
                                        setInputFocus={setInputFocus}
                                        showHourDrawer={showHourDrawer}
                                    />:null
                                }

                                {currentSection==="supplement"?<SuppPartCard
                                    currentSection={currentSection}
                                    setAlterToRoot={setAlterToRoot}
                                    alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                    supers={plan[currentSection]?.times[currentTimes].supers}
                                    set_plan={set_plan}
                                    set_links={set_links}
                                    currentTimes={currentTimes}
                                    handleClickDeletePart={handleClickDeletePart}
                                    handleDeletePartItems={handleDeletePartItems}
                                    supp={supp}
                                    selectPartv2 ={selectSupPartv2}
                                    handleSelectPartV2={handleSelectSupPartV2}
                                    breakFromLink={breakFromLink}
                                    set_supp={set_supp}
                                    part={part}
                                    plan={plan}
                                    selectedPart={selectedPart}
                                    set_selectedPart={set_selectedPart}
                                    setInputFocus={setInputFocus}
                                    showHourDrawer={showHourDrawer}
                                />:null
                                }

                            </Fragment>
                        )
                    })}

















                </div>:null}


            </div>

            {currentSection==="exercise"?
                <div className="plan-add-fix-footer-wr">

                    {/*<div className="plan-add-fix-footer-list" style={{bottom: listBottom}}>*/}
                    {/*    <div className="plan-add-fix-footer-list-header">*/}

                    {/*        <div className="plan-add-fix-footer-list-header-close" onClick={()=>{*/}
                    {/*            set_listBottom(-322)*/}
                    {/*        }}>*/}
                    {/*            <CloseCircleOutlined />*/}
                    {/*        </div>*/}

                    {/*        <div className="plan-add-fix-footer-list-header-btns">*/}
                    {/*            <div*/}
                    {/*                className={searchFilter==="me"?"filter-active plan-add-fix-footer-list-header-btn":"plan-add-fix-footer-list-header-btn"}*/}
                    {/*                 onClick={e=>{*/}
                    {/*                     handleClickOnFilter("me")*/}
                    {/*            }}>*/}
                    {/*                <span>حرکات خودم</span>*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className={searchFilter==="public"?"filter-active plan-add-fix-footer-list-header-btn":"plan-add-fix-footer-list-header-btn"}*/}
                    {/*                onClick={e=>{*/}
                    {/*                    handleClickOnFilter("public")*/}
                    {/*            }}>*/}
                    {/*                <span>حرکات عمومی</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={searchFilter==="all"?"filter-active plan-add-fix-footer-list-header-btn":"plan-add-fix-footer-list-header-btn"}*/}
                    {/*                 onClick={e=>{*/}
                    {/*                     handleClickOnFilter("all")*/}

                    {/*            }}>*/}
                    {/*                <span>همه</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*    <div className="plan-add-fix-footer-list-body">*/}
                    {/*        <br/>*/}
                    {/*        <Spin spinning={searchSpin}>*/}
                    {/*            <div className="plan-add-fix-footer-list-main">*/}

                    {/*                {searchResults.map(s => {*/}
                    {/*                    return (*/}
                    {/*                        <div className="plan-add-fix-footer-list-body-item"*/}
                    {/*                             onClick={e=>handleClickOnItem(s)}*/}
                    {/*                             style={{backgroundImage: `url(${s.image})`}}*/}
                    {/*                        >*/}
                    {/*                            <span>{s.fa_names[0]}</span>*/}
                    {/*                        </div>*/}
                    {/*                    )*/}

                    {/*                })}*/}


                    {/*            </div>*/}
                    {/*        </Spin>*/}

                    {/*    </div>*/}




                    {/*</div>*/}
                    <div className="plan-add-fix-footer-search">
                        <div className="plan-add-fix-footer-search-img" onClick={()=>{
                            handleAddNewExercise()
                        }}>
                            <img src={plusBlueIcon} alt=""/>
                        </div>
                        <input
                            onKeyPress={e=>{if(e.key==="Enter"){handleAddNewExercise()}}}
                            value={searchWord}
                            ref={searchRef}
                            onChange={e=>{
                                handleSearch(e.target.value)
                            }}
                            type="text" name="" id=""/>
                    </div>
                    <div className="plan-add-fix-footer-sec">
                        <div className={listening?"plan-add-fix-footer-mic-on":"plan-add-fix-footer-mic"} onClick={e=>{
                            // setSearchFocus()
                            SpeechRecognition.startListening({ language: 'fa-IR',matchInterim:true,isFuzzyMatch:true})
                        }}>
                            <img src={micIcon} alt=""/>
                        </div>
                        <div className="plan-add-fix-footer-btns">

                            {/*<div className="plan-add-fix-footer-btn">*/}
                            {/*    <span className="save-btn">ذخیره</span>*/}
                            {/*</div>*/}

                            {planIsEnd?
                                <div className="plan-end-time">
                                    <span>طول مدت برنامه به پایان رسیده است</span>
                                </div>:
                                <div className="plan-add-fix-footer-btn">
                                    <span className="save-send-btn" onClick={showSaveDrawer}>ذخیره</span>
                                </div>
                            }


                        </div>

                    </div>
                </div>:
                <div className="plan-add-fix-footer-wr">

                    {/*<div className="plan-add-fix-footer-list" style={{bottom: listBottom}}>*/}
                    {/*    <div className="plan-add-fix-footer-list-header">*/}

                    {/*        <div className="plan-add-fix-footer-list-header-close" onClick={()=>{*/}
                    {/*            set_listBottom(-322)*/}
                    {/*        }}>*/}
                    {/*            <CloseCircleOutlined />*/}
                    {/*        </div>*/}

                    {/*        <div className="plan-add-fix-footer-list-header-btns">*/}
                    {/*            <div className="plan-add-fix-footer-list-header-btn">*/}
                    {/*                <span>حرکات خودم</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="plan-add-fix-footer-list-header-btn">*/}
                    {/*                <span>حرکات عمومی</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*    <div className="plan-add-fix-footer-list-body">*/}
                    {/*        <br/>*/}
                    {/*        <Spin spinning={searchSpin}>*/}
                    {/*            <div className="plan-add-fix-footer-list-main">*/}

                    {/*                {searchResults.map(s => {*/}
                    {/*                    return (*/}
                    {/*                        <div className="plan-add-fix-footer-list-body-item"*/}
                    {/*                             onClick={e=>handleClickOnItem(s)}*/}
                    {/*                             style={{backgroundImage: `url(${s.image})`}}*/}
                    {/*                        >*/}
                    {/*                            <span>{s.fa_names[0]}</span>*/}
                    {/*                        </div>*/}
                    {/*                    )*/}

                    {/*                })}*/}


                    {/*            </div>*/}
                    {/*        </Spin>*/}

                    {/*    </div>*/}




                    {/*</div>*/}
                    <div className="plan-add-fix-footer-search">
                        <div className="plan-add-fix-footer-search-img" onClick={()=>{
                            handleAddMealChild()
                        }}>
                            <img src={plusBlueIcon} alt=""/>
                        </div>
                        <input
                            ref={inputRef}
                            value={mealInput}
                            onKeyPress={e=>{if(e.key==="Enter"){handleAddMealChild()}}}
                            onChange={e=>set_mealInput(e.target.value)}
                            type="text" name="" id=""/>
                    </div>
                    <div className="plan-add-fix-footer-sec">
                        {/*<div className="plan-add-fix-footer-mic">*/}
                        {/*    <img src={micIcon} alt=""/>*/}
                        {/*</div>*/}
                        <div className={listening?"plan-add-fix-footer-mic-on":"plan-add-fix-footer-mic"} onClick={e=>{
                            // setSearchFocus()
                            SpeechRecognition.startListening({ language: 'fa-IR',matchInterim:true,isFuzzyMatch:true})
                        }}>
                            <img src={micIcon} alt=""/>
                        </div>
                        {planIsEnd?
                            <div className="plan-end-time">
                                <span>طول مدت برنامه به پایان رسیده است</span>
                            </div>:
                            <div className="plan-add-fix-footer-btns">

                                <div className="plan-add-fix-footer-btn" onClick={e=>{
                                    handleNewMeal()
                                }}>
                                    <span className="save-btn">وعده جدید</span>
                                </div>

                                <div className="plan-add-fix-footer-btn">
                                    <span className="save-send-btn" onClick={showSaveDrawer}>ذخیره</span>
                                </div>

                            </div>
                        }


                    </div>
                </div>
            }


            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"bottom"}  height={320} closable={false}  onClose={onSaveDrawerClose} visible={visibleSaveDrawer}>
                <Spin spinning={spinSave}>
                    <div className="save-drawer">
                        <div className="save-drawer-header">
                            <span>ذخیره برنامه ها</span>
                        </div>
                        <div className="save-drawer-body">
                            <div className="save-drawer-form">
                                <div className="save-drawer-form-item">

                                    <div className="save-drawer-form-library">
                                        <div className="save-drawer-form-item-label">
                                            {/*<input onClick={e=>set_saveToLibrary(!saveToLibrary)} type="checkbox" name="" id=""/>*/}
                                            <Switch checked={saveToLibrary}  onChange={onChange} />
                                            <span>ذخیره در کتابخانه برنامه ها</span>
                                        </div>
                                        {saveToLibrary?<div className="save-drawer-form-item-input">
                                            <input
                                                value={libraryNameInput}
                                                onChange={e=>set_libraryNameInput(e.target.value)}
                                                type="text" name="" id="" placeholder="نام برنامه , مثلا : کاهش وزن"/>
                                        </div>:null}

                                    </div>


                                    <div className="save-drawer-form-delivery">
                                        <span className="save-drawer-form-delivery-item-title">لینک مستقیم برنامه</span>

                                        <div className="save-drawer-form-delivery-item">
                                            <CopyToClipboard text={"https://step-planner.com/dc/"+basePlan?._id}
                                                             onCopy={e=>{
                                                                 message.success({
                                                                     content: `شماره موبایل کپی شد`,
                                                                     className: 'toast-class'
                                                                 });
                                                             }}>
                                                <span className="save-drawer-form-delivery-item-btn">کپی</span>
                                            </CopyToClipboard>

                                            <input type="text" name=""
                                                   value={"https://step-planner.com/dc/"+basePlan?._id}
                                                   id=""/>
                                        </div>



                                        {/*<div className="save-drawer-form-delivery-item">*/}
                                        {/*    <span>تحویل به ایمیل</span>*/}
                                        {/*    <span>reza.ma@gmail.com</span>*/}
                                        {/*</div>*/}

                                    </div>

                                    <div className="save-drawer-form-delivery">
                                        <div className="save-drawer-form-delivery-item">
                                            <span className="save-drawer-form-delivery-item-mobile">{trainee?.mobile}</span>
                                            {basePlan.send_try>0?
                                                <span className="save-drawer-form-delivery-item-label">تحویل به موبایل (تا {basePlan.send_try} مرتبه)</span>:null
                                            }

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="save-drawer-footer">
                            <span className="save-btn" onClick={save}>ذخیره</span>
                            {basePlan.send_try>0?
                            <span className="saveAndSend-btn" onClick={saveAndSend}>ذخیره و ارسال</span>:
                                null}
                        </div>
                    </div>
                </Spin>
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onRestDrawerClose} visible={visibleRestDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span>مدت زمان استراحت</span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",5)}>
                            <span>5 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",10)}>
                            <span>10 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",15)}>
                            <span>15 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",20)}>
                            <span>20 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",25)}>
                            <span>25 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",30)}>
                            <span>30 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",35)}>
                            <span>35 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",40)}>
                            <span>40 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",45)}>
                            <span>45 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",50)}>
                            <span>50 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",55)}>
                            <span>55 ثانیه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",60)}>
                            <span>1 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",120)}>
                            <span>2 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",180)}>
                            <span>3 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",240)}>
                            <span>4 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",300)}>
                            <span>5 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",360)}>
                            <span>6 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",420)}>
                            <span>7 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",480)}>
                            <span>8 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",540)}>
                            <span>9 دقیقه</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("rest",600)}>
                            <span>10 دقیقه</span>
                        </div>
                    </div>
                </div>
            </Drawer>


            {/*Repeat Drawer*/}

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onRepeatDrawerClose} visible={visibleRepeatDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span>تعداد تکرار</span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",1)}>
                            <span>1 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",2)}>
                            <span>2 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",3)}>
                            <span>3 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",4)}>
                            <span>4 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",5)}>
                            <span>5 تکرار</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",6)}>
                            <span>6 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",7)}>
                            <span>7 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",8)}>
                            <span>8 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",9)}>
                            <span>9 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",10)}>
                            <span>10 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",11)}>
                            <span>11 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",12)}>
                            <span>12 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",13)}>
                            <span>13 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",14)}>
                            <span>14 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",15)}>
                            <span>15 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",16)}>
                            <span>16 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",17)}>
                            <span>17 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",18)}>
                            <span>18 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",19)}>
                            <span>19 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>20 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>30 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>40 تکرار</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("repeat",20)}>
                            <span>50 تکرار</span>
                        </div>

                    </div>
                </div>
            </Drawer>


            {/*hour Drawer*/}
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onHourDrawerClose} visible={visibleHourDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span>تعیین زمان </span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("1:00")}>
                            <span>1:00 </span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleChangeHour("2:00")}>
                            <span>2:00 </span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleChangeHour("3:00")}>
                            <span>3:00 </span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleChangeHour("4:00")}>
                            <span>4:00 </span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleChangeHour("5:00")}>
                            <span>5:00 </span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleChangeHour("6:00")}>
                            <span>6:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("7:00")}>
                            <span>7:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("8:00")}>
                            <span>8:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("9:00")}>
                            <span>9:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("10:00")}>
                            <span>10:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("11:00")}>
                            <span>11:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("12:00")}>
                            <span>12:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("13:00")}>
                            <span>13:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("14:00")}>
                            <span>14:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("15:00")}>
                            <span>15:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("16:00")}>
                            <span>16:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("17:00")}>
                            <span>17:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("18:00")}>
                            <span>18:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("19:00")}>
                            <span>19:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("20:00")}>
                            <span>20:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("21:00")}>
                            <span>21:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("22:00")}>
                            <span>22:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("23:00")}>
                            <span>23:00 </span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleChangeHour("00:00")}>
                            <span>00:00 </span>
                        </div>

                    </div>
                </div>
            </Drawer>

            {/*Set Drawer*/}

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onSetDrawerClose} visible={visibleSetDrawer}>
                <div className="num-drawer">
                    <div className="num-drawer-header">
                        <span>تعداد ست</span>
                    </div>
                    <div className="num-drawer-list">
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",1)}>
                            <span>1 ست</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",2)}>
                            <span>2 ست</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",3)}>
                            <span>3 ست</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",4)}>
                            <span>4 ست</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",5)}>
                            <span>5 ست</span>
                        </div>

                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",6)}>
                            <span>6 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",7)}>
                            <span>7 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",8)}>
                            <span>8 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",9)}>
                            <span>9 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",10)}>
                            <span>10 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",11)}>
                            <span>11 ست</span>
                        </div>
                        <div className="num-drawer-item" onClick={e=>handleSelectItems("set",12)}>
                            <span>12 ست</span>
                        </div>

                    </div>
                </div>
            </Drawer>


            {/*option drawer*/}
            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onOptionDrawerClose} visible={visibleOptionDrawer}>
                <div className="option-drawer">
                    <div className="option-drawer-item" onClick={e=>{
                        showCopyFromDrawer()
                    }}>
                        <span>شبیه روز </span>
                    </div>

                    <div className="option-drawer-item" onClick={e=>{
                        showCopyToDrawer()
                    }}>
                        <span>کپی شود برای </span>
                    </div>

                    <div className="option-drawer-item">
                        <span>استراحت</span>
                    </div>

                    <div className="option-drawer-item">
                        <span>خالی کردن</span>
                    </div>
                </div>
                <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onCopyFromDrawerClose} visible={visibleCopyFromDrawer}>
                   <div className="option-drawer">
                       <div className="num-drawer--float-header" onClick={e=>{
                           handleCopyFrom()
                       }}>
                           <span>اعمال تغییرات</span>
                       </div>
                       <br/><br/>
                       {plan[currentSection].times.map(t=>{
                           return(
                               <div  className={SelectedCopyFromDrawer.index===t.index?"option-drawer-item active-option":"option-drawer-item"}  onClick={e=>{
                                   handleSelectCopyFrom(t)
                               }}>
                                   <span>{t.jDay}</span>
                                   <span>{t.weekDay}</span>
                               </div>
                           )
                       })}
                   </div>
                </Drawer>

                <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={170} closable={false}  onClose={onCopyToDrawerClose} visible={visibleCopyToDrawer}>
                    <div className="option-drawer">
                        <div className="num-drawer--float-header" onClick={e=>{
                            handleCopyTo()
                        }}>
                            <span>اعمال تغییرات کپی</span>
                        </div>
                        <br/><br/>
                        {plan[currentSection].times.map(t=>{
                            return(
                                <div className={existInSelectedCopyTo(SelectedCopyToDrawer,t)?"option-drawer-item active-option":"option-drawer-item"}  onClick={e=>{
                                    handleSelectCopyTo(t)
                                }}>
                                    <span>{t.jDay}</span>
                                    <span>{t.weekDay}</span>
                                </div>
                            )
                        })}
                    </div>
                </Drawer>
            </Drawer>

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}} placement={"left"}  width={250} closable={false}  onClose={onLibDrawerClose} visible={libDrawer}>
                <div className="lib-title">
                    <span>برنامه های آماده</span>
                </div>
                <Spin spinning={libSpin}>
                    <div className="lib-list">
                        {lib.map(l=>{
                            return(
                                <div className="lib-row">
                                    <div className="lib-item-delete clickable" onClick={e=>{
                                        handleDeleteLibItem(l)
                                    }}>
                                        <img src={trashIcon} alt=""/>
                                    </div>
                                    <div className="lib-item" onClick={e=>{
                                        handleSelectLib(l)
                                    }}>
                                        <span>{l.title}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Spin>

            </Drawer>




            {/*Chat Trainee*/}

            {/*Box Trainee*/}
            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                {visibleBoxTraineeDrawer?<DumbbellBoxTrainer visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={trainee} />:null}
            </Drawer>

            <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                {visibleChatTraineeDrawer?<DumbbellChatTrainer visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={trainee} />:null}
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                <DumbbellAddTrainee fill={trainee}/>
            </Drawer>

        </div>
    )
}

export default DumbbellPlanCreate;
