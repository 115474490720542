import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellPlanTrainee.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import sampleFace from "../../assets/dumbbell_image/sample-trainee-face.png";
import capsuleIcon from "../../assets/dumbbell_image/capsule-icon.png";
import dumbbellIcon from "../../assets/dumbbell_image/dumbbell-icon.png";
import foodIcon from "../../assets/dumbbell_image/food-icon.png";
import micIcon from "../../assets/dumbbell_image/mic-icon.png";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import editIcon from "../../assets/dumbbell_image/edit-icon.png";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import { v4 as uuidv4 } from 'uuid';
import {Setup} from "../../Services/Setup";
import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import {Drawer, message, Spin} from "antd";
import moment from "moment-jalaali";
import {useParams} from "react-router";
import {
    getMyProfileApi,
    mediaChallengeDeleteApi,
    mediaDeleteApi,
    mediaRedDeleteApi, myGuestPlanOneApi, myPlanOneApi,
    planOneApi,
    savePlanApi
} from "../../Services/Services";
import {convertMonthIntNumberToName} from "../../Services/utils";
import DumbbellBoxTrainee from "../DumbbellBoxTrainee/DumbbellBoxTrainee";
import DumbbellChatTrainee from "../DumbbellChatTrainee/DumbbellChatTrainee";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import messageIcon from "../../assets/dumbbell_image/message-icon.png";
import _DumbbellHeaderTrainee from "../../Components/_DumbbellHeaderTrainee/_DumbbellHeaderTrainee";

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const params = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    // rebuildOnUpdate : true,
    rtl:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}




function DumbbellPlanTrainee(){

    const MyRef = useRef(null);
    const {planId} = useParams()


    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])




    const [basePlan,set_basePlan] = useState({})
    const [plan,set_plan] = useState(
        {
            supplement:{
                type:"week", // week,day
                times:[]
            },
            diet:{
                type:"week", // week,day
                times:[]
            },
            exercise:{
                type:"week", // week,day
                times:[]
            }
        }
    )
    const [my, set_my] = useState({})
    const [token, set_token] = useState(null)

    useEffect(()=>{
        let t = localStorage.getItem("token")
        if(t){
            set_token(t)
            getPlan()
            getMyProfile()
        }else {
            getGuestPlan()
        }

        initStartDate()
    },[])




    const getMyProfile = async ()=>{
        try{
            const res = await getMyProfileApi()
            set_my(res.data.data)
        }catch (e) {
            console.log(e)
        }
    }


    const [unread,set_unread] = useState(false);

    const getGuestPlan = async ()=>{
        try{
            const response = await myGuestPlanOneApi(planId)
            set_unread(response.data.unread)
            let p = response.data.data;
            let diet = p.data.diet.times
            let exercise = p.data.exercise.times
            let supplement = p.data.supplement.times
            set_basePlan(p)
            set_plan({
                supplement:{
                    times:supplement.length<1?generateTimes(p):supplement
                },
                diet:{
                    times:diet.length<1?generateTimes(p):diet
                },
                exercise:{
                    times:exercise.length<1?generateTimes(p):exercise
                }
            })
        }catch(e){
            console.log(e)
        }
    }


    const getPlan = async ()=>{
        try{
            const response = await myPlanOneApi(planId)
            set_unread(response.data.unread)
            let p = response.data.data;
            let diet = p.data.diet.times
            let exercise = p.data.exercise.times
            let supplement = p.data.supplement.times
            set_basePlan(p)
            set_plan({
                supplement:{
                    times:supplement.length<1?generateTimes(p):supplement
                },
                diet:{
                    times:diet.length<1?generateTimes(p):diet
                },
                exercise:{
                    times:exercise.length<1?generateTimes(p):exercise
                }
            })
        }catch(e){
            console.log(e)
        }
    }

    const generateTimes = (p)=>{
        console.log("Generating exercise")
        let start = new Date()
        let results =[]
        for (let i = 0; i < p.duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }

        return results

    }

    //re generate start Date
    const[reStartDay,set_reStartDay] = useState('0')
    const[reStartMonth,set_reStartMonth] = useState('0')
    const[reStartYear,set_reStartYear] = useState('0')

    const initStartDate =()=>{
        let d = moment(new Date()).format('jDD')
        let m = moment(new Date()).format('jMM')
        let y = moment(new Date()).format('jYYYY')
        console.log(d,m,y)
        set_reStartDay(d)
        set_reStartMonth(m)
        set_reStartYear(y)
        set_startLabel(`${y}/${m}/${d}`)
    }


    const reTimes = (start,duration)=>{
        let results =[]
        for (let i = 0; i < duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }
        return results
    }


    const [startLabel,set_startLabel] = useState("")
    const [currentSection,set_currentSection]= useState("exercise") //supplement, diet ,exercise
    const [currentTimes,set_currentTimes]= useState("")
    const [selectTimes,set_selectTimes]= useState({})

    const [swiperReset,set_swiperReset] = useState(true)
    const convertDayOfWeek = (num)=>{
        console.log("num",num)
        switch(num){
            case 1:
                return "دوشنبه"
                break;
            case 2:
                return "سشنبه"
                break;
            case 3:
                return "چهارشنبه"
                break;
            case 4:
                return "پنجشنبه"
                break;
            case 5:
                return "جمعه"
                break;
            case 6:
                return "شنبه"
                break;
            case 0:
                return "یکشنبه"
                break;


        }
    }




    const handleClickOnTimes =(t)=>{
        console.log(t)
        set_currentTimes(t.index)
        set_selectTimes(t)
    }


    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_visibleChatTraineeDrawer(false);
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };


    const [selectedPart,set_selectedPart] = useState("")

    return(
        <div>
            <div className="fix-content">

                {token? <_DumbbellHeaderTrainee user={my}/>:null}

                {!token?
                <div className="guest-header">
                    <span className="guest-header-hint">برای چت و ارتباط با مربی وارد شوید</span>
                    <span className="guest-header-btn clickable" onClick={e=>{

                    }}>ورود به اپ</span>
                </div>
                :null}

                <div className="plan-add-header" style={{alignItems:"baseline"}}>

                    <div className="dumbbell-header-plan-card-actions">
                        <div className="dumbbell-trainee-card-message" onClick={e=>{
                            showChatDrawer()
                        }}>
                            <img src={messageIcon} alt=""/>
                            {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}
                        </div>

                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showBoxDrawer()
                        }}>
                            <CodeSandboxOutlined style={{
                                color: "#cdc4c4",
                                fontSize: 19,
                                marginTop: 6,
                            }} />
                        </div>

                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showProfileDrawer()
                        }}>
                            <UserOutlined  style={{
                                color: "#cdc4c4",
                                fontSize: 19,
                                marginTop: 6,
                            }} />
                        </div>
                    </div>


                    {basePlan._id?<div className="plan-add-header-info">
                        <span className="name">{basePlan.trainer_first_name+" "+basePlan.trainer_last_name}</span>
                        <span className="mobile"></span>
                    </div>:null}

                    <div className="plan-add-header-image">
                        <img src={sampleFace} alt=""/>
                    </div>
                </div>
                <div className="plan-add-header-type">
                    <span>{startLabel} تاریخ شروع </span>
                </div>
            </div>

            <div className="plan-add-tabs">

                <div className="plan-add-tabs-item"
                     onClick={e=>set_currentSection("diet")}
                     style={currentSection==="diet"?{backgroundColor:"#FFCE31"}:{}}>
                    <img src={foodIcon} alt=""/>
                    <span>تغذیه</span>
                </div>

                <div className="plan-add-tabs-item active"
                     onClick={e=>set_currentSection("exercise")}
                     style={currentSection==="exercise"?{backgroundColor:"#FFCE31"}:{}}>
                    <img src={dumbbellIcon} alt=""/>
                    <span>تمرین</span>
                </div>

                <div className="plan-add-tabs-item"
                     onClick={e=>{
                         console.log(plan)
                         set_currentSection("supplement")
                     }}
                     style={currentSection==="supplement"?{backgroundColor:"#FFCE31"}:{}}>
                    <img src={capsuleIcon} alt=""/>
                    <span>مکمل</span>
                </div>
            </div>




            <div className="plan-sec">

                {plan[currentSection]?.times.length>0?<div className="plan-bar">

                    {swiperReset?<div className="plan-bar-list">
                        <Swiper ref={MyRef}  {...params}>
                            {plan[currentSection].times.map(time=>{

                                return(
                                    <div  className={'plan-bar-item'}
                                          style={currentTimes===time.index?{borderBottom:"2px solid",color:"#8D89FD",fontFamily:"YekanBold"}:{}}
                                          onClick={e=>{
                                              handleClickOnTimes(time)
                                          }}>

                                        <span>{time.jDay}</span>
                                        <span>{time.weekDay}</span>
                                    </div>
                                )
                            })}

                        </Swiper>
                    </div>:null}


                    <div className="plan-month">
                        {selectTimes.jMonth?<span>{selectTimes.jYear} {convertMonthIntNumberToName(selectTimes.jMonth)}</span>:null}
                    </div>
                </div>:null}



                <div className="plan-create-list">

                    {plan[currentSection]?.times[currentTimes]?.parts.map((part,i)=>{
                        return(
                            <Fragment>
                                {currentSection==="exercise"?
                                    <div className="plan-card">
                                        <div className="plan-exercise-card-main">
                                            <div className="plan-exercise-card-main-header">
                                                <div className="plan-exercise-card-main-header-delete" >
                                                    {/*<img src={trashIcon} alt=""/>*/}
                                                </div>
                                                <div className="plan-exercise-card-main-header-name">
                                                    <span>{part.text}</span>
                                                </div>
                                            </div>

                                            <div className="plan-exercise-card-main-body">
                                                <div className="plan-exercise-card-main-body-child">
                                                    <div className="plan-exercise-card-main-body-child-item">
                                                        <span className="plan-exercise-card-main-body-child-item-val">{part.items[2].value}</span>
                                                        <span className="plan-exercise-card-main-body-child-item-label">ست</span>
                                                    </div>
                                                    <div className="plan-exercise-card-main-body-child-item">
                                                        <span className="plan-exercise-card-main-body-child-item-val">{part.items[1].value}</span>
                                                        <span className="plan-exercise-card-main-body-child-item-label">تکرار</span>
                                                    </div>
                                                    {/*<div className="plan-exercise-card-main-body-child-item">*/}
                                                    {/*    <span className="plan-exercise-card-main-body-child-item-val">0</span>*/}
                                                    {/*    <span className="plan-exercise-card-main-body-child-item-label">مدت</span>*/}
                                                    {/*</div>*/}
                                                    <div className="plan-exercise-card-main-body-child-item">
                                                        <span className="plan-exercise-card-main-body-child-item-val">{part.items[3].value}</span>
                                                        <span className="plan-exercise-card-main-body-child-item-label">استراحت(ثانیه)</span>
                                                    </div>
                                                </div>
                                                <div className="plan-exercise-card-main-body-video"
                                                     style={{backgroundImage: `url(${part.url})`}}
                                                >
                                                    <img src={playIcon} alt=""/>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="plan-card-number">
                                            <span>{part.order}</span>
                                            {plan[currentSection]?.times[currentTimes]?.parts[i+1]?<div className="plan-card-number-line"></div>:null}

                                        </div>

                                    </div>:
                                    <div className="plan-card-diet">
                                        <div className="plan-exercise-card-main">
                                            <div
                                                onClick={e=>{
                                                    set_selectedPart(part.id)
                                                }}
                                                className={selectedPart===part.id?"plan-exercise-card-main-header active-part":"plan-exercise-card-main-header"}>
                                                <div className="plan-exercise-card-main-header-delete">
                                                    {/*<img src={trashIcon} alt=""/>*/}
                                                </div>
                                                <div className="plan-exercise-card-main-header-name">
                                                    <span>{part.text}</span>
                                                </div>
                                            </div>

                                            <div className="plan-exercise-card-main-body">
                                                <div className="plan-exercise-card-main-body-childes">
                                                    {plan[currentSection]?.times[currentTimes]?.parts[part.id-1]?.items.map(i=>{
                                                            return(
                                                                <div className="plan-exercise-row">
                                                                    <span>{i.text}</span>
                                                                    <span className="bullet-blue"></span>
                                                                </div>
                                                            )
                                                        }

                                                    )}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="plan-card-number">
                                            <span>{part.order}</span>

                                        </div>

                                    </div>
                                }
                            </Fragment>
                        )
                    })}


                </div>


            </div>


            {/*Box Trainee*/}
            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                {visibleBoxTraineeDrawer?<DumbbellBoxTrainee visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={{_id:basePlan.trainer_id,first_name:basePlan.trainer_first_name,last_name:basePlan.trainer_last_name,mobile:""}} />:null}
            </Drawer>

            <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                {visibleChatTraineeDrawer?<DumbbellChatTrainee visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={{_id:basePlan.trainer_id,first_name:basePlan.trainer_first_name,last_name:basePlan.trainer_last_name,mobile:""}} />:null}
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                <DumbbellAddTrainee fill={my}/>
            </Drawer>
        </div>
    )
}

export default DumbbellPlanTrainee;
