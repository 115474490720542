import React from "react";
import "./DumbbellDrawerHeader.scss"

function DumbbellDrawerHeader({title}){
    return(
        <div className="dumbbell-drawer-header">
            <span>{title}</span>
        </div>
    )
}

export default DumbbellDrawerHeader