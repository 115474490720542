import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configurations.
var firebaseConfig = {
    apiKey: "AIzaSyBzDff3os66w7UTpdSgje1jTBkcGxzck4g",
    authDomain: "starium-40b21.firebaseapp.com",
    projectId: "starium-40b21",
    storageBucket: "starium-40b21.appspot.com",
    messagingSenderId: "829469170793",
    appId: "1:829469170793:web:a1ec3de940b3504db7d6f8"
};

/**
 * This function requests permission for notifications from Firebase messaging API.
 * It returns a Promise that resolves with the Firebase notification
 * token if permission is granted,
 * or rejects with an error if the request fails or is denied.
 */
export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

/**
 * This function returns a subscription token from Firebase which
 * is used to identify a unique system.
 *
 * If the token is successfully retrieved, it will be logged and returned.
 * If not, the function will request notification permission
 * and log the result of the request.
 */
export const getTokenFromFirebase = () => {
    return getToken(messaging, {
        vapidKey: "BNZjdO12_oqoDG2j9_aAazkOHZSFWfxd6gDKGpq22GRtyYOdBylyskcgzhz9-HK0qsaHMninBBB8soqV3RYrD_8",
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                return currentToken;
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
                Notification.requestPermission().then((permission) => {
                    console.log("Access for notification is ", permission);
                });
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });
};

/**
 * The onMessage function from the Firebase Messaging API
 * is used to listen for incoming messages.
 */
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);