import React,{useEffect} from 'react';
import "./DumbbellTraineeProfile.scss";

function DumbbellTraineeProfile(){

    return(
        <div>
            <h1>DumbbellTraineeProfile ...</h1>
        </div>
    )
}

export default DumbbellTraineeProfile;
