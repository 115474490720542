import React, {Suspense,useEffect,useState } from 'react';
import './App.css';
import "./Main.scss";
import "antd/dist/antd.css";
import { BgColorsOutlined } from '@ant-design/icons';

import {Route, useHistory, useLocation, withRouter} from "react-router-dom";
//Protected Routes
import Login from "./Pages/DumbbellLogin/DumbbellLogin";
import Verify from "./Pages/DumbbellVerify/DumbbellVerify";
import ProfileTrainer from "./Pages/DumbbellTrainerProfile/DumbbellTrainerProfile";
import MyTrainee from "./Pages/DumbbellTrainee/DumbbellTrainee";
import TrainerPlanList from "./Pages/DumbbellPlanList/DumbbellPlanList";
import TrainerPlanCreate from "./Pages/DumbbellPlanCreate/DumbbellPlanCreate";
import TrainerVideoList from "./Pages/DumbbellVideoList/DumbbellVideoList";
import TrainerVideoUpload from "./Pages/DumbbellVideoUpload/DumbbellVideoUpload";
import TrainerLandingPage from "./Pages/DumbbellLandingPage/DumbbellLandingPage";
import TrainerLandingPageEdit from "./Pages/DumbbellLandingPageCreate/DumbbellLandingPageCreate";
import BookingLand from "./Pages/DumbbellBookingLand/DumbbellBookingLand";
import TrainerPayList from "./Pages/DumbbellPayList/DumbbellPayList";
import BookingManagement from "./Pages/DumbbellBookingManagement/DumbbellBookingManagement";

import LoginTrainee from "./Pages/DumbbellLoginTrainee/DumbbellLoginTrainee";
import VerifyTrainee from "./Pages/DumbbellVerifyTrainee/DumbbellVerifyTrainee";
import TraineeProfile from "./Pages/DumbbellTraineeProfile/DumbbellTraineeProfile";
import TraineePlanList from "./Pages/DumbbellPlanListTrainee/DumbbellPlanListTrainee";
import TraineePlan from "./Pages/DumbbellPlanTrainee/DumbbellPlanTrainee";
import TraineePayList from "./Pages/DumbbellPayListTrainee/DumbbellPayListTrainee";
import DumbbellTrainerWelcome from "./Pages/DumbbellTrainerWelcome/DumbbellTrainerWelcome";
import DumbbellInvoice from "./Pages/DumbbellInvoice/DumbbellInvoice";
import DumbbellCallback from "./Pages/DumbbellCallback/DumbbellCallback";





import LoginLogoStar from "./assets/images/login_logo.svg"
import LoginLogoTitle from "./assets/images/login_logo_title.svg"
import {ErrorDrawer} from "./Components/ErrorDrawer/ErrorDrawer";
import {useError} from "./Hook/useError";
import {useDispatchError} from "./Hook/useDispatchError";
import ProtectedRoute from "./Components/ProtectedRoute";
import {HealthCheckApi, sendPushToken_api} from "./Services/Services";
import {getTokenFromFirebase, onMessageListener} from "./firebase";
import {useNotification} from "./Hook/useNotification";
import {useDispatcherNotification} from "./Hook/useDispatcherNotification";

import { notification } from 'antd';
import DumbbellPayList from "./Pages/DumbbellPayList/DumbbellPayList";
import DumbbellPlanListTrainee from "./Pages/DumbbellPlanListTrainee/DumbbellPlanListTrainee";
import DumbbellCallbackLand from "./Pages/DumbbellCallbackLand/DumbbellCallbackLand";
import DumbbellTraineeWelcome from "./Pages/DumbbellTraineeWelcome/DumbbellTraineeWelcome";
import DumbbellCallbackBookingLand from "./Pages/DumbbellCallbacBookingkLand/DumbbellCallbackBookingLand";
import PrintablePlan from "./Pages/PrintablePlan/PrintablePlan";
import DumbbellMyRequests from "./Pages/DumbbellMyRequests/DumbbellMyRequests";
import PageV2 from "./Pages/PageV2/PageV2";
import Package from "./Pages/Package/Package";
import PageEditV2 from "./Pages/PageEditV2/PageEditV2";
import MyStep from "./Pages/MyStep/MyStep";
import DumbbellCallbackDebt from "./Pages/DumbbellCallbackDebt/DumbbellCallbackDebt";
import ProtectedRouteTrainee from "./ProtectedRouteTrainee";
import DumbbellLoginTraineeV2 from "./Pages/DumbbellLoginTraineeV2/DumbbellLoginTraineeV2";
import DumbbellVerifyTraineeV2 from "./Pages/DumbbellVerifyTraineeV2/DumbbellVerifyTraineeV2";
import DumbbellPlanCreateTrainee from "./Pages/DumbbellPlanCreateTrainee/DumbbellPlanCreateTrainee";
import Loading from "./Components/Loading/Loading";
import PrintablePlanDiet from "./Pages/PrintablePlan/PrintablePlanDiet";
import PrintablePlanSupplement from "./Pages/PrintablePlan/PrintablePlanSupplement";
import UploadLoading from "./Components/UploadLoading/UploadLoading";
import Ipg from "./Pages/Ipg/Ipg";


const openNotificationWithIcon = (d,history) => {
    notification.open({
        message: d.title,
        duration:3,
        onClick:()=>{
            history.push("/inbox")
        },
        icon: (
            <img style={{width:30}} src={d.icon} alt=""/>
        ),
        description:d.body,
    });
};

function App() {




    let error = useError()
    let errorDispatch = useDispatchError()

    let notification = useNotification()
    let notificationDispatch = useDispatcherNotification()

    let location = useLocation();
    let history = useHistory();

    const [notificationState, setNotificationState] = useState({open: false, message: "",});

    onMessageListener()
        .then((payload) =>{
                notificationDispatch([...notification,payload.data])
                openNotificationWithIcon(payload.data,history);
            //show local Notification
            // const n = new Notification(payload.data.title, payload.data);
                setNotificationState({
                    open: true,
                    message: `🗓 ${payload.data.body}`,
                })
            }
        )
        .catch((err) => {
            console.log(`An error occured when showing notif ${err}`);
        });


    //send push token To server
    useEffect(()=>{
        // sendFcmToken()
    },[])

    const sendFcmToken = async ()=>{
        const token = localStorage.getItem("token")
        if(window.Notification.permission==="granted" && token) {
            let fcmToken = await getTokenFromFirebase()
            sendPushToken_api(fcmToken)
        }
    }

    // useEffect(() => {
    //     if(location.pathname!=="/offline")
    //             checkInternetConnection()
    // }, [location]);
    //
    // const checkInternetConnection = async ()=>{
    //     try{
    //         await HealthCheckApi()
    //     }catch (e) {
    //         history.push("/offline")
    //         console.log("e")
    //     }
    // }



    return (
        <>
            <div className="master-sec">
                <div className="desk-sec">
                    <img className="image-sec-star" src={LoginLogoStar} alt=""/>
                    <img className="image-sec-title" src={LoginLogoTitle} alt=""/>
                </div>
                <div className="mobile-sec" id="mobile-sec">
                    <Loading/>
                    <UploadLoading/>
                    <div className="main_container" id="App">
                        {/*Trainer Routes*/}
                        <ProtectedRoute path='/' exact component={MyStep} />
                        <Route path='/login-trainer' exact component={Login} />
                        <Route path='/verify-trainer' exact component={Verify} />
                        <Route path='/welcome-trainer/:welcomeId' exact component={DumbbellTrainerWelcome} />
                        <Route path='/welcome/:welcomeId' exact component={DumbbellTraineeWelcome} />
                        {/*<Route path='/land/:slug' exact component={TrainerLandingPage} />*/}
                        <Route path='/page/:slug' exact component={PageV2} />
                        <Route path='/ipg' exact component={Ipg} />

                        <ProtectedRoute path='/my-trainee' exact component={MyTrainee} />
                        <ProtectedRoute path='/my-requests' exact component={DumbbellMyRequests} />
                        <ProtectedRoute path='/profile-trainer/:traineeId' exact component={ProfileTrainer} />
                        <ProtectedRoute path='/trainer/plan-list/:traineeId' exact component={TrainerPlanList} />
                        <ProtectedRoute path='/trainer/plan-add/:traineeId/:planId' exact component={TrainerPlanCreate} />
                        <ProtectedRoute path='/trainer/video-list' exact component={TrainerVideoList} />
                        <ProtectedRoute path='/trainer/video-upload/:trainerId' exact component={TrainerVideoUpload} />
                        <ProtectedRoute path='/trainer/page-edit' exact component={PageEditV2} />
                        <ProtectedRoute path='/trainer/packages' exact component={Package} />
                        <ProtectedRoute path='/trainer/land-edit' exact component={TrainerLandingPageEdit} />
                        <ProtectedRoute path='/trainer/booking-land' exact component={BookingLand} />
                        <ProtectedRoute path='/trainer/pay-list' exact component={TrainerPayList} />
                        <ProtectedRoute path='/trainer/booking-management' exact component={BookingManagement} />
                        <ProtectedRoute path='/printable/:planId' exact component={PrintablePlan} />
                        <ProtectedRoute path='/printable-diet/:planId' exact component={PrintablePlanDiet} />
                        <ProtectedRoute path='/printable-supplement/:planId' exact component={PrintablePlanSupplement} />
                        <ProtectedRoute path='/my-step' exact component={MyStep} />

                        {/*public*/}
                        <Route path='/invoice/:planId' exact component={DumbbellInvoice} />
                        <Route path='/callback' exact component={DumbbellCallback} />
                        <Route path='/callback-debt' exact component={DumbbellCallbackDebt} />
                        <Route path='/callback-land/:requestId' exact component={DumbbellCallbackLand} />
                        <Route path='/callback-booking/:requestId' exact component={DumbbellCallbackBookingLand} />


                        {/*Trainee Routes*/}
                        <Route path='/login-trainee' exact component={DumbbellLoginTraineeV2} />
                        <Route path='/verify-trainee' exact component={DumbbellVerifyTraineeV2} />
                        {/*<Route path='/dc/:planId' exact component={TraineePlan} />*/}
                        <Route path='/dc/:planId' exact component={DumbbellPlanCreateTrainee} />

                        {/*<ProtectedRouteTrainee path='/profile-trainee/:traineeId' exact component={TraineeProfile} />*/}
                        <ProtectedRouteTrainee path='/trainee/plan-list' exact component={TraineePlanList} />
                        {/*<ProtectedRouteTrainee path='/trainee/pay-list' exact component={TraineePayList} />*/}


                    </div>
                </div>
                <ErrorDrawer error={error} />
            </div>
        </>
    );
}


export default withRouter(App);

// SMS

//auth
//welcome trainer  - auto login
//send invoice     -
//pay trainee      - auto login
//pay trainee land - auto login
//send plan        - auto login
