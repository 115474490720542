import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellVideoUpload.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import ReactPlayer from 'react-player'

// import sampleMov from "../../assets/video/sample_video.mov";
// import sampleMp4 from "../../assets/video/sample_video.mp4";
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import {boxImageUploadApi, videoUploadApi} from "../../Services/Services";
import {LoadingOutlined} from "@ant-design/icons"
import {message,Spin} from "antd";
import {checkFileSize} from "../../Services/utils";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DumbbellVideoUpload({handleUploadDone}){

    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);
    const [spinTip,set_spinTip] = useState("")
    const[uploadMode,set_uploadMode] = useState(false)
    const[title,set_title] = useState("")
    const[cat,set_cat] = useState("")
    const setUploadLoading = useDispatchUploadLoading()


    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 100
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره ویدیو  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود ویدیو  . . ."})
        }
    },[thumbUploadProgress])

    const upload = async ()=>{

        if(title.length<2){
            return message.error({
                content: "لطفا عنوان حرکت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!imageFile){
            return message.error({
                content: "لطفا ویدیو را انتخاب نمایید",
                className: 'toast-class'
            });
        }


        set_spinTip("در حال آپلود...")
        set_spin(true)
        set_uploadMode(true)
        if(imageFile){
            try{
                let response = await videoUploadApi(imageFile,title,cat,set_thumbUploadProgress);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }

    }

    const uri = "https://media.starium-space.com/d69b6df3-e7ea-4450-aced-ad0470e3857a/sample_video.mov"

    return(
        <Spin indicator={antIcon} tip={"در حال فشرده سازی و آپلود ویدیو..."} spinning={spin}>
            <div>
                <DumbbellDrawerHeader title={"آپلود ویدیو"}/>

                <div className="dumbbell-video-upload-wr">
                    {imagePrev?<div className="edit-video-icon clickable" onClick={handleClickItems}>
                            <img src={EditIcon} alt=""/>
                    </div>:null}
                    {!imagePrev?<div className="dumbbell-video-upload-sec clickable" onClick={e=>{
                        handleClickItems()
                    }}>
                        <img   src={playIcon} alt=""/>
                    </div>:null}
                    {imagePrev?
                        <ReactPlayer width={"100%"}  url={imagePrev} controls />:
                        null
                    }

                    <div className="hint-message">
                        <span>حجم هر ویدیو ارسالی نباید بیشتر از 100 مگابایت باشد</span>
                    </div>
                </div>

                <div className="dumbbell-video-upload-title">
                    <input
                        value={title}
                        onChange={e=>set_title(e.target.value)}
                        type="text" name="" id="" placeholder="عنوان حرکت"/>
                </div>

                <div className="dumbbell-video-upload-btn" onClick={
                    e=>upload()
                }>
                    <div>آپلود ویدیو</div>
                </div>

                <input type="file" ref={fileRef} name="" accept="video/mp4,video/x-m4v,video/*" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
            </div>
        </Spin>
    )
}

export default DumbbellVideoUpload;
