import React,{useEffect,useState,useRef} from 'react';
import "./DumbbellPlanList.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png";
import messageIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg"
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg"
import defaultUserIcon from "../../assets/images/default_user.png"

import sampleFace from "../../assets/images/default_user.png"
import refreshIcon from "../../assets/dumbbell_image/refresh-icon.png"
import {
    cancelMeet_api,
    deletePlanApi,
    getTraineeApi,
    planListApi, planSeenApi,
    traineeListApi,
    trainerChatSeenMessageApi
} from "../../Services/Services";
import {useHistory, useParams} from "react-router";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import {Drawer, Popconfirm} from "antd";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import DumbbellRequestPlan from "../DumbbellRequestPlan/DumbbellRequestPlan";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import moment from "moment-jalaali";
import {checkPlanEndDate, convertMonthNumberToName, numberWithCommas} from "../../Services/utils";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import {Setup} from "../../Services/Setup";
import defaultUser from "../../assets/images/default_user.png";
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import {DeleteOutlined} from "@ant-design/icons";

let page = 0;
let search = "";
let triggerID = ""

function btnStatusAccess(status,pay_status) {
    if(status==="default" && pay_status==="default")
        return false

    if(status==="default" && pay_status==="payed")
        return true

    if(status==="delivered" && pay_status==="payed")
        return true
}

function btnStatus(status,pay_status,planIsEnd) {
    if(planIsEnd)
        return "مشاهده"

    if(status==="default" && pay_status==="default")
        return "منتظر پرداخت"

    if(status==="default" && pay_status==="payed")
        return "تنظیم برنامه"

    if(status==="delivered" && pay_status==="payed")
        return "مشاهده / ویرایش"
}

function PlanCard({plan,getPaginationPlanList,traineeId,trainee,removeFromPlanList}){

    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [planIsEnd,set_planIsEnd] = useState(false);
    const [currentSeen,set_currentSeen] = useState(plan.seen);
    useEffect(()=>{
        set_planIsEnd(checkPlanEndDate(plan))
    },[])


    const seen = async (id)=>{
        try{
            await planSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===plan._id){
            console.log(plan._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationPlanList()
        }
        if(!currentSeen && isVisible ){
            console.log(plan._id,"seen",isVisible)
            set_currentSeen(true)
            seen(plan._id)
            //api call
        }
    },[isVisible])

    const confirm = async(e) => {
        console.log(e);
        try {
            await deletePlanApi(plan._id)
            removeFromPlanList(plan)
        }catch (e) {
             console.log(e)
        }
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    return(
    <div className="plan-card-list" ref={ref}>
        {/*<div className="plan-card-header">*/}
        {/*    {plan.pay_status!=="payed"?*/}
        {/*        <Popconfirm*/}
        {/*            title="آیا شما از حذف این برنامه مطمئن هستید ؟"*/}
        {/*            onConfirm={confirm}*/}
        {/*            onCancel={cancel}*/}
        {/*            okText="بله"*/}
        {/*            cancelText="خیر"*/}
        {/*        >*/}

        {/*            <DeleteOutlined style={{fontSize:20}} />*/}
        {/*        </Popconfirm>:<span></span>*/}
        {/*    }*/}

        {/*    {planIsEnd?*/}
        {/*        <span> (طول مدت برنامه به پایان رسیده است) </span>:*/}
        {/*        <span>( تحویل حداکثر{plan.delivery_estimate} روز بعد از پرداخت ) </span>*/}
        {/*    }*/}
        {/*    <span>{plan.title}</span>*/}
        {/*</div>*/}
        <div className="plan-card-body-v2">
            <div className="plan-card-fix-row clickable">
                <div className="plan-card-fix-price">
                    <span className="price">{numberWithCommas(plan.amount)}</span>
                    <span className="unit">تومان</span>
                </div>
                <div className="plan-card-fix-trainee">
                    <span className="trainee-name">{plan.title}</span>
                    <span className="trainee-date">{moment(plan.createdAt).format('jYYYY/jMM/jDD HH:mm')}</span>
                </div>
            </div>
            <div className="plan-card-progress">
                <div className="plan-card-progress-station" style={{backgroundColor:"#3195FF"}}>
                    <span className="plan-card-progress-station-date">
                        <span>{moment(plan.createdAt).format('jDD')}</span>
                        <span>{convertMonthNumberToName(moment(plan.createdAt).format('jMM'))}</span>
                    </span>
                    <span className="plan-card-progress-station-label">ثبت</span>
                </div>
                <div className="plan-card-progress-line" style={plan.payedAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}></div>
                <div className="plan-card-progress-station" style={plan.payedAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {plan.payedAt?
                            <>
                                <span>{moment(plan.payedAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(plan.payedAt).format('jMM'))}</span>
                            </>
                            :""}
                    </span>
                    <span className="plan-card-progress-station-label">پرداخت</span>
                </div>
                <div className="plan-card-progress-line" style={plan.deliveredAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}></div>
                <div className="plan-card-progress-station" style={plan.deliveredAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {plan.deliveredAt?
                            <>
                                <span>{moment(plan.deliveredAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(plan.deliveredAt).format('jMM'))}</span>
                            </>
                            :""}
                        {trainee.delivery_date && parseInt(moment(trainee.delivery_date).format('jYYYY'))<1500 && !plan.deliveredAt?
                            <div className="plan-card-delivery_date">
                                <span>{moment(trainee.delivery_date).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(trainee.delivery_date).format('jMM'))}</span>
                            </div>
                            :""}
                    </span>
                    <span className="plan-card-progress-station-label">تحویل</span>
                </div>
            </div>

            <div className="plan-card-footer">

                <div className="plan-card-footer-btn clickable" onClick={e=>{
                    if(btnStatusAccess(plan.status,plan.pay_status))
                    history.push(`/trainer/plan-add/${traineeId}/${plan._id}`)
                }}>
                    <span>{btnStatus(plan.status,plan.pay_status,planIsEnd)}</span>
                </div>
            </div>

            <div className="plan-card-target">
                <span className="plan-card-target-title"> هدف برنامه : </span>
                <p>{plan.target}</p>
            </div>
        </div>
    </div>
    )
}


function DumbbellPlanList(){

    // useEffect(()=>{
    //     document.body.style.backgroundColor = "#8A86FE";
    // },[])

    const {traineeId} = useParams()
    const [trainee,set_trainee] = useState({})
    const [unread,set_unread] = useState(0)
    const [plans,set_plans] = useState([])
    const [totalCount, set_totalCount] = useState(0)
    const [my,set_my] = useState(null)

    useEffect(()=>{
        if(traineeId){
            getTrainee()
            getPlanList()
            getLocalUser()
        }
    },[])


    const getLocalUser = ()=>{
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }

    const getTrainee = async ()=>{
        try{
            const res = await getTraineeApi(traineeId)
            set_trainee(res.data.data.trainee)
            set_unread(res.data.data.unread)
        }catch (e) {
            console.log(e)
        }
    }


    const getPlanList = async ()=>{
        set_plans([])
        try{
            const res = await planListApi(traineeId,0,search)
            let trainee = res.data.data.plans
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_plans(trainee)
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }
    const getPaginationPlanList = async ()=>{
        try{
            const res = await planListApi(page,search)
            let trainee = res.data.data.plans
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_plans(oldArray => [...oldArray, ...trainee])
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }

    const [visibleRequestPlanDrawer,set_visibleRequestPlanDrawer] = useState(false)

    const showRequestDrawer = ()=>{
        set_visibleRequestPlanDrawer(!visibleRequestPlanDrawer)
    }

    const onRequestDrawerClose = () => {
        set_visibleRequestPlanDrawer(false);
    };

    const handleDoneSendRequest =()=>{
        set_visibleRequestPlanDrawer(false);
        getPlanList()
    }



    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_visibleChatTraineeDrawer(false);
        getTrainee()
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };

    const removeFromPlanList = (i)=>{
        let p = plans.filter(p=>p._id!==i._id)
        set_plans([])
        set_plans(p)
    }

    return(
        <div className="fix-top-sec">
            <div className="fix-content">
                <DumbbellHeader back={true} title={"برنامه ها"}/>

                <div className="plan-list-header-v2">

                    <div className="plan-list-header-info">
                        {trainee.first_name?<span className="name">{trainee?.first_name+" "+trainee?.last_name}</span>:null}
                        {trainee.mobile?<span className="mobile">{trainee.mobile}</span>:null}
                    </div>
                    <div className="plan-list-header-image">
                        {trainee.image?
                            <img src={Setup.filePoint+trainee.image} alt=""/>:
                            <img  src={defaultUserIcon} alt=""/>

                        }

                    </div>

                </div>

                <div className="actions-v2">
                    <div></div>
                    <div className="dumbbell-header-plan-card-actions">
                        <div className="dumbbell-trainee-card-message" onClick={e=>{
                            showChatDrawer()
                        }}>
                            <img src={messageIcon} alt=""/>
                            {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}
                        </div>

                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showBoxDrawer()
                        }}>
                            <img src={picIcon} alt=""/>
                        </div>

                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showProfileDrawer()
                        }}>
                            <img src={profileIcon} alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="plan-list">
                {plans.map(p=>{
                    return(
                        <PlanCard removeFromPlanList={removeFromPlanList} trainee={trainee} traineeId={traineeId} plan={p} getPaginationPlanList={getPaginationPlanList}/>
                    )
                })}
            </div>


            <div className="float-add-btn-v2 clickable" onClick={showRequestDrawer}>
                {/*<span>شاگرد جدید</span>*/}
                <img src={plusWhiteIcon} alt=""/>
            </div>
            {/*Chat Trainee*/}
            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onRequestDrawerClose} visible={visibleRequestPlanDrawer}>
                <DumbbellRequestPlan traineeId={traineeId} handleDoneSendRequest={handleDoneSendRequest}/>
            </Drawer>



            {/*Box Trainee*/}
            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                {visibleBoxTraineeDrawer?<DumbbellBoxTrainer visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={trainee} />:null}
            </Drawer>

            <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                {visibleChatTraineeDrawer?<DumbbellChatTrainer visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={trainee} />:null}
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                <DumbbellAddTrainee fill={trainee}/>
            </Drawer>
        </div>
    )
}

export default DumbbellPlanList;
