import React,{useEffect} from "react";
import "./Ipg.scss";
import {useLocation} from "react-router";

function Ipg(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("authority");
    const callback=new URLSearchParams(search).get("callback");

    useEffect(()=>{
        console.log("aut : ",authority)
    },[])

    const successPay = ()=>{
        window.open(`${callback}?Authority=${authority}&Status=OK`)
    }
    const failedPay = ()=>{

    }

    return(
        <div className="ipg-container">
            <span className="success-pay clickable" onClick={successPay}>پرداخت موفق </span>
            <span className="failed-pay clickable">پرداخت ناموفق </span>
        </div>
    )
}

export default Ipg