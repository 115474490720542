import React from "react";

export const NotificationContext = React.createContext();
export const NotificationDispatchContext = React.createContext();


function NotificationProvider({children}) {
    const [state,setstate] = React.useState([])
    return(
        <NotificationContext.Provider value={state}>
            <NotificationDispatchContext.Provider value={setstate} >
                {children}
            </NotificationDispatchContext.Provider>
        </NotificationContext.Provider>
    )
}

export {NotificationProvider};
