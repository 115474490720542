import React,{useEffect,useState} from 'react';
import "./DumbbellInvoice.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {loginApi, payInvoiceApi, viewInvoiceApi} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory, useParams} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";

function DumbbellInvoice(){
    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    let {planId} = useParams()




    const [mobile,set_mobile] = useState("")
    const [spin,set_spin] = useState(false)
    const [data,set_data] = useState({})


    useEffect(()=>{
       if(planId)
           getInvoice()
    },[])

    const getInvoice = async ()=>{
        try{
            const resp = await viewInvoiceApi(planId)
            set_data(resp.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const pay = async ()=>{
        set_spin(true)
        try{
            let resp = await payInvoiceApi(planId)
            window.open(resp.data.data.url, '_blank');
            set_spin(false)
            // history.push("/verify-trainer",{mobile})
        }catch (e) {
            set_spin(false)
            return message.error({
                content: "مشکل در اتصال به درگاه",
                className: 'toast-class'
            });
        }
    }


    return(
        <div className="dumbbell-login-container">

            <div className="dumbbell-invoice-page">

                <div className="dumbbell-invoice-page-title">
                    <span>پرداخت هزینه برنامه</span>
                </div>

                {data.plan?<div className="dumbbell-invoice-page-form">

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{data?.plan?.title}</span>
                        <span className="dumbbell-invoice-page-form-label">عنوان برنامه </span>
                    </div>

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{data?.trainer?.first_name+" "+data?.trainer?.last_name}</span>
                        <span className="dumbbell-invoice-page-form-label">مربی</span>
                    </div>

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{data?.trainee?.first_name+" "+data?.trainee?.last_name}</span>
                        <span className="dumbbell-invoice-page-form-label">شاگرد</span>
                    </div>

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{data?.plan?.delivery_estimate} روز</span>
                        <span className="dumbbell-invoice-page-form-label">حداکثر زمان تحویل</span>
                    </div>

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{data?.plan?.duration} روز</span>
                        <span className="dumbbell-invoice-page-form-label">طول مدت برنامه</span>
                    </div>


                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{numberWithCommas(data?.plan?.amount)}  تومان</span>
                        <span className="dumbbell-invoice-page-form-label">مبلغ برنامه</span>
                    </div>

                    <div className="dumbbell-invoice-page-form-row">
                        <span className="dumbbell-invoice-page-form-value">{numberWithCommas(data?.plan?.discount)}  تومان</span>
                        <span className="dumbbell-invoice-page-form-label">تخفیف برنامه </span>
                    </div>

                </div>:null}

                <div className="dumbbell-invoice-page-payable">
                    <span className="dumbbell-invoice-page-payable-value">{numberWithCommas(data?.plan?.payable_amount)}  تومان</span>
                    <span className="dumbbell-invoice-page-payable-label">مبلغ قابل پرداخت</span>
                </div>

            </div>

            {data?.plan?.pay_status==="payed"?
            <div className="payed-label">
                <span>پرداخت شده</span>
            </div>:null
            }


            {data?.plan?.pay_status!=="payed"?
                <Spin spinning={spin}>
                    <div className="dumbbell-login-btn" onClick={pay}>
                        <span>پرداخت</span>
                    </div>
                </Spin>
                :null}

        </div>
    )
}

export default DumbbellInvoice;
