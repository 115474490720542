import React from "react";

export const LoadingContext = React.createContext();
export const LoadingDispatchContext = React.createContext();


function LoadingProvider({children}) {
    const [state,setstate] = React.useState(false)
    return(
        <LoadingContext.Provider value={state}>
            <LoadingDispatchContext.Provider value={setstate} >
                {children}
            </LoadingDispatchContext.Provider>
        </LoadingContext.Provider>
    )
}

export {LoadingProvider};
