import React, {useEffect, useState} from 'react';
import "./DumbbellLandingPage.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import landImage from "../../assets/dumbbell_image/land-image.png";
import {Collapse, Drawer, Space} from 'antd';
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellLandingPageAddTraineeDrawer
    from "../DumbbellLandingPageAddTraineeDrawer/DumbbellLandingPageAddTraineeDrawer";
import {useParams} from "react-router";
import {getLandApi} from "../../Services/Services";
import {numberWithCommas} from "../../Services/utils";
import {Setup} from "../../Services/Setup";
import { v4 as uuidv4 } from 'uuid';
import DumbbellBookingLand from "../DumbbellBookingLand/DumbbellBookingLand";
import ReactPlayer from "react-player";
// import {SoundOutlined,MutedOutlined} from '@ant-design/icons';
import {SoundOutlined,NotificationOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const GenExtra = (title,price) => {
    return(
            <div className="dumbbell-land-plan-item" >
                <span className="price">{`${price}  تومان`}</span>
                <span className="title">{title}</span>
            </div>
    )
};



function DumbbellLandingPage(){

    const {slug} = useParams()

    //Add Trainee Drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)
    const [selectedPlan,set_selectedPlan] = useState({})

    const showMenu = (p,index)=>{
        set_selectedPlan({...p,index:index})
        set_visibleTraineeDrawer(!visibleTraineeDrawer)
    }

    const onMenuClose = () => {
        set_visibleTraineeDrawer(false);
    };


    //plans drawer
    const [visiblePlansDrawer,set_visiblePlansDrawer] = useState(false)

    const showPlansDrawer = (p)=>{
        set_visiblePlansDrawer(!visiblePlansDrawer)
    }

    const onPlansDrawerClose = () => {
        set_visiblePlansDrawer(false);
    };


    //booking drawer
    const [visibleBookingDrawer,set_visibleBookingDrawer] = useState(false)

    const showBookingDrawer = (p)=>{
        set_visibleBookingDrawer(!visibleBookingDrawer)
    }

    const onBookingDrawerClose = () => {
        set_visibleBookingDrawer(false);
    };



    const [full_name,set_full_name] = useState("");
    const [image,set_image] = useState(null);
    const [video,set_video] = useState(null);
    const [mute,set_mute] = useState(true);
    const [descriptionInput,set_descriptionInput] = useState("");
    const [descriptions,set_descriptions] = useState([]);

    const [instagram,set_instagram] = useState("");
    const [whatsApp,set_whatsApp] = useState("");
    const [telegram,set_telegram] = useState("");
    const [website,set_website] = useState("");
    const [email,set_email] = useState("");
    const [phone,set_phone] = useState("");


    const [plans,set_plans] = useState([]);

    useEffect(()=>{
        getLand()
    },[])

    const [page,set_page] = useState(null)
    const getLand =async ()=>{
        try{
            const resp = await getLandApi(slug)
            set_page(resp.data.data)
            if(resp.data.data.data)
                fillPage(resp.data.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const fillPage = (data)=>{

        set_image(data.image)
        set_video(data.video)
        set_full_name(data.full_name)
        set_descriptions(data.descriptions)
        set_instagram(data.instagram)
        set_whatsApp(data.whatsApp)
        set_telegram(data.telegram)
        set_website(data.website)
        set_email(data.email)
        set_phone(data.phone)
        set_plans(data.plans)

    }


    return(
        <div className="dumbbell-land-container">
            {/*<DumbbellHeader backColor={"#8A86FE"} user={false}/>*/}

            {video?<div className="dumbbell-land-image">
                <ReactPlayer
                    playsinline={true}
                    playing={true}
                    volume={0.7}
                    controls={false}
                    muted={mute}
                    width={"100%"}   url={Setup.filePoint+video+"?id="+uuidv4()}  />
            </div>:null}

            <br/>

            <div className="muted-sec clickable" onClick={e=>{
                set_mute(!mute)
            }}>
                {!mute?
                    <SoundOutlined />:
                    <NotificationOutlined />
                }
            </div>
            <div className="dual-btns">
                <div className="dual-btn clickable booking-btn" onClick={e=>{
                    showBookingDrawer()
                }}>
                    <span>گرفتن وقت حضوری</span>
                </div>
                <div className="dual-btn clickable train-btn" onClick={e=>{
                    showPlansDrawer()
                }}>
                    <span>گرفتن برنامه</span>
                </div>
            </div>

            <div className="dumbbell-land-name">
                <span>{full_name}</span>
            </div>

            <div className="dumbbell-land-trainer-desc">

                {descriptions.map(d=>{
                    return(
                        <div className="dumbbell-land-trainer-desc-item">
                            <span className="item-text">{d.text}</span>
                            <span className="item-bullet"></span>
                        </div>
                    )
                })}


            </div>




            <div className="social-wrapper">
                <div className="social-list">

                    <div className="social-item clickable">
                        <span>{instagram}</span>
                    </div>

                    <div className="social-item clickable">
                        <span>{telegram}</span>
                    </div>

                    <div className="social-item clickable">
                        <span>{whatsApp}</span>
                    </div>
                </div>
            </div>

            <Drawer width={300}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="right" onClose={onPlansDrawerClose} visible={visiblePlansDrawer}>

                <div className="dumbbell-land-plan-list">

                    {plans.map((p,index)=>{
                        return(
                            <Collapse collapsible="header" >
                                <Panel header={GenExtra(p.title+`(${p.duration} روزه) `,numberWithCommas(p.cost))}  key={index+1} showArrow={false}>

                                    <div className="dumbbell-land-plan-desc">

                                        {p.descriptions.map(d=>{
                                            return(
                                                <div className="dumbbell-land-plan-desc-item">
                                                    <span className="desc-text">{d.text}</span>
                                                    <span className="desc-bullet"></span>
                                                </div>
                                            )
                                        })}

                                        <div className="dumbbell-land-plan-desc-item">
                                            <span className="desc-text">زمان تحویل برنامه {p.delivery_time} روز </span>
                                            <span className="desc-bullet"></span>
                                        </div>
                                    </div>

                                    <div className="dumbbell-land-plan-desc-btn">
                                    <span onClick={e=>{
                                        showMenu(p,index)
                                    }}>گرفتن برنامه</span>
                                    </div>

                                </Panel>
                            </Collapse>
                        )
                    })}



                </div>


                {/*Add New Trainee*/}
                <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onMenuClose} visible={visibleTraineeDrawer}>
                    <DumbbellLandingPageAddTraineeDrawer plan={selectedPlan} slug={slug} trainerName={full_name} handleDoneAddTrainee={""} />
                </Drawer>

            </Drawer>



            <Drawer width={300}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="right" onClose={onBookingDrawerClose} visible={visibleBookingDrawer}>
                {page?.trainer_id?
                    <DumbbellBookingLand trainerId={page.trainer_id} />:
                    null
                }
            </Drawer>


        </div>


    )
}

export default DumbbellLandingPage;
