import React from "react";

export const UserContext = React.createContext();
export const UserDispatchContext = React.createContext();


function UserProvider({children}) {
    const [state,setstate] = React.useState(null)
    return(
        <UserContext.Provider value={state}>
            <UserDispatchContext.Provider value={setstate} >
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    )
}

export {UserProvider};
