import React from "react";

export const ErrorContext = React.createContext();
export const ErrorDispatchContext = React.createContext();


function ErrorProvider({children}) {
    const [state,setstate] = React.useState({status:"error",message:"",show:false})
    return(
        <ErrorContext.Provider value={state}>
            <ErrorDispatchContext.Provider value={setstate} >
                {children}
            </ErrorDispatchContext.Provider>
        </ErrorContext.Provider>
    )
}

export {ErrorProvider};
