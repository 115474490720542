import React,{useEffect} from "react"
import {useLoading} from "../../Hook/useLoading";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

const Loading = ()=>{
    const loader = useLoading()
    return(
        <>
            {loader?
                <div className="loading">
                    <span style={{width:50,height:50,border:"2px solid #8d89fd ", borderBottomColor: "transparent"}} className="loader"></span>
                    <span></span>
                </div>:null
            }
            </>
    )
}
export default Loading;