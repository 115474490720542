import React, {useEffect, useState} from 'react';
import "./DumbbellVerify.scss";
import loginImage from  "../../assets/dumbbell_image/V2/login_image.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import keyIcon from  "../../assets/dumbbell_image/key_icon.png";
import {useHistory, useLocation} from "react-router";
import {setTokenToLocalStorage, setUserToLocalStorage, validation} from "../../Services/utils";
import {message} from "antd";
import {loginApi, traineeVerifyApi, verifyApi} from "../../Services/Services";
import hintIcon from "../../assets/dumbbell_image/V2/hint_icon_v2.png";
import caretIcon from "../../assets/dumbbell_image/V2/caret_icon_v2.png";
import mobileIcon from "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import spinnerIcon from "../../assets/dumbbell_image/V2/spinner_icon.gif";
import VerificationInput from "react-verification-input";




function DumbbellVerify(){

    const history = useHistory()
    const location = useLocation()


    const [mode,set_mode] = useState("mobile")
    const [email,set_email] = useState("")
    const [mobile,set_mobile] = useState("")
    const [code,set_code] = useState("")
    const [spin,set_spin] = useState(false)

    const [counter,set_counter] = useState(60)

    const handleChangeOtp = (e)=>{
        console.log(e)
        set_code(e)
    }


    const modeName = ()=>{
        return mode==="mobile"?"موبایل":"ایمیل"
    }


    const resendCode = async ()=>{
        set_spin(true)

        if(mode==="mobile" && !validation("MOBILE",mobile)){
            set_spin(false)
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(mode==="email" && !validation("EMAIL",email)){
            set_spin(false)
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }

        try{
            await loginApi({mobile,login_mode:mode,email})
            message.success({
                content: `کد تایید به شماره ${modeName()} شما ارسال شد`,
                className: 'toast-class'
            });
            set_spin(false)
            runCountDown()
        }catch (e) {
            set_spin(false)
            return message.error({
                content: " مورد پذیرش نمیباشد",
                className: 'toast-class'
            });
        }



    }
    const onComplete = (e)=>{
        console.log("complete : " ,e)
        login(e)
    }


    useEffect(()=>{
        document.body.style.backgroundColor = "white";
    },[])

    useEffect(()=>{
        if(location?.state?.mobile || location?.state?.email){
            set_mobile(location.state.mobile)
            set_email(location.state.email)
            set_mode(location.state.mode)
            runCountDown()
        }
    },[])


    const runCountDown = ()=>{
        set_counter(60)
        setInterval(()=>{
            set_counter(c=>c-1)
        },1000)
    }

    const login = async (c)=>{
        set_spin(true)
        // if(!validation("MOBILE",mobile)){
        //     set_spin(false)
        //     return message.error({
        //         content: "فرمت موبایل درست نمیباشد",
        //         className: 'toast-class'
        //     });
        // }

        if(!validation("VERIFY_TOKEN",c)){
            set_spin(false)
            return message.error({
                content: "کد اشتباه است",
                className: 'toast-class'
            });
        }

        try{
            let res = await verifyApi({mobile,code:c,login_mode:mode,email})
            setTokenToLocalStorage(res.data.data.accessToken)
            setUserToLocalStorage(res.data.data.trainer)
            set_spin(false)
            history.push(`/my-step`)
        }catch (e) {
            set_spin(false)
            return message.error({
                content: "کد اشتباه است",
                className: 'toast-class'
            });
        }



    }


    return(
        <div className="dumbbell-login-container">

            {/*<div className="dumbbell-login-image">*/}
            {/*    <img src={loginImage} alt=""/>*/}
            {/*</div>*/}

            {/*<div className="dumbbell-login-logo">*/}
            {/*    <img src={logoWhite} alt=""/>*/}
            {/*</div>*/}

            <div className="v2-dumbbell-login-image" style={{ backgroundImage:`url(${loginImage})`}}>


                <div className="v2-dumbbell-login-logo">
                    <h3>STEP</h3>
                    <h6>The future  of  Planing</h6>
                    <span>Ali Pakzadi - The best coach of the month</span>
                    <img className="login-hint-icon clickable" src={hintIcon} alt=""/>
                </div>

                <div className="v2-dumbbell-login-image-wrapper"></div>

            </div>

            <div className="v2-dumbbell-login-form">


                <span className="v2-dumbbell-login-form-title">کد تایید را وارد نمایید</span>

                <div className="key-sec">
                    <img src={keyIcon} alt=""/>
                    <div className="key-main">
                        <VerificationInput
                            autoFocus={true}
                            placeholder=""
                            classNames={{
                                container: "container",
                                character: "character",
                                characterInactive: "character--inactive",
                                characterSelected: "character--selected",
                                characterFilled: "character--filled",
                            }}
                            length={4}
                            onComplete={onComplete}
                            onChange={handleChangeOtp}
                            validChars="0-9" inputProps={{ inputMode: "numeric" }} />
                    </div>
                </div>



                <div className="v2-dumbbell-login-btn clickable" onClick={e=>{
                    login(code)
                }}>
                    {spin?
                        <span style={{width:20,height:20,border:"1.5px solid #ffffff", borderBottomColor: "transparent"}} className="loader"></span>:
                        <span>ورود</span>

                    }
                </div>
                <div className="v2-dumbbell-login-links">


                    <div className="v2-dumbbell-login-links-item">
                        {counter>0?
                            <div className="v2-dumbbell-login-links-item-resend">
                                <span>{counter}</span>
                            </div>:null
                        }
                        {counter<1?
                            <span className="v2-dumbbell-login-links-label clickable" onClick={e=>{
                                resendCode()
                            }}>ارسال دوباره</span>:null
                        }
                    </div>

                    <div className="v2-dumbbell-login-links-item" onClick={e=>{
                        history.goBack()
                    }}>
                        <span className="v2-dumbbell-login-links-label clickable">تعویض شماره تلفن</span>
                    </div>



                </div>

            </div>

            {/*<div className="dumbbell-login-input-sec">*/}
            {/*    <div className="dumbbell-login-input">*/}
            {/*        <img src={keyIcon} alt=""/>*/}
            {/*        <input*/}
            {/*            onKeyPress={e=>{if(e.key==="Enter"){login()}}}*/}
            {/*            value={code}*/}
            {/*            onChange={e=>set_code(e.target.value)}*/}
            {/*            type="text" placeholder="کد تایید"/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="dumbbell-login-btn" onClick={login}>*/}
            {/*    <span>ورود</span>*/}
            {/*    <div onClick={e=>{*/}
            {/*        history.goBack();*/}
            {/*    }} className="dumbbell-login-return-link">اصلاح شماره موبایل</div>*/}
            {/*</div>*/}



            <div className="dumbbell-login-copyright">
                <span>copywrite@2024  - all right reserved</span>
            </div>

        </div>
    )
}

export default DumbbellVerify;
