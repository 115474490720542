import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellChatTrainee.scss";
import "../DumbbellChatTrainer/DumbbellChatTrainer.scss";
import sampleFace from "../../assets/images/default_user.png"
import {
    traineeChatListApi, traineeChatSeenMessageApi, traineeChatSendMessageApi,
    traineeListApi,
    trainerChatListApi,
    trainerChatSeenMessageApi,
    trainerChatSendMessageApi
} from "../../Services/Services";
import moment from "moment-jalaali";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import {useHistory} from "react-router";
import {message} from "antd";

let page = 0;
let triggerID = ""
let bodyId = ""

function    MessageCard({getPaginationMessages,item,seen,init,set_init}){

    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [currentSeen,set_currentSeen] = useState(item.seen);
    const [pageSeen,set_pageSeen] = useState(false);
    useEffect(()=>{
        // console.log("ttttt :",triggerID,bodyId,init,pageSeen,item._id)
        // if(isVisible && !pageSeen && triggerID===item._id){
        //     console.log(item._id,item.body,"is vis",isVisible)
        //     if(init){
        //         console.log("innnnnnnnnnnnnnnnn")
        //         set_pageSeen(true)
        //         page = page+1
        //         getPaginationMessages()
        //     }else {
        //         set_init(true)
        //     }
        //
        // }

        if(!currentSeen && isVisible && item.sender==="trainer"){
            console.log(item._id,"seen",isVisible)
            set_currentSeen(true)
            seen(item._id)
            //api call
        }

    },[isVisible])

    return(
        <div>
            {item.sender==="trainee"?
                <div className="dumbbell-chat-body-me-wr " ref={ref}>
                    <div className="dumbbell-chat-body-me-card">
                        <div className="dumbbell-chat-body-text">
                            <p>{item.body}</p>
                        </div>
                        <div className="dumbbell-chat-body-date">
                            <span>{moment(item.createdAt).format('jYYYY/jMM/jDD HH:mm')}</span>
                        </div>
                    </div>
                </div>:null
            }
            {item.sender==="trainer"?<div className="dumbbell-chat-body-another-wr" ref={ref}>
                <div className="dumbbell-chat-body-another-card">
                    <div className="dumbbell-chat-body-text">
                        <p>{item.body}</p>
                    </div>
                    <div className="dumbbell-chat-body-date">
                        <span>{moment(item.createdAt).format('jYYYY/jMM/jDD HH:mm')}</span>
                    </div>
                </div>
            </div>:null}



        </div>
    )
}




function DumbbellChatTrainee({trainee,visibleChatTraineeDrawer}){


    useEffect(()=>{
        page = 0;
        getMessages()
    },[])



    const [messages,set_messages] = useState([])
    const [init,set_init] = useState(false)

    const getMessages = async ()=>{
        set_messages([])
        let elm = document.querySelector(".ant-drawer-body")
        elm.scrollTo({ top:  elm.scrollHeight, behavior: 'smooth' })
        try{
            const res =  await traineeChatListApi(0,trainee._id);
            let messages = res.data.data
            messages.reverse()
            triggerID = messages.length>10?messages[2]._id:""
            bodyId = messages.length>10?messages[2].body:""
            set_messages(messages)
            let elm = document.querySelector(".ant-drawer-body")
            elm.scrollTo({ top:  elm.scrollHeight, behavior: 'smooth' })
        }catch(e){
            console.log(e)
        }
    }

    const getPaginationMessages = async ()=>{
        try{
            const res =  await traineeChatListApi(page,trainee._id);
            let messages = res.data.data
            messages.reverse()
            triggerID = messages.length>10?messages[2]._id:""
            bodyId = messages.length>10?messages[2].body:""
            set_messages(oldArray => [...messages,...oldArray])
            let elm = document.querySelector(".ant-drawer-body")
            elm.scrollTo({ top:  80*14 })
        }catch(e){
            console.log(e)
        }
    }

    const seen = async (id)=>{
        try{
            await traineeChatSeenMessageApi(id);
        }catch(e){
            console.log(e)
        }
    }


    const [input,set_input] = useState("")
    const sendMessage = async ()=>{
        try{
            if(input.length>0){
                try{
                    let res = await traineeChatSendMessageApi(trainee._id,input)
                    set_messages(oldArray => [...oldArray,res.data.data])
                    let elm = document.querySelector(".ant-drawer-body")
                    elm.scrollTo({ top:  elm.scrollHeight, behavior: 'smooth' })
                    set_input("")
                }catch (e) {
                    console.log(e)
                    if(e.response.data.message==="not_active_plan"){
                        return message.error({
                            content: "شما برنامه فعالی با این مربی ندارید",
                            className: 'toast-class'
                        });
                    }
                }
            }
        }catch (e) {
            console.log()
        }
    }


    return(
        <div className="dumbbell-chat-container">

            <div className="dumbbell-chat-header-container">
                <div className="dumbbell-chat-header-container-info">
                    <span className="name">{trainee?.first_name+" "+trainee?.last_name}</span>
                    <span className="mobile">{trainee?.mobile}</span>
                </div>
                <div className="dumbbell-chat-header-container-img">
                    <img src={sampleFace} alt=""/>
                </div>
            </div>


            <div className="dumbbell-chat-body">

                {triggerID.length>0?<div className="dumbbell-chat-body-more" onClick={e=>{
                    page = page+1
                    getPaginationMessages()
                }}>
                    <span>دیدن پیامهای قبلی</span>
                </div>:null}

                {messages.map(m=>{
                    return(
                        <MessageCard init={init} set_init={set_init} seen={seen} getPaginationMessages={getPaginationMessages} item={m}/>
                    )
                })}

            </div>

            <div className="dumbbell-chat-message-box-wr">
                <div className="dumbbell-chat-message-box">
                    <input
                        value={input}
                        onKeyPress={e=>{if(e.key==="Enter"){sendMessage()}}}
                        onChange={e=>set_input(e.target.value)}
                        type="text" name="" id=""/>
                    <span  onClick={sendMessage}>ارسال</span>
                </div>
            </div>


        </div>
    )
}

export default DumbbellChatTrainee;
