import moment from "moment-jalaali";

const Regex ={
    // mobile : /^(\+98|0)?9\d{9}$/,
    verify_code :/^[0-9]{4}$/,
    number :/^[0-9]$/,
    mobile :/^09[0|1|2|3][0-9]{8}$/,
    email:/^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const validation = (type,val)=>{

    if(type === "MOBILE"){
        return Regex.mobile.test(val);
    }

    if(type === "NUMBER"){
        return Regex.number.test(val);
    }

    if(type === "EMAIL"){
        return Regex.email.test(val);
    }

    if(type === "VERIFY_TOKEN"){
        return Regex.verify_code.test(val);
    }

}


export const setTokenToLocalStorage = (token)=>{
    localStorage.setItem("token",token)
}
export const setUserToLocalStorage = (user)=>{
    localStorage.setItem("user",JSON.stringify(user))
}

export const clearLocalStorage = ()=>{
    localStorage.clear()
}

export function numberWithCommas  (x) {
    //
    // console.log("X ",x)

    // r= r.toString()
    //
    // r= r.replaceAll(",", "");
    //
    // console.log("X ",r)

    //return x

    let r =""
    if(x){
        r = x.toString()
        r= r.replaceAll(",", "");
        return r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return 0

}

export const convertMonthNumberToName = (num)=>{
    switch (num) {
        case "01":
            return "فرودرین"
            break;
        case "02":
            return "اردیبهشت"
            break;
        case "03":
            return "خرداد"
            break;
        case "04":
            return "تیر"
            break;
        case "05":
            return "مرداد"
            break;
        case "06":
            return "شهریور"
            break;
        case "07":
            return "مهر"
            break;
        case "08":
            return "آبان"
            break;
        case "09":
            return "آذر"
            break;
        case "10":
            return "دی"
            break;
        case "11":
            return "بهمن"
            break;
        case "12":
            return "اسفند"
            break;

    }

}
export function englishNumber(string) {
    return string.split('۰').join('0')
        .split('۱').join('1')
        .split('۲').join('2')
        .split('۳').join('3')
        .split('۴').join('4')
        .split('۵').join('5')
        .split('۶').join('6')
        .split('۷').join('7')
        .split('۸').join('8')
        .split('۹').join('9')
}


export const convertMonthIntNumberToName = (num)=>{
    switch (num) {
        case 1:
            return "فرودرین"
            break;
        case 2:
            return "اردیبهشت"
            break;
        case 3:
            return "خرداد"
            break;
        case 4:
            return "تیر"
            break;
        case 5:
            return "مرداد"
            break;
        case 6:
            return "شهریور"
            break;
        case 7:
            return "مهر"
            break;
        case 8:
            return "آبان"
            break;
        case 9:
            return "آذر"
            break;
        case 10:
            return "دی"
            break;
        case 11:
            return "بهمن"
            break;
        case 12:
            return "اسفند"
            break;

    }

}


export const checkPlanEndDate = (plan)=>{
    let duration = plan.duration;
    let startAt = plan.startAt; // 1403/03/11
    if(!startAt)
        return false

    let end_date = moment(startAt,"jYYYY/jMM/jDD").add(duration,'days');
    let end = moment(end_date).unix()
    let now = parseInt((new Date().getTime()/1000).toFixed())
    return (end-now)<=0
}

export const checkFileSize = (size,limit)=>{
    let fileSize = Math.floor(size/1000000)
    return fileSize<=limit
}