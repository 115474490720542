import React, {useState,useEffect} from 'react';
import "./DumbbellHederTrainee.scss";
import menuIcon from  "../../assets/dumbbell_image/V2/menu_icon_v2.svg";
import bellIcon from  "../../assets/dumbbell_image/V2/bell_icon_v2.svg";
import backIcon from  "../../assets/dumbbell_image/V2/back_icon.svg";
import {Drawer} from "antd";
import DumbbellAddTrainee from "../../Pages/DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellTrainerMenu from "../../Pages/DumbbellTrainerMenu/DumbbellTrainerMenu";
import {useHistory} from "react-router";
import mobileIcon from "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import {getNotificationsCount_api} from "../../Services/Services";
import DumbbellTraineeMenu from "../../Pages/DumbbellTraineeMenu/DumbbellTraineeMenu";


function DumbbellHeader({changeFilter=e=>{},user=null,back=false,notification=false,title=null}){

    const history = useHistory()

    //Add Trainee Drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)

    const showMenu = ()=>{
        set_visibleTraineeDrawer(!visibleTraineeDrawer)
    }

    const onMenuClose = () => {
        set_visibleTraineeDrawer(false);
    };



    const goBack = (t)=>{
        console.log("ssss :)")
        history.goBack()
        // onBottomDrawerClose()
    }



    return(
        <div className="dumbbell-header-container" >

            {back?<div className="dumbbell-header-item clickable" onClick={goBack}>
                <img src={backIcon} alt=""/>
            </div>:<div></div>}

            <div className="dumbbell-header-item">
                <span>{title?title:""}</span>
            </div>

            <div className="dumbbell-header-item clickable" onClick={showMenu}>
                <img src={menuIcon} alt=""/>
            </div>

            <Drawer destroyOnClose={true} bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}}  width={300} closable={false}  onClose={onMenuClose} visible={visibleTraineeDrawer}>
                <DumbbellTraineeMenu/>
            </Drawer>


        </div>
    )
}

export default DumbbellHeader;
