import React, {Fragment, useEffect, useState} from 'react';
import "./DumbbellCallbackBookingLand.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {
    addTraineeApi, bookingAuthorityVerifyApi, completeRequestApi,
    landVerifyInvoiceApi,
    landViewInvoiceAuthorityApi, landViewInvoiceRequestApi,
    loginApi,
    verifyInvoiceApi,
    viewInvoiceApi,
    viewInvoiceAuthorityApi
} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory,useLocation,useParams} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";

function DumbbellCallbackBookingLand(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("Authority");
    const status=new URLSearchParams(search).get("Status");

    const {requestId} = useParams()

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    const [spin,set_spin] = useState(true)
    const [mode,set_mode] = useState("default") //default - invoice - failed - success
    const [data,set_data] = useState({})
    const [turn,set_turn] = useState({})

    useEffect(()=>{
        console.log(authority)
        console.log(status)
        getInvoice()

    },[])


    const [compMode,set_compMode] = useState("default")

    const getInvoice = async ()=>{
        let memberId = requestId.split('-')[1]
        let resp = null;
        try{
                resp = await bookingAuthorityVerifyApi(authority,memberId)
                set_data(resp.data.data)
                set_turn(resp.data.data.member)
                set_mode("success")
                set_spin(false)
        }catch (e) {
            set_spin(false)
            set_mode("failed")
            console.log(e)
        }
    }



    return(
        <div className="dumbbell-login-container">



            <Spin spinning={spin}>

                <div className="dumbbell-login-default">

                    <div className="dumbbell-invoice-page callback-page" style={{paddingBottom:20,paddingTop:20}}>

                        <div className="dumbbell-invoice-page-title">
                            <span>{mode==="success"?"پرداخت شد":""}</span>
                            <span>{mode==="failed"?"پرداخت ناموفق":""}</span>
                        </div>






                        {mode==="success"?
                            <Fragment>
                                <div className="dumbbell-callback-message-success">
                                    <span className="dumbbell-callback-message-title">پرداخت شما با موفقیت انجام شد</span>
                                    <span className="dumbbell-callback-message-text">{`رزرو شما در تاریخ ${moment(turn.date).format(' jYYYY/jMM/jDD ')} در بازه ${turn.startAt} تا ${turn.endAt} با موفقیت انجام شد`}</span>
                                </div>
                            </Fragment>
                            :null
                        }

                        {mode==="failed"?<div className="dumbbell-callback-message-failed">
                            <span className="dumbbell-callback-message-title">پرداخت ناموفق</span>
                        </div>:null}


                    </div>


                </div>


            </Spin>




        </div>
    )
}

export default DumbbellCallbackBookingLand;
