import React from "react";

export const UploadLoadingContext = React.createContext();
export const UploadLoadingDispatchContext = React.createContext();


function UploadLoadingProvider({children}) {
    const [state,setstate] = React.useState({visible:false,percent:0,title:'در حال آپلود  . . .'})
    return(
        <UploadLoadingContext.Provider value={state}>
            <UploadLoadingDispatchContext.Provider value={setstate} >
                {children}
            </UploadLoadingDispatchContext.Provider>
        </UploadLoadingContext.Provider>
    )
}

export {UploadLoadingProvider};
