import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellMyRequests.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/V2/search_icon_v2.svg"
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg"
import chatIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg"
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg"
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg"
import loadingGif from "../../assets/dumbbell_image/V2/page_spinner_v2.gif"

import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png";
import loadingImage from "../../assets/loading/load_2.gif"
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useWindowDimensions from "../../Hook/useWindowDimensions";
import {Drawer, message, Spin} from "antd";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import {useHistory, useLocation, useParams} from "react-router";
import {
    bookSeenApi,
    changeStatus_api, changeStatusFromBook_api,
    deleteTraineeApi,
    getMediasApi, getTrainerProfileApi, getUnreadMessage_api,
    mediaChallengeDeleteApi, mediaDeleteApi,
    mediaRedDeleteApi, planSeenApi, requestsPlan_api, requestsTurn_api,
    seenMediaApi,
    traineeListApi
} from "../../Services/Services";
import defaultUser from "../../assets/images/default_user.png";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";

import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import {checkPlanEndDate, convertMonthNumberToName, numberWithCommas} from "../../Services/utils";
import {Collapse} from "react-collapse";
import sampleFace from "../../assets/images/default_user.png";

let page = 0;
let search = "";
let type = "all";
let triggerID = "";




function PlanCard({p,getPaginationPlanList}){

    const [collapseCard,set_collapseCard] = useState(false)
    const [planIsEnd,set_planIsEnd] = useState(false);
    //
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(p.seen);

    useEffect(()=>{
        set_planIsEnd(checkPlanEndDate(p))
    },[])

    const seen = async (id)=>{
        try{
            await planSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===p._id){
            console.log(p._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationPlanList()
        }
        if(!currentSeen && isVisible ){
            console.log(p._id,"seen",isVisible)
            set_currentSeen(true)
            seen(p._id)
            //api call
        }
    },[isVisible])

    return(
        <>
            {p.trainee?
                <div className="plan-card-v2-main" ref={ref} >
                    <div className="plan-card-fix-row clickable" onClick={e=>{
                        set_collapseCard(!collapseCard)
                    }}>
                        <div className="plan-card-fix-price">
                            <span className="price">{numberWithCommas(p.payable_amount)}</span>
                            <span className="unit">تومان</span>
                        </div>
                        <div className="plan-card-fix-trainee">
                            <span className="trainee-name">{p.trainee.first_name+" "+p.trainee.last_name}</span>
                            <span className="trainee-date">{p.title}</span>
                            <span className="trainee-date">{moment(p.createdAt).format('jYYYY/jMM/jDD HH:mm')}</span>
                        </div>
                    </div>

                    <Collapse isOpened={collapseCard}>
                        <Fragment>

                            <div className="plan-card-progress">

                                <div className="plan-card-progress-station" style={{backgroundColor:"#3195ff"}}>
                    <span className="plan-card-progress-station-date">
                        <span>{moment(p.createdAt).format('jDD')}</span>
                        <span>{convertMonthNumberToName(moment(p.createdAt).format('jMM'))}</span>
                    </span>
                                    <span className="plan-card-progress-station-label">ثبت</span>
                                </div>


                                <div className="plan-card-progress-line" style={p.payedAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}></div>


                                <div className="plan-card-progress-station" style={p.payedAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {p.payedAt?
                            <>
                                <span>{moment(p.payedAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.payedAt).format('jMM'))}</span>
                            </>
                            :""}
                    </span>
                                    <span className="plan-card-progress-station-label">پرداخت</span>
                                </div>

                                <div className="plan-card-progress-line" style={p.deliveredAt?{backgroundColor:"#428fe2"}:{backgroundColor:"##D9D9D9"}}></div>



                                <div className="plan-card-progress-station" style={p.deliveredAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {p.deliveredAt?
                            <>
                                <span>{moment(p.deliveredAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.deliveredAt).format('jMM'))}</span>
                            </>
                            :""}
                        {p.trainee.delivery_date && parseInt(moment(p.trainee.delivery_date).format('jYYYY'))<1500 && !p.deliveredAt?
                            <div className="plan-card-delivery_date">
                                <span>{moment(p.trainee.delivery_date).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.trainee.delivery_date).format('jMM'))}</span>
                            </div>
                            :""}
                    </span>
                                    <span className="plan-card-progress-station-label">تحویل</span>
                                </div>



                            </div>






                            <div className="plan-card-btn" >
                                <span className="clickable" onClick={e=>{
                                    history.push(`/trainer/plan-add/${p.trainee._id}/${p._id}`)
                                }}>{btnStatus(p.status,p.pay_status,planIsEnd)}</span>
                            </div>


                            <div className="plan-card-target-v2">

                                <div className="plan-card-target-title">
                                    <span>هدف برنامه</span>
                                </div>

                                <div className="plan-card-target-desc">
                                    <span>{p.target}</span>
                                </div>

                                <div className="plan-card-actions">
                                    <div>

                                    </div>

                                    {/*<div className="actions-icons">*/}
                                    {/*    <div className="plan-card-actions-item">*/}
                                    {/*        <img src={chatIcon} alt=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="plan-card-actions-item">*/}
                                    {/*        <img src={picIcon} alt=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="plan-card-actions-item">*/}
                                    {/*        <img src={profileIcon} alt=""/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="actions-contact clickable" onClick={e=>{
                                        history.push(`/trainer/plan-list/${p.trainee._id}`)
                                    }}>
                                        <div className="profile-info">
                                            {p.trainee?<span className="mobile-name">{p.trainee.first_name+" "+p.trainee.last_name}</span>:null}
                                            {p.trainee.mobile?<span className="mobile-contact">{p.trainee.mobile}</span>:null}
                                            {p.trainee.email?<span>{p.trainee.email}</span>:null}
                                        </div>
                                        <div className="profile-image">
                                            {p.trainee?.image?<img src={Setup.filePoint+p.trainee?.image} alt=""/>:<img src={sampleFace} alt=""/>}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </Fragment>

                    </Collapse>




                </div>:null
            }
            </>

    )
}

function BookCard({item,getBooks}){

    //default , booked ,accepted , cancel_me , cancel_client , not_come
    const [memberStatus,set_memberStatus] = useState("")
    const [statusSpin,set_statusSpin] = useState(false)

    useEffect(()=>{
        set_memberStatus(item.status)
    },[])


    //
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(item.seen);


    const seen = async (id)=>{
        try{
            await bookSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===item._id){
            console.log(item._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getBooks()
        }
        if(!currentSeen && isVisible ){
            console.log(item._id,"seen",isVisible)
            set_currentSeen(true)
            seen(item._id)
            //api call
        }
    },[isVisible])

    const handleChangeMemberStatus = async (s)=>{
        handleClickCloseBottomDrawer()
        set_statusSpin(true)
        let obj = {
            turnId:item.for,
            linkId:item.link_id,
            status:s
        }
        try{
            await changeStatusFromBook_api(obj)
            set_memberStatus(s)
            getBooks()
            set_statusSpin(false)
        }catch (e) {
            console.log(e)
            set_statusSpin(false)
            message.error("نمیتونی وضعیت و تغییر بدی")
        }
    }

    const bookBackColor = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"
        }

        if(status==="cancel_me"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-me-status"
        }

        if(status==="cancel_client"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-client-status"
        }

        if(status==="accepted"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }

        if(status==="not_come"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }


    const convertStatusToName = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "رزرو شده"
        }

        if(status==="cancel_me"){
            return "کنسل توسط خودم"
        }

        if(status==="cancel_client"){
            return "کنسل توسط مشتری"
        }

        if(status==="accepted"){
            return "پذیرش شده"
        }

        if(status==="not_come"){
            return "مراجعه نکرده"
        }

        return "رزرو شده"

    }



    const [collapseCard,set_collapseCard] = useState(false)
    const [currentStatus,set_currentStatus] = useState(item.status)

    //Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleClickCloseBottomDrawer = (m)=>{
        set_visibleBottomDrawer(false);
    }

    return(
        <Fragment>
            {item.for?
                <Spin spinning={statusSpin}>
                     <div className="dumbbell-booking-mg-booking-item" ref={ref}>

                        <div className="plan-card-fix-row clickable" onClick={e=>{
                            set_collapseCard(!collapseCard)
                        }}>

                            <div className="plan-card-fix-price">
                                <span className="price">{`${item.startAt} - ${item.endAt}`}</span>
                                <span className="unit">{moment(item.date,"YYYY-MM-DD").format("jYYYY/jMM/jDD")}</span>
                            </div>

                            <div className="plan-card-fix-trainee">
                                <span className="trainee-name">{item.first_name+" "+item.last_name}</span>
                                <span className="trainee-date">{item.mobile}</span>
                                <span className={bookBackColor(item.status)}>{convertStatusToName(item.status)}</span>
                            </div>
                        </div>

                        <Collapse isOpened={collapseCard}>
                            <Fragment>

                                <div className="booking-card-price-sec">
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{item.number}</h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>شماره </span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.cost)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>قیمت سرویس</span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.pre_cost)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>قیمت پرداخت شده</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="booking-card-description-sec">
                                    <span>توضیحات</span>
                                    <p>{item.description}</p>
                                </div>

                                <div className="booking-card-price-btn">
                                    <span className="clickable" onClick={showBottomDrawer}>{convertStatusToName(item.status)}</span>
                                </div>

                            </Fragment>
                        </Collapse>


                        <Drawer height={350}
                                bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                                mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                            <div className="bottom-drawer-handle">
                                <span></span>
                            </div>
                            <div className="bottom-drawer-body">
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("booked")
                                }}>
                                    {/*<img src={mobileIcon} alt=""/>*/}
                                    <span className="label">رزرو شده</span>
                                </div>
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("cancel_me")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط خودم</span>
                                </div>

                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("cancel_client")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط مشتری</span>
                                </div>

                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("accepted")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">پذیرش شده</span>
                                </div>
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("not_come")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">مراجعه نکرده</span>
                                </div>
                            </div>
                        </Drawer>


                        {/*<p>{item.member.description}</p>*/}

                    </div>
                </Spin>:null
            }
        </Fragment>
    )
}


function btnStatus(status,pay_status,planIsEnd) {
    if(planIsEnd)
        return "مشاهده"

    if(status==="default" && pay_status==="default")
        return "منتظر پرداخت"

    if(status==="default" && pay_status==="payed")
        return "تنظیم برنامه"

    if(status==="delivered" && pay_status==="payed")
        return "مشاهده / ویرایش"
}


function DumbbellMyRequests(){

    const [pageSpin,set_pageSpin] = useState(true)

    const location = useLocation()
    const history = useHistory()

    //tabs
    const [activeTab,set_activeTab] = useState("plan") //plan , turn
    const handleClickOnTab = (t)=>{
        console.log("in QAAAA",t)
        set_activeTab(t)
    }
    useEffect(()=>{
        // resetPage()
        console.log("in BBBBBB")
        console.log("loca : ",location?.state?.filter)
        if(location?.state?.filter)
            checkInitFilter()
            else
                getData()
                getServices()
    },[activeTab])

    const checkInitFilter = ()=>{
            let t = location.state.filter;
            history.replace({ state: {} })
            console.log("loca 2: ",location?.state)
            if(t==="turn"){
                set_activeTab("turn")
            }else {
                set_activeTab("plan")
                handleChangeType(t)
            }
    }

    const resetPage = ()=>{
        type="all"
        set_currentType("all")
        set_data([])
    }

    const [services,set_services] = useState(null)
    const getServices = async()=> {
        try {
            const response = await getTrainerProfileApi()
            let srv = response.data.data.services
            set_services(srv)
            if(srv.contains("booking") && !srv.contains("plan"))
                handleClickOnTab("turn")
        }catch (e) {
            console.log(e)
        }
    }
    //data
    const [data,set_data] = useState([])
    const getData = async ()=>{
        set_pageSpin(true)
        console.log("dddddddddddddddddddddddd **")
        try{
            if(activeTab==="plan"){
                set_data([])
                const resp = await requestsPlan_api(page,search,type)
                set_data(resp.data.data.plans)
            }
            if(activeTab==="turn"){
                set_data([])
                const resp = await requestsTurn_api(page,search,type)
                set_data(resp.data.data)
            }
            set_pageSpin(false)
        }catch (e) {
            set_pageSpin(false)
            console.log(e)
        }
    }

    //Drawer
    const [currentType,set_currentType] = useState("all")
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")

    const convertTypeName = (t)=>{
        switch (t) {
            case 'all':
                return 'همه'
                break;
            case 'new':
                return 'جدید ها'
                break;
            case 'delivered':
                return 'تحویل داده شده'
                break;
            case 'not_delivered':
                return 'تحویل داده نشده'
                break;
        }
    }

    const handleChangeType = (t)=>{
        set_currentType(t)
        onBottomDrawerClose()
        type=t
        getData()
    }

    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleChangeFilter=(page,t)=>{
        if(page==="request"){
            if(t==="turn"){
                set_activeTab("turn")
            }else {
                set_activeTab("plan")
                handleChangeType(t)
            }
        }

    }


    const [searchInput,set_searchInput] = useState("")

    const handleSearch = (word)=>{
        set_searchInput(word)
        if(word.length>2){
            page = 0;
            search = word;
            getData()
        }
        if(word.length===0){
            page = 0;
            search = "";
            getData()
        }
    }

    return(
        <div>

            <DumbbellHeader title="درخواست های من" changeFilter={handleChangeFilter}/>

            <div className="my-requests-page">

                <div className="tabs-row">
                    {services?.contains("booking")?
                    <div onClick={e=>handleClickOnTab("turn")} className={activeTab==="turn"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>وقت حضوری</span>
                    </div>:null}
                    {services?.contains("plan")?
                    <div onClick={e=>handleClickOnTab("plan")} className={activeTab==="plan"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>برنامه آنلاین</span>
                    </div>:null}
                </div>

                <br/>
                <div className="search-row">
                    <img src={searchIcon} alt=""/>
                    <input
                        value={searchInput}
                        onChange={e=>handleSearch(e.target.value)}
                        type="text" name="" id="" placeholder="جستجو با موبایل یا نام"/>
                </div>


                {activeTab==="plan"?
                    <div className="filter-row">
                        <div className="filter-item clickable" onClick={showBottomDrawer}>
                            <img src={caretDownIcon} alt=""/>
                            <span>{convertTypeName(currentType)}</span>
                        </div>
                    </div>:null
                }

            </div>


            {data.length<1 && !pageSpin?
                <div className="empty-state">
                    <img src={emptyIcon} alt=""/>
                    <span>هنوز درخواستی وجود</span>
                    <span>نداره !</span>
                </div>:null
            }

            {pageSpin?
            <div className="loading-state">
                <span style={{width:20,height:20,border:"1.5px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
            </div>:null
            }

            <div className="page-data-list">
                {data.map(d=>{
                    if(activeTab==="plan")
                        return <PlanCard p={d}/>
                    if(activeTab==="turn")
                        return <BookCard item={d} getBooks={getData}/>
                })}
            </div>


            <Drawer height={380}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    <div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("all")} >
                        <span className="label">همه برنامه ها</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("new")} >
                        <span className="label">درخواسست برنامه جدید</span>
                    </div>
                    <div className="bottom-drawer-item clickable"  onClick={e=>handleChangeType("delivered")}>
                        <span className="label">برنام های تحویل داده شده</span>
                    </div>
                    <div className="bottom-drawer-item clickable"  onClick={e=>handleChangeType("not_delivered")}>
                        <span className="label">برنام های تحویل داده نشده</span>
                    </div>
                </div>
            </Drawer>

        </div>
    )
}



export default DumbbellMyRequests;
