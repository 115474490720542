import React, {useEffect, useState} from 'react';
import "./DumbbellTraineeWelcome.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import keyIcon from  "../../assets/dumbbell_image/key_icon.png";
import {useHistory, useLocation, useParams} from "react-router";
import {setTokenToLocalStorage, setUserToLocalStorage, validation} from "../../Services/utils";
import {message, Spin} from "antd";
import {loginApi, traineeWelcomeApi, trainerWelcomeApi, verifyApi} from "../../Services/Services";

function DumbbellTraineeWelcome(){

    const history = useHistory()
    const location = useLocation()
    let {welcomeId} = useParams()


    const [pageSpin,set_pageSpin] = useState(false)
    const [trainer,set_trainer] = useState(false)

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    useEffect(()=>{
        if(welcomeId)
            autoLogin()
    },[])


    const autoLogin = async ()=>{
        set_pageSpin(true)
        try{
            const res = await traineeWelcomeApi({welcomeId})
            setTokenToLocalStorage(res.data.data.accessToken)
            setUserToLocalStorage(res.data.data.trainee)
            set_trainer(res.data.data.trainee)
            set_pageSpin(false)
        }catch (e) {
            console.log(e);
            set_pageSpin(false)
        }
    }


    const login = ()=>{
        history.push(`/trainee/plan-list`)
    }

    return(
        <div className="dumbbell-login-container">
            <Spin spinning={pageSpin}>

                <div className="dumbbell-login-image">
                    <img src={loginImage} alt=""/>
                </div>

                {/*<div className="dumbbell-login-logo">*/}
                {/*    <img src={logoWhite} alt=""/>*/}
                {/*</div>*/}



                <div className="dumbbell-welcome">
                    <span className="dumbbell-welcome-name" >{trainer.first_name+" "+trainer.last_name} عزیز</span>
                    <br/>
                    <span className="dumbbell-welcome-label">به استپ خوش امدید</span>
                    <span className="dumbbell-welcome-message">برای دیدن و پیگیری برنامه های خود وارد شوید</span>
                    <br/>
                    <span className="dumbbell-welcome-btn" onClick={login}>مشاهده برنامه ها</span>
                </div>




                {/*<div className="dumbbell-login-copyright">*/}
                {/*    <span>copywrite@2024  - all right reserved</span>*/}
                {/*</div>*/}

            </Spin>
        </div>
    )
}

export default DumbbellTraineeWelcome;
