import React from "react";
import {Progress} from "antd";
import "./UploadLoading.scss";
import {useUploadLoading} from "../../Hook/useUploadLoading";

function UploadLoading() {
    const loader = useUploadLoading()
    return(
        <>
            {loader.visible?
                <div className="upload-loading-component">
                    <span className="step-loader"></span>
                    <div className="upload-loading-sec">
                        <Progress
                            trailColor={"#e2e2e2"}
                            strokeColor={"#5c7bb1"}
                            showInfo={false}
                            percent={loader.percent}  status="active" />
                        <span className="upload-loading-title">{loader.title}</span>
                        <span className="upload-loading-percent"> {loader.percent} %</span>
                        <p>لطفا تا پایان آپلود صفحه را نبندید.</p>
                    </div>
                </div>:null
            }
            </>
    )
}

export default UploadLoading