import React ,{useState,useEffect} from 'react';
import "./DumbbellRequestPlan.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {addTraineeApi, payMySelfPlanInvoiceApi, sendRequestPlanInvoiceApi} from "../../Services/Services";
import {message, Spin} from "antd";
import {englishNumber, numberWithCommas, validation} from "../../Services/utils";

function DumbbellRequestPlan({handleDoneSendRequest,traineeId}){

    let [title,set_title] = useState("")
    let [cost,set_cost] = useState("0")
    let [discount,set_discount] = useState("0")
    let [estimate,set_estimate] = useState("1")
    let [target,set_target] = useState("")
    let [duration,set_duration] = useState(30)
    let [spinPage,set_spinPage] = useState(false)

    const resetForm =()=>{
        set_title("")
        set_cost("0")
        set_discount("0")
        set_estimate("1")
        set_target("")
    }

    const sendInvoice = async ()=>{
        let cost_num= cost.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(target.length<2){
            return message.error({
                content: "لطفا هدف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(cost.length<1){
            return message.error({
                content: "لطفا هزینه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }



        if(!estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            type:"trainee-pay",
            target:target,
            duration:parseInt(duration),
            traineeId: traineeId,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            delivery_estimate: estimate,
        }


        try{
            await sendRequestPlanInvoiceApi(obj)
            resetForm()
            message.success({
                content: "فاکتور برنامه برای شاگرد ارسال شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            if(e.response.status===403 || e.response.status===405){
                return message.error({
                    content: "شما قادر به انجام این کار نمیباشید",
                    className: 'toast-class'
                });
            }

            return message.error({
                content: "مشکل در ارسال برنامه بوجود آمده است",
                className: 'toast-class'
            });

        }

    }

    const payMySelfInvoice = async ()=>{
        let cost_num= cost.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(cost.length<2){
            return message.error({
                content: "لطفا هزینه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<2){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }



        if(!estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            type:"trainer-pay",
            traineeId: traineeId,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            delivery_estimate: estimate,
        }


        try{
            await payMySelfPlanInvoiceApi(obj)
            resetForm()
            message.success({
                content: "فاکتور برنامه ارسال شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e)
            return message.error({
                content: "مشکل در ارسال برنامه بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = cost;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد پرونده ورزشی"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : برنامه تمرینی"
                            id=""/>
                        <span>عنوان برنامه</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <textarea
                            value={target}
                            onChange={e=>set_target(e.target.value)}
                            name=""
                            placeholder="مثلا : کاهش وزن"
                        >

                        </textarea>
                        <span>هدف برنامه</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={cost}
                            onChange={e=>set_cost(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>هزینه برنامه (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={discount}
                            onChange={e=>set_discount(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تخفیف برنامه (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={estimate}
                            onChange={e=>set_estimate(e.target.value)}
                            type="text" name="" id=""/>
                        <span>مدت زمان تحویل(روز)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            value={duration}
                            onChange={e=>set_duration(e.target.value)}
                            className="s-input" name="" id="">
                            <option value={30}>1 ماهه</option>
                            <option value={60}>2 ماهه</option>
                            <option value={90}>3 ماهه</option>
                            <option value={120}>4 ماهه</option>
                            <option value={150}>5 ماهه</option>
                            <option value={180}>6 ماهه</option>
                            <option value={210}>7 ماهه</option>
                            <option value={240}>8 ماهه</option>
                            <option value={270}>9 ماهه</option>
                            <option value={300}>10 ماهه</option>
                            <option value={330}>11 ماهه</option>
                            <option value={365}>1 ساله</option>
                            <option value={730}>2 ساله</option>
                            <option value={1460}>3 ساله</option>
                        </select>
                        <span>طول مدت برنامه</span>
                    </div>


                    <div className="total-section">
                        <span className="total-section-label">{`مبلغ قابل پرداخت`}</span>
                        <span className="total-section-price">{calcTotal()} تومان </span>
                    </div>



                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ارسال فاکتور برنامه</span>
                    </div>
                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}

                </div>

            </Spin>






        </div>
    )
}

export default DumbbellRequestPlan;
