import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellBoxTrainee.scss";
import sampleFace from "../../assets/images/default_user.png"
import {
    boxImageUploadApi, boxTraineeImageUploadApi, deleteBoxItemApi, deleteTraineeBoxItemApi, getBoxApi, getBoxTraineeApi,
    traineeListApi,
    trainerChatListApi,
    trainerChatSeenMessageApi,
    trainerChatSendMessageApi
} from "../../Services/Services";
import moment from "moment-jalaali";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import {useHistory} from "react-router";
import { CodeSandboxOutlined, DeleteOutlined } from '@ant-design/icons';
import {Drawer, Spin, Popconfirm, message} from "antd";
import {Setup} from "../../Services/Setup";
import { v4 as uuidv4 } from 'uuid';
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";

function BoxItem({item,trainee,index,uploadMode,set_uploadMode}){

    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);

    const [url,set_url] = useState(item.url?item.url:"");

    //Chat Trainee Drawer
    const [visibleBoxImageTraineeDrawer,set_visibleBoxImageTraineeDrawer] = useState(false)

    const showBoxImageDrawer = ()=>{
        set_visibleBoxImageTraineeDrawer(!visibleBoxImageTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxImageTraineeDrawer(false);
    };

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const upload = async ()=>{
        set_spinTip("در حال آپلود...")
        set_spin(true)
        set_uploadMode(true)
            if(imageFile){
                try{
                    let response = await boxTraineeImageUploadApi(imageFile,trainee._id,index+1);
                    set_url(response.data.data.url)
                    set_spin(false)
                    set_uploadMode(false)
                    set_imagePrev(null)
                }catch(e){
                    set_spin(false)
                    set_uploadMode(false)
                    if(e.response.data.message==="not_active_plan"){
                        return message.error({
                            content: "شما برنامه فعالی با این مربی ندارید",
                            className: 'toast-class'
                        });
                    }
                    console.log(e)
                }
            }

    }

    const cancelUpload = ()=>{
        set_imagePrev(null)
        set_imageFile(null)
    }
    const getDrawerStyle = () => {
        const style = { position: 'absolute' };
        if (visibleBoxImageTraineeDrawer) {
            style.transform = undefined; // set to undefined here
        }
        return style;
    };

    const [spinTip,set_spinTip] = useState("")
    const confirm = async ()=>{
        set_spinTip("در حال حذف ...")
        set_spin(true);
        try{
            await deleteTraineeBoxItemApi(trainee._id,index+1);
            set_spin(false);
            set_url("")
            set_imagePrev(null)
            set_imageFile(null)
        }catch (e) {
            set_spin(false);
            console.log(e)
        }

    }
    const cancel = ()=>{

    }

    return(
        <Fragment>
            <Spin spinning={spin} tip={spinTip}>
                <div
                    style={{backgroundImage:`url(${url.length>0 && !imagePrev?Setup.filePoint+`/${url}?id=${uuidv4()}`:imagePrev})`}}
                    className={!imagePrev && url.length<1 ?"dumbbell-box-item clickable":"dumbbell-box-item"} onClick={e=>{
                    if(!imagePrev && !uploadMode && url.length<1)
                        handleClickItems()
                }}>
                    {url.length<1?<div className="mask-box"></div>:null}
                    {url.length>0?<div className="wrapper-box" onClick={e=>{
                        showBoxImageDrawer()
                    }}></div>:null}
                    {!imagePrev && url.length<1?
                        <Fragment>
                            <CodeSandboxOutlined style={{zIndex:3,color: "#b4b4b4", fontSize: 19, marginTop: 6,}} />
                            <span className="dumbbell-box-item-index">{index+1}</span>
                        </Fragment>
                        :null}

                    {url.length>0?
                        <Popconfirm
                            title="این تصویر حذف بشه ؟"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="بله"
                            icon={""}
                            cancelText="انصراف"
                        >
                        <span className="delete-sec" >
                            <DeleteOutlined/>
                        </span>
                        </Popconfirm>
                    :null}

                    {imagePrev && !uploadMode?
                        <div className="box-btn">
                    <span className="box-upload-btn" onClick={e=>{
                        upload()
                    }}>آپلود</span>
                            <span className="box-cancel-btn" onClick={e=>{
                                cancelUpload()
                            }}>انصراف</span>
                        </div>
                        :null}

                    <input type="file" ref={fileRef} name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
                </div>
                <Drawer    bodyStyle={{padding:0,margin:0}}  placement="top" height={"auto"}  closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxImageTraineeDrawer}>
                    <img style={{width:'100%'}} src={Setup.filePoint+`/${url}?id=${uuidv4()}`} alt=""/>
                </Drawer>
            </Spin>

        </Fragment>
    )
}

function DumbbellBoxTrainee({trainee,visibleChatTraineeDrawer}){

    const [box,set_box] = useState({});
    const[items,set_items] = useState([])
    const[uploadMode,set_uploadMode] = useState(false)

    const data = [
        {url:"",uid:1},
        {url:"",uid:2},
        {url:"",uid:3},
        {url:"",uid:4},
        {url:"",uid:5},
        {url:"",uid:6},
        {url:"",uid:7},
        {url:"",uid:8},
        {url:"",uid:9},
        {url:"",uid:10}
    ]

    useEffect(()=>{
        getBox()
    },[])


    const assignToData = (list)=>{
        list.forEach(i=>{
            let find = data.find(d=>d.uid===i.index)
            find['url']=i.url
        })

        set_items(data)
    }

    const getBox = async ()=>{
        try{
            const resp = await getBoxTraineeApi(trainee._id);
            if(resp.data.data)
            assignToData(resp.data.data.items)
            else
            set_items(data)

        }catch(e){
            console.log(e)
        }
    }





    return(
        <div className="dumbbell-box-container">

            <div className="dumbbell-box-list">
                {items.map((i,index)=>{
                    return(
                        <BoxItem trainee={trainee}  uploadMode={uploadMode} key={i.uid} item={i} index={index} set_uploadMode={set_uploadMode}/>
                    )
                })}
            </div>

        </div>
    )
}

export default DumbbellBoxTrainee;
