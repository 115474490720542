import React, {useEffect, useState} from 'react';
import "./DumbbellTrainerWelcome.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import keyIcon from  "../../assets/dumbbell_image/key_icon.png";
import {useHistory, useLocation, useParams} from "react-router";
import {setTokenToLocalStorage, setUserToLocalStorage, validation} from "../../Services/utils";
import {message, Spin} from "antd";



import {loginApi, trainerWelcomeApi, verifyApi} from "../../Services/Services";
import {Setup} from "../../Services/Setup";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

function DumbbellTrainerWelcome(){

    const history = useHistory()
    const location = useLocation()
    let {welcomeId} = useParams()


    const [pageSpin,set_pageSpin] = useState(false)
    const [trainer,set_trainer] = useState(null)
    const setLoader = useDispatchLoading()


    useEffect(()=>{
        if(welcomeId)
            autoLogin()
    },[])


    const autoLogin = async ()=>{
        setLoader(true)
        set_pageSpin(true)
        try{
            const res = await trainerWelcomeApi({welcomeId})
            setTokenToLocalStorage(res.data.data.accessToken)
            setUserToLocalStorage(res.data.data.trainer)
            set_trainer(res.data.data.trainer)
            set_pageSpin(false)
            setLoader(false)
        }catch (e) {
            console.log(e);
            setLoader(false)
            set_pageSpin(false)
        }
    }


    const login = ()=>{
        history.push(`/my-step`)
    }

    return(
        <div className="dumbbell-login-container">



            <div className="step-logo">
                <h3>STEP</h3>
            </div>


            {trainer?.image?
                <div className="step-trainer-image">
                    <div className="step-trainer-image-wrapper">
                        <img src={Setup.filePoint+trainer.image} alt=""/>
                    </div>

                </div>:null
            }


            {trainer?<div className="dumbbell-welcome-v2">
                <span className="dumbbell-welcome-name" >{trainer.first_name+" "+trainer.last_name} عزیز</span>
                <br/>
                <span className="dumbbell-welcome-label">به استپ خوش امدید</span>
                <span className="dumbbell-welcome-message">برای مدیریت و ثبت شاگردان خود لطفا وارد شوید</span>
                <span className="dumbbell-welcome-btn" onClick={login}>ورود به استپ</span>

                <br/>
                <span className="dumbbell-welcome-label">دیدن آموزش تصویری</span>
                <span className="dumbbell-welcome-message">برای دیدن تمام آموزش های تصویری استپ میتوانید از لینک زیر استفاده نمایید</span>
                <span className="dumbbell-welcome-btn" >آموزش تصویری</span>
            </div>:null}




            {/*<div className="dumbbell-login-copyright">*/}
            {/*    <span>copywrite@2024  - all right reserved</span>*/}
            {/*</div>*/}

        </div>
    )
}

export default DumbbellTrainerWelcome;
